.login-header {
    height: 280px;
    background-image: url('../assets/images/login-bg1.webp');
    background-size: 100% 100%;
}

.login-section {

    background-image: linear-gradient(56deg, #ffcc2e 4%, #ffb80c 42%);
    height: 100vh;
}

.login-header {
    text-align: center;
    display: flex;
    align-items: center;
    flex-flow: column;
    bottom: 30px;
    justify-content: flex-end;
}

.login-header img {
    width: 130px;
    margin: 0 auto;
}

.login-header p {
    margin: 0;
    color: #000;
    font-size: 9.2px;
}

.login-header>div {
    width: 100px;
    margin-top: 28px;
    margin-bottom: 8px;
    opacity: 0.5;
}

.login-header>div>img {
    width: 100%;
}

.login-form {
    width: 84%;
    margin: 36px auto 0;
}

.login-form input {
    border: 1px solid #666 !important;
}

.validation-input img {
    position: absolute;
    width: 55px;
    right: 4px;
    top: 40%;
    transform: translate(0px, -50%);
}

.footer-links {
    /* width: 100vw; */
    margin: 3.2vw auto 8vw;
    font-size: 12px;
    text-align: center;
    padding: 0 1.86667vw;
}

.footer-links a:first-child {
    border: none;
    padding-left: 0;
}

.footer-links a {
    color: #fff;
    text-decoration: underline;
    margin-bottom: 1.33333vw;
    display: inline-block;
}

.footer-links a:first-child:before {
    margin: 0;
    content: "";
}

.footer-links a:before {
    margin: 0 0.4vw;
    content: "|";
}

.footer-bottom {
    margin: 6.4vw auto 8vw;
    margin-bottom: 70px;
}

.social {
    background: #ffffff80;
}

.social .MuiTabs-root button {
    padding: 6px
}

.xs-down-app {
    width: 100%;
    display: flex;
    justify-content: center;
}

.xs-down-app>div:first-child {
    margin-right: 2.66667vw;
    margin-bottom: 2.66667vw;
}

.xs-down-app p {
    text-align: left;
    margin: 0 !important;
    font-size: 2.66667vw;
}

.tab-description>div {
    padding: 3.46667vw 0 2.66667vw;
}

.tab-description>div p {
    font-size: 11.9px;
}

.xs-down-app>div:first-child>div {
    background: #ffffff4d;
    border: 1px solid #0000004d;
    padding: 0.8vw 1.86667vw 1.6vw;
    border-radius: 2.13333vw;
    color: #00000080;
    height: 8.8vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.xs-down-app img {
    width: 140px;
}

.xs-down-app .bet-img {
    width: 26.66667vw;
    height: 4.26667vw;
    opacity: 0.5;
}

.login-social button {
    padding: 6px 6px;
}

.login-social .tab-description>div a {
    display: block;
    color: #efefef;
    padding: 4px 8px;
    font-size: 12px;
}

.login-social {
    background: #00000099;
}

.login-social .MuiTab-textColorPrimary {
    color: #999999;
}

.login-social .MuiTab-textColorPrimary.Mui-selected {
    color: #efefef;
}

.login-social .MuiTab-textColorPrimary.Mui-selected::after {
    content: '';
    position: absolute;
    top: 100%;
    height: 2.66667vw;
    background-image: url(https://bxawscf.Exchange/images/mobile/support_arrow.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-color: initial;
    pointer-events: none;
}

.login-close {
    position: fixed;
    right: 1.86667vw;
    justify-content: center;
    align-items: center;
    width: 9.33333vw;
    height: 9.33333vw;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    margin-top: 1.86667vw;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-close svg {
    color: #fff;
}

/* Login End */
/* Header */
.logo {
    width: 33.8vw;
}

.bottom-nav {
    position: fixed;
    bottom: 0;
    z-index: 99;
    width: 100%;
}

.bottom-nav a {
    width: 100%;
}

.bottom-nav button {
    background-image: linear-gradient(-180deg, #243A48 20%, #172732 91%);
    color: #ffffff !important;
    border: 0;
    min-width: 20vw;
    padding: 1.86667vw 2.66667vw 0;
    width: 100%;
    min-height: 13.33333vw;
    align-items: flex-start;
    top: -1px;
}

.bottom-nav button.Mui-selected {
    background: linear-gradient(-180deg, #32617F 20%, #1F4258 91%);
}

.bottom-nav>div {
    height: 46px;
}

.bottom-nav button.home img {
    width: 8.53333vw;
    height: 8vw;
    margin: -2.13333vw auto 0vw;
}

.bottom-nav button.sportlable img {
    width: 5.86667vw;
    height: 4.8vw;
}

.bottom-nav button img {
    width: 5.53333vw;
    height: 5.53333vw;
}

.right-login {
    padding: 0 40px;
    margin-top: 90px !important;
    width: -webkit-fill-available;
}

.MuiGrid-item {
    margin-top: 0px !important;
}