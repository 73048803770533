* {
    margin: 0;
    padding: 0;
}

body {
    background: #F0ECE1;
}

.tab-topplay a {
    height: 100%;
    background-repeat: repeat-x !important;
    padding-left: 10px;
    background-position: 0 -12370px !important;
}

.dashboard {
    height: 100%;
    font-family: Tahoma, Helvetica, sans-serif;
    color: #1e1e1e;
    font-size: 12px;
    line-height: 15px;
    background: #F0ECE1;
}

.dashboard .top {
    background: linear-gradient(-180deg, #414141, #000);
}

.top {
    min-width: 1300px;
    background: linear-gradient(-180deg, #414141, #000);
    background-attachment: fixed;
    margin-bottom: 1px;
}

.dashboard .header {
    color: #FDB72F;
}

.header {
    height: 54px;
    padding-top: 20px;
}

.dashboard .header h1 {
    width: 50px;
    height: 50px;
    margin-top: -10px;
    background: url(../assets/images/lce.png) no-repeat;
    background-size: 50px;
}

/* .dashboard .header h1 {
    margin-top: -10px;
}
.dashboard .header h1 {
    width: 158px;
    height: 53px;
    background-image: url(./skyexchange/logo-skyexchange.png) no-repeat;
} */
.main_wrap,
.header,
.footer-content {
    position: relative;
    width: 1387px;
    margin: 0 auto;
}

.delete,
.btn-close,
.header h1,
.mysports247 .header h1,
.maxinplay .header h1,
.maxexch9 .header h1,
.mazaplay .header h1,
.kingfair24 .header h1,
.alphaexch .header h1,
.alphaexchlive .header h1,
.dashboard .header h1,
.skyfair .header h1,
.mcwex .header h1,
.goexch365 .header h1,
.agexch247 .header h1,
.suninplay .header h1,
.oxyinplay .header h1,
.iceEX .header h1,
.o_spondemo .header h1,
.o_12bet .header h1,
.dafabet .header h1,
.skyinplay .header h1,
.probetx .header h1,
.etoroexch .header h1,
.probet247 .header h1,
.bigexch .header h1,
.sixball .header h1,
.sixballio .header h1,
.sgexch247 .header h1,
.sharet365 .header h1,
.baji .header h1,
.baji365 .header h1,
.baazi365 .header h1,
.lionexch .header h1,
.masterexchange .header h1,
.matador .header h1,
.fairenter .header h1,
.lucky7 .header h1,
.dhoom .header h1,
.qexch .header h1,
.iceinplay .header h1,
.betmygame .header h1,
.regalexch365 .header h1,
.khelexch .header h1,
.fancyfair .header h1,
.maruti .header h1,
.marutibook .header h1,
.dashboard247 .header h1,
.dashboard247com .header h1,
.oceanexch1 .header h1,
.oceanbook1 .header h1,
.wicketspro .header h1,
.yabo101sport .header h1,
.aecricket888 .header h1,
.cftbet365 .header h1,
.crickzoom .header h1,
.crickzoomlive .header h1,
.betbarter .header h1,
.wickets247 .header h1,
.crickex .header h1,
.hmsbet .header h1,
.cricfair .header h1,
.jeetbuzz .header h1,
.enterfair .header h1,
.language,
.menu-list .Go,
.path .path-back,
.favor,
.tag-live strong,
.transfer-wrap .close,
.transfer-wrap-slider .close,
.transferId-con dd .copy-but,
.transferId-con .mainBalance dd .refresh-but,
.login_to_go .close,
.close_pop,
.table01 .action a,
.table-s .action a,
.table_one .action a,
.btn_open,
.btn_close,
.odd-add a,
.open-odds,
.close-odds,
.credit-amount-member .btn,
.credit-amount-member .btn-send,
.credit-amount-member .pages a,
.pages .credit-amount-member a,
.credit-amount-member .calendarTable_inputBox input,
.calendarTable_inputBox .credit-amount-member input,
.credit-amount-member .calendarTable_inputBox textarea,
.calendarTable_inputBox .credit-amount-member textarea,
.credit-amount-member .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .credit-amount-member a,
.credit-amount-member .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .credit-amount-member a,
.credit-amount-member .total_all .btn_replay,
.total_all .credit-amount-member .btn_replay,
.credit-amount-member .btn_expand,
.credit-amount-member .add_member {
    font-size: 0;
    text-indent: -99999px;
}

.header h1 {
    margin: -2px 15px 0 0;
}

.header h1 {
    width: 143px;
    height: 34px;
    background-image: url(./logo.png) no-repeat;
}

.float-L,
.delete,
.login-wrap li,
.account-wrap li,
.menu li,
.setting-wrap li,
.tab-highlight li,
.marquee-pop .input-list li,
.marquee-pop .filter-list li,
.tab li,
.input-list li,
.filter-list li,
.filter-pop ul li,
.acc-info li,
.total-show li,
.total_info li,
.action li,
.footer_info .action li,
.half_box li,
.pt_allowed dl li,
.status_but li,
.agent_path ul li,
.member_select li,
.tab-topplay li,
.slip-head li,
.agent-path li,
.btn_list-DW li,
.login-panel,
.change_pass .login-panel,
.change_pass .note,
.login-wrap .btn-login,
.login-wrap .btn-signup,
.header h1,
.login-wrap input,
.login-wrap textarea,
.login-wrap .member_select a,
.member_select .login-wrap a,
.menu,
.setting-wrap,
.stake-set dd,
.odds-set dd,
.favor,
.event-left,
.event-right,
.event-left .slip-head span,
.event-right .slip-head span,
.live-left,
.live-right,
.live-channel span,
.marquee-box .marquee a,
.profile-wrap dt,
.form_list dt,
.add_account_box dl dt,
.pt_allowed dl dt,
.filter-pop .btn-wrap .btn,
.filter-pop .btn-wrap .btn-send,
.filter-pop .btn-wrap .pages a,
.pages .filter-pop .btn-wrap a,
.filter-pop .btn-wrap .calendarTable_inputBox input,
.calendarTable_inputBox .filter-pop .btn-wrap input,
.filter-pop .btn-wrap .calendarTable_inputBox textarea,
.calendarTable_inputBox .filter-pop .btn-wrap textarea,
.filter-pop .btn-wrap .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .filter-pop .btn-wrap a,
.filter-pop .btn-wrap .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .filter-pop .btn-wrap a,
.filter-pop .btn-wrap .total_all .btn_replay,
.total_all .filter-pop .btn-wrap .btn_replay,
.filter-pop .btn-wrap .btn_expand,
.filter-pop .btn-wrap .add_member,
.market-float,
.sum-pl dt,
.head-balance,
.pop-left,
.pop-left dt,
.select-betting .selection,
.pop-right,
.initial-balance dt,
.final-balance dt,
.initial-balance dd,
.final-balance dd,
.initial-balance dt span,
.final-balance dt span,
.search-wrap,
.total_box .total_dl,
.total_all h2,
.total_all .input-list,
.total_all .filter-list,
.total_info,
.table01 strong.no,
.table-s strong.no,
.table_one strong.no,
.table01 .ico_account,
.table-s .ico_account,
.table_one .ico_account,
.btn_open,
.btn_close,
.lv_0,
.lv_1,
.lv_2,
.lv_3,
.lv_4,
.lv_sub,
.ico_dot1,
.ico_dot2,
.ico_dot3,
.table-pt .expand-close,
.table-pt .expand-open,
.pt_allowed dl,
.status_id p,
.agent_path ul,
.agent_path-L,
.agent_path-L img,
.total_all .agent_path,
.inline-form .form_list,
.inline-form .add_account_box dl,
.add_account_box .inline-form dl,
.inline-form .pt_allowed dl,
.pt_allowed .inline-form dl,
.inline-form .btn,
.inline-form .btn-send,
.inline-form .pages a,
.pages .inline-form a,
.inline-form .calendarTable_inputBox input,
.calendarTable_inputBox .inline-form input,
.inline-form .calendarTable_inputBox textarea,
.calendarTable_inputBox .inline-form textarea,
.inline-form .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .inline-form a,
.inline-form .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .inline-form a,
.inline-form .total_all .btn_replay,
.total_all .inline-form .btn_replay,
.inline-form .btn_expand,
.inline-form .add_member,
.top_player-wrap,
.racing-wrap,
.col-2,
.table01 .order,
.table-s .order,
.table_one .order,
.open-odds,
.close-odds,
.btn-book,
.banking-head dd span,
.DW-amount input,
.DW-amount textarea,
.DW-amount .member_select a,
.member_select .DW-amount a,
.DW-amount .btn,
.DW-amount .btn-send,
.DW-amount .pages a,
.pages .DW-amount a,
.DW-amount .calendarTable_inputBox input,
.calendarTable_inputBox .DW-amount input,
.DW-amount .calendarTable_inputBox textarea,
.calendarTable_inputBox .DW-amount textarea,
.DW-amount .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .DW-amount a,
.DW-amount .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .DW-amount a,
.DW-amount .total_all .btn_replay,
.total_all .DW-amount .btn_replay,
.DW-amount .btn_expand,
.DW-amount .add_member,
.btn_list-DW,
.credit-amount input,
.credit-amount textarea,
.credit-amount .member_select a,
.member_select .credit-amount a,
.wrap-refer_edit dt {
    float: left;
}

.header a {
    height: 100%;
}

.displayB,
.delete,
.btn,
.btn-send,
.marquee-pop .pages a,
.pages a,
.calendarTable_inputBox input,
.calendarTable_inputBox textarea,
.calendarTable_inputBox .member_select a,
.member_select .calendarTable_inputBox a,
.total_all .btn_replay,
.btn_expand,
.add_member,
.login-wrap,
.account-wrap,
.menu,
.setting-wrap,
.tab-highlight,
.marquee-pop .input-list,
.marquee-pop .filter-list,
.tab,
.input-list,
.filter-list,
.filter-pop ul,
.acc-info,
.total-show,
.total_info,
.action,
.footer_info .action,
.half_box,
.pt_allowed dl,
.status_but,
.agent_path ul,
.member_select,
.tab-topplay,
.slip-head,
.agent-path,
.btn_list-DW,
.login-wrap li,
.account-wrap li,
.menu li,
.setting-wrap li,
.tab-highlight li,
.marquee-pop .input-list li,
.marquee-pop .filter-list li,
.tab li,
.input-list li,
.filter-list li,
.filter-pop ul li,
.acc-info li,
.total-show li,
.total_info li,
.action li,
.footer_info .action li,
.half_box li,
.pt_allowed dl li,
.status_but li,
.agent_path ul li,
.member_select li,
.tab-topplay li,
.slip-head li,
.agent-path li,
.btn_list-DW li,
.tag-live strong:before,
.age_box li .secondary:before,
.age_box li .btn-send:before,
.top_player-wrap h3 a,
.racing-wrap h3 a,
.game-wrap h3 a,
.game-highlight-wrap h3 a,
.favor-wrap h3 a,
.sub_path h3 a,
.pop-title h3 a,
.btn-book,
.account-wrap ul a,
.selection-list a,
.power-wrap h3 img,
.power-wrap-b img,
.btn_list-DW.select-D:after,
.btn_list-DW.select-W:after,
.login-wrap .btn-login,
.login-wrap .btn-signup,
.header a,
.account-wrap li .refresh-bar,
.language,
.flag,
.menu-wrap a,
.menu li:hover ul,
.setting-wrap li:hover ul,
.menu-list a,
.menu-list .Go,
.favor-set,
.path .path-back,
.path-pop a,
.favor,
.kv-wrap a,
.promo-banner-wrap a,
.tab-highlight a,
.event-left .slip-head span,
.event-right .slip-head span,
.app-link,
.live-list a,
.live-list a:after,
.change-channel::before,
.marquee-box h4:before,
.marquee-box .tc-content h5:before,
.tc-content .marquee-box h5:before,
.marquee-box h4:after,
.marquee-box .tc-content h5:after,
.tc-content .marquee-box h5:after,
.marquee-box .marquee a span,
.announce-footer .confirm-checkbox,
.close_pop,
.error-text,
.go-back,
.pop-close,
.tab a,
.expand-close,
.expand-open,
.selection,
.initial-balance dt span,
.final-balance dt span,
.btn_expand:before,
.margin-table:after,
.table01 .action a,
.table-s .action a,
.table_one .action a,
.table-pt .expand-close,
.table-pt .expand-open,
.status_but .but_active,
.status_but .but_suspend,
.status_but .but_locked,
.status_but .but_active img,
.status_but .but_suspend img,
.status_but .but_locked img,
.agent_path a,
.agent_path-L img,
.member_select a,
.odd-add a,
.note,
.tab-topplay li:before,
.tab-topplay li:after,
.tab-topplay a,
.table01 .order,
.table-s .order,
.table_one .order,
.open-odds:before,
.close-odds:before,
.banking-head dd span {
    display: block;
    float: left;
}

a {
    text-decoration: none;
    color: #2789ce;
    outline: none;
}

.account-wrap {
    display: flex;
    align-items: center;
}

.float-R,
.pop-warp.no-match_odds .pop-left .select-betting .selection,
.no-match_odds.log-wrap .pop-left .select-betting .selection,
.no-match_odds.streaming-pop .pop-left .select-betting .selection,
.btn-close,
.login-wrap,
.account-wrap,
.language,
.setting-wrap,
.time_zone,
.gmt,
.menu-list .Go,
.favor-set,
.event-right,
.live-head h3 span,
.total-show .sports-switch,
.menu .logout,
.setting-wrap .logout,
.menu .time_zone,
.setting-wrap .time_zone,
.total_all .btn_replay,
.btn_expand,
.add_member,
.table01 .action a,
.table-s .action a,
.table_one .action a,
.action,
.footer_info .action,
.status_id .status-active,
.status_id .status-suspend,
.status_id .status-lock,
.full-amount .btn,
.full-amount .btn-send,
.full-amount .pages a,
.pages .full-amount a,
.full-amount .calendarTable_inputBox input,
.calendarTable_inputBox .full-amount input,
.full-amount .calendarTable_inputBox textarea,
.calendarTable_inputBox .full-amount textarea,
.full-amount .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .full-amount a,
.full-amount .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .full-amount a,
.full-amount .btn_expand,
.full-amount .add_member,
.credit-amount .btn,
.credit-amount .btn-send,
.credit-amount .pages a,
.pages .credit-amount a,
.credit-amount .calendarTable_inputBox input,
.calendarTable_inputBox .credit-amount input,
.credit-amount .calendarTable_inputBox textarea,
.calendarTable_inputBox .credit-amount textarea,
.credit-amount .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .credit-amount a,
.credit-amount .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .credit-amount a,
.credit-amount .btn_expand,
.credit-amount .add_member,
.credit-amount-member .btn,
.credit-amount-member .btn-send,
.credit-amount-member .pages a,
.pages .credit-amount-member a,
.credit-amount-member .calendarTable_inputBox input,
.calendarTable_inputBox .credit-amount-member input,
.credit-amount-member .calendarTable_inputBox textarea,
.calendarTable_inputBox .credit-amount-member textarea,
.credit-amount-member .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .credit-amount-member a,
.credit-amount-member .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .credit-amount-member a,
.credit-amount-member .btn_expand,
.credit-amount-member .add_member,
.wrap-refer_edit dd .btn,
.wrap-refer_edit dd .btn-send,
.wrap-refer_edit dd .pages a,
.pages .wrap-refer_edit dd a,
.wrap-refer_edit dd .calendarTable_inputBox input,
.calendarTable_inputBox .wrap-refer_edit dd input,
.wrap-refer_edit dd .calendarTable_inputBox textarea,
.calendarTable_inputBox .wrap-refer_edit dd textarea,
.wrap-refer_edit dd .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .wrap-refer_edit dd a,
.wrap-refer_edit dd .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .wrap-refer_edit dd a,
.wrap-refer_edit dd .total_all .btn_replay,
.total_all .wrap-refer_edit dd .btn_replay,
.wrap-refer_edit dd .btn_expand,
.wrap-refer_edit dd .add_member,
.btn-add {
    float: right;
}

.clearfix,
.over-wrap,
.pop-warp,
.log-wrap,
.streaming-pop,
.col-center,
.login-wrap,
.account-wrap,
.menu,
.setting-wrap,
.tab-highlight,
.marquee-pop .input-list,
.marquee-pop .filter-list,
.tab,
.input-list,
.filter-list,
.filter-pop ul,
.acc-info,
.total-show,
.total_info,
.action,
.footer_info .action,
.half_box,
.pt_allowed dl,
.status_but,
.agent_path ul,
.member_select,
.tab-topplay,
.slip-head,
.agent-path,
.btn_list-DW,
.change_pass,
.game-wrap,
.game-highlight-wrap,
.menu-wrap,
.stake-set,
.odds-set,
.upcome,
.marquee-box,
.form_list dd,
.add_account_box dl dd,
.pt_allowed dl dd,
.white-wrap,
.total_box,
.fix-content,
.pop-left dl,
.pop-left dd,
.initial-balance,
.final-balance,
.total_all,
.table01 .ico_account,
.table-s .ico_account,
.table_one .ico_account,
.footer_info .main_wrap,
.footer_info .header,
.footer_info .footer-content,
.pt_allowed,
.status_id,
.agent_path,
.agent_path-L .drop_down_li,
.inline-form,
.play_race-wrap,
.top_player-wrap,
.banking-head dd,
.DW-amount,
.credit-amount,
.wrap-refer_edit dl {
    zoom: 1;
}

.clearfix:after,
.over-wrap:after,
.pop-warp:after,
.log-wrap:after,
.streaming-pop:after,
.col-center:after,
.login-wrap:after,
.account-wrap:after,
.menu:after,
.setting-wrap:after,
.tab-highlight:after,
.marquee-pop .input-list:after,
.marquee-pop .filter-list:after,
.tab:after,
.input-list:after,
.filter-list:after,
.filter-pop ul:after,
.acc-info:after,
.total-show:after,
.total_info:after,
.action:after,
.footer_info .action:after,
.half_box:after,
.pt_allowed dl:after,
.status_but:after,
.agent_path ul:after,
.member_select:after,
.tab-topplay:after,
.slip-head:after,
.agent-path:after,
.btn_list-DW:after,
.change_pass:after,
.game-wrap:after,
.game-highlight-wrap:after,
.menu-wrap:after,
.stake-set:after,
.odds-set:after,
.upcome:after,
.marquee-box:after,
.form_list dd:after,
.add_account_box dl dd:after,
.pt_allowed dl dd:after,
.white-wrap:after,
.total_box:after,
.fix-content:after,
.pop-left dl:after,
.pop-left dd:after,
.initial-balance:after,
.final-balance:after,
.total_all:after,
.table01 .ico_account:after,
.table-s .ico_account:after,
.table_one .ico_account:after,
.footer_info .main_wrap:after,
.footer_info .header:after,
.footer_info .footer-content:after,
.pt_allowed:after,
.status_id:after,
.agent_path:after,
.agent_path-L .drop_down_li:after,
.inline-form:after,
.play_race-wrap:after,
.top_player-wrap:after,
.banking-head dd:after,
.DW-amount:after,
.credit-amount:after,
.wrap-refer_edit dl:after {
    content: "";
    display: block;
    clear: both;
    float: left;
}

.account-wrap li {
    position: relative;
    margin-left: 15px;
}

.account-wrap li span {
    color: #fff;
    height: 15px;
    line-height: 15px;
    font-size: 10px;
    padding: 0 5px;
    border-radius: 4px;
    background-color: #000;
    margin-right: 3px;
}

.displayIB,
.menu-wrap span.menu-drop:after,
.menu-wrap span.menu-drop.select:after,
.menu-wrap a.menu-drop:after,
.menu-wrap a.menu-drop.select:after,
.favor-wrap .menu-list a,
.upcome-wrap dt,
.upcome-wrap dd,
.app-link a,
.announce-header h1:before,
.announce-footer li,
.toggle_on,
.toggle_off,
.form_list .radio_enter input,
.add_account_box dl .radio_enter input,
.pt_allowed dl .radio_enter input,
.form_list .radio_enter textarea,
.add_account_box dl .radio_enter textarea,
.pt_allowed dl .radio_enter textarea,
.form_list .radio_enter .member_select a,
.member_select .form_list .radio_enter a,
.add_account_box dl .radio_enter .member_select a,
.member_select .add_account_box dl .radio_enter a,
.pt_allowed dl .radio_enter .member_select a,
.member_select .pt_allowed dl .radio_enter a,
.form_list .radio_enter label,
.add_account_box dl .radio_enter label,
.pt_allowed dl .radio_enter label,
.form_list .radio_enter .radio,
.add_account_box dl .radio_enter .radio,
.pt_allowed dl .radio_enter .radio,
.form_list .add_btn .btn,
.add_account_box dl .add_btn .btn,
.pt_allowed dl .add_btn .btn,
.form_list .add_btn .btn-send,
.add_account_box dl .add_btn .btn-send,
.pt_allowed dl .add_btn .btn-send,
.form_list .add_btn .pages a,
.pages .form_list .add_btn a,
.add_account_box dl .add_btn .pages a,
.pages .add_account_box dl .add_btn a,
.pt_allowed dl .add_btn .pages a,
.pages .pt_allowed dl .add_btn a,
.form_list .add_btn .calendarTable_inputBox input,
.calendarTable_inputBox .form_list .add_btn input,
.add_account_box dl .add_btn .calendarTable_inputBox input,
.calendarTable_inputBox .add_account_box dl .add_btn input,
.pt_allowed dl .add_btn .calendarTable_inputBox input,
.calendarTable_inputBox .pt_allowed dl .add_btn input,
.form_list .add_btn .calendarTable_inputBox textarea,
.calendarTable_inputBox .form_list .add_btn textarea,
.add_account_box dl .add_btn .calendarTable_inputBox textarea,
.calendarTable_inputBox .add_account_box dl .add_btn textarea,
.pt_allowed dl .add_btn .calendarTable_inputBox textarea,
.calendarTable_inputBox .pt_allowed dl .add_btn textarea,
.form_list .add_btn .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .form_list .add_btn a,
.add_account_box dl .add_btn .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .add_account_box dl .add_btn a,
.pt_allowed dl .add_btn .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .pt_allowed dl .add_btn a,
.form_list .add_btn .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .form_list .add_btn a,
.add_account_box dl .add_btn .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .add_account_box dl .add_btn a,
.pt_allowed dl .add_btn .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .pt_allowed dl .add_btn a,
.form_list .add_btn .total_all .btn_replay,
.total_all .form_list .add_btn .btn_replay,
.add_account_box dl .add_btn .total_all .btn_replay,
.total_all .add_account_box dl .add_btn .btn_replay,
.pt_allowed dl .add_btn .total_all .btn_replay,
.total_all .pt_allowed dl .add_btn .btn_replay,
.form_list .add_btn .btn_expand,
.add_account_box dl .add_btn .btn_expand,
.pt_allowed dl .add_btn .btn_expand,
.form_list .add_btn .add_member,
.add_account_box dl .add_btn .add_member,
.pt_allowed dl .add_btn .add_member,
.btn_box .btn,
.btn_box .btn-send,
.btn_box .pages a,
.pages .btn_box a,
.btn_box .calendarTable_inputBox input,
.calendarTable_inputBox .btn_box input,
.btn_box .calendarTable_inputBox textarea,
.calendarTable_inputBox .btn_box textarea,
.btn_box .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .btn_box a,
.btn_box .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .btn_box a,
.btn_box .total_all .btn_replay,
.total_all .btn_box .btn_replay,
.btn_box .btn_expand,
.btn_box .add_member,
.fix-content .status_but li,
.account-wrap li span,
.submit-wrap li,
.submit-wrap .btn-send,
.submit-wrap .btn-send span,
.sort-this:after,
.table01 .expand-close,
.table-s .expand-close,
.table_one .expand-close,
.table01 .expand-open,
.table-s .expand-open,
.table_one .expand-open,
.banking-head dt,
.banking-head dd,
.form-edit label,
.form-edit .btn,
.form-edit .btn-send,
.form-edit .pages a,
.pages .form-edit a,
.form-edit .calendarTable_inputBox input,
.calendarTable_inputBox .form-edit input,
.form-edit .calendarTable_inputBox textarea,
.calendarTable_inputBox .form-edit textarea,
.form-edit .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .form-edit a,
.form-edit .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .form-edit a,
.form-edit .total_all .btn_replay,
.total_all .form-edit .btn_replay,
.form-edit .btn_expand,
.form-edit .add_member,
.access-selection,
.icon-select_all,
.icon-select_partial,
.footer-content dd {
    display: inline-block;
}

.dashboard .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.8);
}

.account-wrap .main-wallet.no-multi {
    border: 0;
    background: none !important;
}

.account-wrap .main-wallet {
    flex: 1;
    width: auto;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}

.displayF,
.promo-banner .promo-control-nav,
.account-wrap .main-wallet .nums,
.account-wrap .a-refresh,
.badge-currency,
.tag-live,
.tag-live strong,
.age_box li .secondary,
.age_box li .btn-send,
.power-wrap,
.account-wrap a,
.account-wrap .main-wallet,
.account-wrap .main-wallet .a-wallet ul,
.account-wrap p.loading-bar,
.licence_embed,
.live-head,
.change-channel,
.marquee-box,
.marquee-box h4,
.marquee-box .tc-content h5,
.tc-content .marquee-box h5,
.marquee-box .marquee a,
.marquee-pop,
.marquee-pop .nofound,
.marquee-pop .nofound .nofound_box,
.marquee-pop .pages,
.btn_expand,
.sports_box dl,
.casinopt-list,
.profile-wrap .dl_list .casinopt-list,
.sports_box .casinopt-list,
.casinopt-list-head,
.profile-wrap .dl_list .casinopt-list-head,
.sports_box .casinopt-list-head,
.sports_box .casino-setting-head {
    display: flex;
}

.account-wrap .main-wallet.no-multi .a-wallet {
    background-color: transparent !important;
    box-shadow: none;
}

.dashboard .account-wrap .a-wallet {
    background-color: rgba(255, 255, 255, 0.15) !important;
}

.account-wrap .main-wallet .a-wallet {
    height: auto;
    line-height: initial;
    border: 0;
    border-radius: 4px 0 0 4px;
    box-shadow: inset 0 1px 0 0 rgb(255 255 255 / 50%);
    padding: 0;
}

.account-wrap a {
    align-items: center;
    color: #1e1e1e;
}

.account-wrap .main-wallet .a-wallet ul {
    align-items: center;
    position: relative;
    width: auto;
    height: 100%;
    line-height: 12px;
    background-color: initial !important;
    padding: 0 7px;
}

.account-wrap .main-wallet ul:not(:target) {
    display: flex;
}

.account-wrap ul:not(:target) {
    display: none;
}

.account-wrap .main-wallet ul {
    position: relative;
    display: flex;
    width: auto;
    box-shadow: none;
    background: none;
}

.account-wrap ul {
    display: none;
    right: 0;
    width: 220px;
}

.account-wrap ul,
.selection-list {
    position: absolute;
    background-color: #fff;
    border-radius: 4px;
    z-index: 99;
}

.pop-warp,
.log-wrap,
.streaming-pop,
.account-wrap ul,
.selection-list,
.slip_set-pop,
.oneclick_set-pop,
.path-pop,
.-wrap,
.dialog-wrap-w,
.pop_box,
.pop_refer,
.filter-pop,
.calendarPanel,
.account_pop,
.tips-popup {
    box-shadow: 0 4px 5px rgb(0 0 0 / 50%);
}

.account-wrap .main-wallet .a-wallet li:last-child {
    margin-right: 0;
}

.account-wrap .main-wallet.no-multi li {
    font-weight: bold;
}

.account-wrap .main-wallet .a-wallet li {
    font-weight: bold;
    margin-right: 7px;
    margin-left: 0;
}

.account-wrap .main-wallet ul li {
    line-height: 24px;
    margin-left: 15px;
}

.account-wrap ul li {
    float: none;
    text-indent: 0;
    margin: 0;
}

.account-wrap ul li,
.selection-list li {
    list-style: none;
}

.account-wrap .main-wallet.no-multi li span {
    opacity: inherit;
}

.account-wrap .main-wallet span:first-of-type {
    opacity: 0.7;
    font-weight: normal;
}

.account-wrap .main-wallet.no-multi .loading-bar {
    background: none;
    box-shadow: none;
    padding: 0 0 0 15px;
}

.account-wrap p.loading-bar {
    width: 80px;
    height: 23px;
    align-items: center;
    margin-right: 0;
    margin-bottom: 0;
    padding: 0 10px 0 15px;
    border-radius: 4px 0 0 4px;
}

.account-wrap .main-wallet p {
    font-weight: bold;
    margin-right: 7px;
    line-height: 1.4;
}

.align-R,
.table01,
.table-s,
.table_one,
.account-wrap .main-wallet p,
.time_zone,
.path ul,
.change-channel span,
.form_list dt,
.add_account_box dl dt,
.pt_allowed dl dt,
.table-trans .unit-num,
.sports_set.pt_player dd,
.pt_player.sports_set2 dd,
.tab-transfer input,
.tab-transfer textarea,
.tab-transfer .member_select a,
.member_select .tab-transfer a,
.full-amount input,
.full-amount textarea,
.full-amount .member_select a,
.member_select .full-amount a,
.slip-head .col-exp,
.slip-head .col-amount,
.form-edit label,
.table-subaccount th:nth-last-child(-n+2),
.table-subaccount td:nth-last-child(-n+2),
.footer-content,
.casinopt-list dd,
.profile-wrap .dl_list .casinopt-list dd,
.sports_box .casinopt-list dd,
.casinopt-list-head dd,
.profile-wrap .dl_list .casinopt-list-head dd,
.sports_box .casinopt-list-head dd {
    text-align: right;
}

.account-wrap p.loading-bar span:nth-child(1) {
    animation-delay: 0s;
}

.account-wrap p.loading-bar span:nth-child(2) {
    animation-delay: 0.1s;
}

.account-wrap p.loading-bar span:nth-child(3) {
    animation-delay: 0.2s;
}

.account-wrap p.loading-bar span:nth-child(4) {
    animation-delay: 0.3s;
}

.account-wrap p.loading-bar span:nth-child(5) {
    animation-delay: 0.4s;
}

.account-wrap p.loading-bar span:nth-child(6) {
    animation-delay: 0.5s;
}

.account-wrap p.loading-bar span:nth-child(7) {
    animation-delay: 0.6s;
}

.account-wrap p.loading-bar span:nth-child(8) {
    animation-delay: 0.7s;
    margin-right: 0;
}

.dashboard .account-wrap .loading-bar span {
    background: #fff;
}

.account-wrap p.loading-bar span {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #1e1e1e;
    margin-right: 6px;
    opacity: 0;
    animation: loadBar 0.8s ease infinite;
}

.account-wrap .loading-bar span {
    padding: 0;
}

.dashboard .account-wrap .main-wallet.no-multi .a-refresh {
    background-color: #FDB72F;
    border: 1px solid rgba(0, 0, 0, 0.8);
}

.account-wrap .main-wallet.no-multi .a-refresh {
    width: 28px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}

.account-wrap .a-refresh {
    width: 24px;
    height: 24px;
    border-left: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 0 4px 4px 0;
    box-shadow: inset 0 1px 0 0 rgb(255 255 255 / 50%);
}

.account-wrap .main-wallet .nums,
.account-wrap .a-refresh,
.badge-currency,
.tag-live,
.tag-live strong,
.age_box li .secondary,
.age_box li .btn-send {
    justify-content: center;
    align-items: center;
}

.dashboard .account-wrap .a-refresh img {
    background: url(./icons/rotate-black.png) no-repeat center center;
    background-size: contain;
}

.account-wrap .a-refresh img {
    height: 14px;
    background: url(./icons/rotate-black.png) no-repeat center center;
    background-size: contain;
}

a img {
    border: 0px solid #fff;
}

.clearfix:after,
.over-wrap:after,
.pop-warp:after,
.log-wrap:after,
.streaming-pop:after,
.col-center:after,
.login-wrap:after,
.account-wrap:after,
.menu:after,
.setting-wrap:after,
.tab-highlight:after,
.marquee-pop .input-list:after,
.marquee-pop .filter-list:after,
.tab:after,
.input-list:after,
.filter-list:after,
.filter-pop ul:after,
.acc-info:after,
.total-show:after,
.total_info:after,
.action:after,
.footer_info .action:after,
.half_box:after,
.pt_allowed dl:after,
.status_but:after,
.agent_path ul:after,
.member_select:after,
.tab-topplay:after,
.slip-head:after,
.agent-path:after,
.btn_list-DW:after,
.change_pass:after,
.game-wrap:after,
.game-highlight-wrap:after,
.menu-wrap:after,
.stake-set:after,
.odds-set:after,
.upcome:after,
.marquee-box:after,
.form_list dd:after,
.add_account_box dl dd:after,
.pt_allowed dl dd:after,
.white-wrap:after,
.total_box:after,
.fix-content:after,
.pop-left dl:after,
.pop-left dd:after,
.initial-balance:after,
.final-balance:after,
.total_all:after,
.table01 .ico_account:after,
.table-s .ico_account:after,
.table_one .ico_account:after,
.footer_info .main_wrap:after,
.footer_info .header:after,
.footer_info .footer-content:after,
.pt_allowed:after,
.status_id:after,
.agent_path:after,
.agent_path-L .drop_down_li:after,
.inline-form:after,
.play_race-wrap:after,
.top_player-wrap:after,
.banking-head dd:after,
.DW-amount:after,
.credit-amount:after,
.wrap-refer_edit dl:after {
    content: "";
    display: block;
    clear: both;
}

.dashboard .menu-wrap {
    /* background-image: linear-gradient(
180deg, #FFCC2F 8%, #FFB80C 100%); */
    /* background: #4d4d4d; */
    box-shadow: inset 0 1px 0 0 rgb(255 255 255 / 75%);
    display: inline-block;
    width: 100%;
}

.menu-wrap {
    min-width: 1300px;
    background-color: #1e1e1e;
    line-height: 30px;
}

.main_wrap,
.header,
.footer-content {
    position: relative;
    width: 1300px;
}

.dashboard .account-wrap a {
    color: #FDB72F;
}

.menu,
.setting-wrap {
    width: 100%;
    font-weight: bold;
}

.dashboard .menu li,
.dashboard .setting-wrap li {
    border-right-color: rgba(69, 32, 7, 0.2);
}

.menu li,
.setting-wrap li {
    border-right: 1px solid #383838;
}

.dashboard .menu .select,
.dashboard .setting-wrap .select {
    box-shadow: inset 0 1px 3px 0 rgb(52 9 9 / 50%);
    background-image: linear-gradient(180deg, #DBBC68 0%, #FFDC7A 61%);
    background-position: inherit;
    color: #222;
}

.dashboard .menu a,
.dashboard .setting-wrap a {
    color: #fff;
}

.menu .select,
.setting-wrap .select {
    background-color: #4d4d4d;
}

.menu a,
.setting-wrap a {
    position: relative;
}

.menu-wrap a {
    color: #fff;
    padding: 0 10px;
}

.menu-wrap span {
    color: #fff;
    padding: 0 10px;
}

.dashboard .menu-wrap a.menu-drop:after {
    border-top-color: #fff;
}

.dashboard .menu-wrap span.menu-drop:after {
    border-top-color: #fff;
}

.menu-wrap a.menu-drop:after,
.menu-wrap a.menu-drop.select:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 3px 0 3px;
    border-color: #a5a5a5 transparent transparent transparent;
    margin-left: 10px;
    vertical-align: middle;
    content: '';
}

.menu-wrap span.menu-drop:after,
.menu-wrap span.menu-drop.select:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 3px 0 3px;
    border-color: #a5a5a5 transparent transparent transparent;
    margin-left: 10px;
    vertical-align: middle;
    content: '';
}

.menu li ul,
.setting-wrap li ul {
    display: none;
    position: absolute;
    background-color: #1e1e1e;
    border-top: 1px solid #383838;
    z-index: 99;
}

.menu li li,
.setting-wrap li li {
    float: none;
    border-bottom: 1px solid #383838;
}

.dashboard .menu li ul a,
.dashboard .setting-wrap li ul a {
    color: #fff;
}

.dashboard .menu a,
.dashboard .setting-wrap a {
    color: #fff;
}

.dashboard .menu .logout,
.dashboard .setting-wrap .logout {
    border-left-width: 0;
}

.menu .logout,
.setting-wrap .logout {
    border-left: 1px solid #383838;
}

.dashboard .menu a,
.dashboard .setting-wrap a {
    color: #fff;
}

.dashboard .menu span,
.dashboard .setting-wrap span {
    color: #fff;
}

.dashboard .menu .logout img,
.dashboard .setting-wrap .logout img {
    background: url(./icons/arrow.png) no-repeat;
}

.menu .logout img,
.setting-wrap .logout img {
    width: 10px;
    height: 11px;
    margin-left: 5px;
    background: url(./icons/arrow-white.png) no-repeat;
}

.dashboard .time_zone {
    color: #fff;
}

.time_zone {
    color: #fff;
    margin: 0;
    padding: 0 12px;
}

.dashboard .time_zone span {
    color: #fff;
}

.time_zone span {
    color: rgba(255, 255, 255, 0.6);
    font-weight: normal;
}

.marquee-box {
    border-radius: 4px;
}

.marquee-box {
    color: #fff;
    line-height: 25px;
    height: 25px;
    margin-bottom: 1px;
    background-repeat: repeat-x;
    background-image: linear-gradient(180deg, #2A3A43 27%, #1C282D 83%);
}

.marquee-box h4,
.marquee-box .tc-content h5,
.tc-content .marquee-box h5 {
    position: relative;
    flex-basis: 72px;
    justify-content: center;
    align-items: center;
}

.marquee-box h4:before,
.marquee-box .tc-content h5:before,
.tc-content .marquee-box h5:before {
    content: '';
    height: 17px;
    width: 17px;
    margin-right: 2px;
    background-position: 100% -2719px;
}

.main-sprite,
.btn-close,
.icon-time,
.icon-live,
.icon-irun,
.icon-predict,
.icon-cashout,
.icon-in_play,
.icon-no_play,
.icon-cricket,
.icon-soccer,
.icon-tennis,
.icon-basketball,
.icon-dog,
.icon-HR,
.icon-racing,
.game-wrap .to-expand,
.game-highlight-wrap .to-expand,
.close.game-wrap .to-expand,
.close.game-highlight-wrap .to-expand,
.o_spondemo .game-wrap .to-expand,
.o_spondemo .game-highlight-wrap .to-expand,
.o_spondemo .close.game-wrap .to-expand,
.o_spondemo .close.game-highlight-wrap .to-expand,
.favor,
.favor.select,
.upcome-head dt,
.tab-highlight a,
.tab-highlight a.select,
.transfer-wrap .close,
.transfer-wrap-slider .close,
.login_to_go .close,
.live-list a:after,
.icon-TV_head,
.marquee-box h4:before,
.marquee-box .tc-content h5:before,
.tc-content .marquee-box h5:before,
.marquee-box h4:after,
.marquee-box .tc-content h5:after,
.tc-content .marquee-box h5:after,
.o_12bet .game-wrap .to-expand,
.o_12bet .game-wrap.close .to-expand,
.o_12bet .tab-highlight a,
.o_12bet .tab-highlight a.select,
.o_12bet .game-wrap-horse .to-expand,
.o_12bet .game-wrap-horse.close .to-expand,
.o_12bet .tab-markets a.select,
.o_12bet .add-pin h3,
.o_12bet .delete-pin h3,
.o_12bet .one_click-stake,
.dafabet .one_click-setting .a-edit img,
.dafabet .one_click-stake,
.mazaplay .one_click-setting .a-edit img,
.mazaplay .one_click-stake,
.mysports247 .one_click-setting .a-edit img,
.mysports247 .one_click-stake,
.dashboard .login_to_go .close,
.dashboard .one_click-setting .a-edit img,
.dashboard .one_click-stake,
.o_spondemo .tab-highlight,
.o_spondemo .tab-highlight a,
.o_spondemo .tab-highlight a.select,
.o_spondemo .tab-markets a.select,
.o_spondemo .add-pin h3,
.o_spondemo .delete-pin h3,
.o_spondemo .one_click-stake,
.skyinplay .one_click-setting .a-edit img,
.skyinplay .one_click-stake,
.bigexch .one_click-setting .a-edit img,
.bigexch .one_click-stake,
.masterexchange .login_to_go .close,
.masterexchange .one_click-setting .a-edit img,
.masterexchange .one_click-stake,
.matador .one_click-setting .a-edit img,
.matador .one_click-stake,
.fairenter .one_click-setting .a-edit img,
.fairenter .one_click-stake,
.lucky7 .one_click-setting .a-edit img,
.lucky7 .one_click-stake,
.qexch .one_click-setting .a-edit img,
.qexch .one_click-stake,
.betmygame .one_click-setting .a-edit img,
.betmygame .one_click-stake,
.fancyfair .one_click-setting .a-edit img,
.fancyfair .one_click-stake,
.maruti .one_click-setting .a-edit img,
.maruti .one_click-stake,
.marutibook .one_click-setting .a-edit img,
.marutibook .one_click-stake,
.dashboard247 .login_to_go .close,
.dashboard247 .one_click-setting .a-edit img,
.dashboard247 .one_click-stake,
.probetx .one_click-setting .a-edit img,
.probetx .one_click-stake,
.alphaexch .one_click-setting .a-edit img,
.alphaexch .one_click-stake,
.alphaexchlive .one_click-setting .a-edit img,
.alphaexchlive .one_click-stake,
.maxexch9 .one_click-setting .a-edit img,
.maxexch9 .one_click-stake,
.cftbet365 .one_click-setting .a-edit img,
.cftbet365 .one_click-stake,
.crickzoom .one_click-setting .a-edit img,
.crickzoom .one_click-stake,
.crickzoomlive .one_click-setting .a-edit img,
.crickzoomlive .one_click-stake,
.maxinplay .one_click-setting .a-edit img,
.maxinplay .one_click-stake,
.betbarter .game-wrap .to-expand,
.betbarter .game-wrap.close .to-expand,
.betbarter .tab-highlight a,
.betbarter .tab-highlight a.select,
.betbarter .game-wrap-horse .to-expand,
.betbarter .game-wrap-horse.close .to-expand,
.betbarter .tab-markets a.select,
.betbarter .add-pin h3,
.betbarter .delete-pin h3,
.betbarter .one_click-stake,
.probet247 .one_click-setting .a-edit img,
.probet247 .one_click-stake,
.kingfair24 .one_click-setting .a-edit img,
.kingfair24 .one_click-stake,
.sgexch247 .one_click-setting .a-edit img,
.sgexch247 .one_click-stake,
.sixball .one_click-setting .a-edit img,
.sixball .one_click-stake,
.sixballio .one_click-setting .a-edit img,
.sixballio .one_click-stake,
.wickets247 .login_to_go .close,
.wickets247 .one_click-setting .a-edit img,
.wickets247 .one_click-stake,
.crickex .login_to_go .close,
.crickex .one_click-setting .a-edit img,
.crickex .one_click-stake,
.iceEX .login_to_go .close,
.iceEX .one_click-setting .a-edit img,
.iceEX .one_click-stake,
.iceinplay .one_click-setting .a-edit img,
.iceinplay .one_click-stake,
.dhoom .login_to_go .close,
.dhoom .one_click-setting .a-edit img,
.dhoom .one_click-stake,
.baji .login_to_go .close,
.baji .one_click-setting .a-edit img,
.baji .one_click-stake,
.agexch247 .login_to_go .close,
.agexch247 .one_click-setting .a-edit img,
.agexch247 .one_click-stake,
.baji365 .login_to_go .close,
.baji365 .one_click-setting .a-edit img,
.baji365 .one_click-stake,
.sportsexchlive .login_to_go .close,
.sportsexchlive .one_click-setting .a-edit img,
.sportsexchlive .one_click-stake,
.sharet365 .login_to_go .close,
.sharet365 .one_click-setting .a-edit img,
.sharet365 .one_click-stake,
.baazi365 .login_to_go .close,
.baazi365 .one_click-setting .a-edit img,
.baazi365 .one_click-stake,
.goexch365 .login_to_go .close,
.goexch365 .one_click-setting .a-edit img,
.goexch365 .one_click-stake,
.etoroexch .one_click-setting .a-edit img,
.etoroexch .one_click-stake,
.oxyinplay .login_to_go .close,
.oxyinplay .one_click-setting .a-edit img,
.oxyinplay .one_click-stake,
.cricfair .login_to_go .close,
.cricfair .one_click-setting .a-edit img,
.cricfair .one_click-stake,
.khelexch .one_click-setting .a-edit img,
.khelexch .one_click-stake,
.lionexch .login_to_go .close,
.lionexch .one_click-setting .a-edit img,
.lionexch .one_click-stake,
.hmsbet .one_click-setting .a-edit img,
.hmsbet .one_click-stake,
.regalexch365 .login_to_go .close,
.regalexch365 .one_click-setting .a-edit img,
.regalexch365 .one_click-stake,
.jeetbuzz .login_to_go .close,
.jeetbuzz .one_click-setting .a-edit img,
.jeetbuzz .one_click-stake,
.skyfair .login_to_go .close,
.skyfair .one_click-setting .a-edit img,
.skyfair .one_click-stake,
.dashboard247com .login_to_go .close,
.dashboard247com .one_click-setting .a-edit img,
.dashboard247com .one_click-stake,
.mcwex .login_to_go .close,
.mcwex .one_click-setting .a-edit img,
.mcwex .one_click-stake,
.enterfair .one_click-setting .a-edit img,
.enterfair .one_click-stake,
.suninplay .login_to_go .close,
.suninplay .one_click-setting .a-edit img,
.suninplay .one_click-stake {
    background-image: url(./sprite/main-s03fdd17476.png);
    background-repeat: no-repeat;
}

.marquee-box h4:after,
.marquee-box .tc-content h5:after,
.tc-content .marquee-box h5:after {
    content: '';
    position: absolute;
    right: -7px;
    top: 0;
    z-index: 2;
    width: 7px;
    height: 25px;
    background-position: 100% -3524px;
}

.marquee-box marquee.marquee {
    flex: 1;
    overflow: hidden;
    z-index: 1;
}

.marquee-box .marquee a {
    height: 25px;
    line-height: 25px;
    font-weight: bold;
    color: #6AC2FF;
    margin-right: 100px;
    align-items: center;
}

.marquee-box .marquee a span {
    height: 15px;
    line-height: 15px;
    font-size: 11px;
    font-style: italic;
    font-weight: normal;
    color: #1C2834;
    background-color: #6AC2FF;
    border-radius: 2px;
    padding: 0 5px;
    margin-right: 5px;
}

.total_all {
    margin: 13px 0 10px 0;
    display: inline-block;
    width: 100%;
}

.total_all .search-wrap {
    margin-right: 10px;
}

.search-wrap {
    position: relative;
}

.search-wrap::before {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 2px;
    display: block;
    content: '';
    width: 19px;
    height: 19px;
    background-image: url(./icons/search.png);
    background-repeat: no-repeat;
    background-size: contain;
    transform: translateY(-50%);
}

.total_all .search-input {
    width: 280px;
    height: 32px;
    border: 1px solid #aaa;
    box-shadow: inset 0px 2px 0px rgb(0 0 0 / 10%);
}

.search-input {
    width: 280px;
    padding-left: 25px;
    margin: 0;
    height: 25px;
    box-sizing: border-box;
}

input,
textarea,
.member_select a {
    font-family: Tahoma, Helvetica, sans-serif;
    color: #1e1e1e;
    font-size: 12px;
    border: 0px #aaa solid;
    background: #fff;
    box-shadow: inset 0px 1px 0px rgb(0 0 0 / 50%);
    border-radius: 4px;
    padding: 5px;
    margin: 0 5px 5px 0;
    box-sizing: border-box;
}

.total_all .search-but {
    position: absolute;
    top: 50%;
    right: 3px;
    width: max-content;
    height: 25px;
    border: 0;
    border-radius: 2px;
    transform: translateY(-50%);
    background-color: #FDB72F;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    padding: 0 8px;
}

.total_all .input-list,
.total_all .filter-list {
    margin-bottom: 0;
    margin-right: 20px;
}

.input-list,
.filter-list {
    margin-bottom: 5px;
    margin-right: 5px;
}

.total_all .input-list li,
.total_all .filter-list li {
    margin: 0 5px 0 0;
}

.input-list li,
.filter-list li {
    list-style: none;
    line-height: 25px;
    margin: 0 5px 5px 0;
    white-space: nowrap;
}

.total_all .input-list li strong,
.total_all .filter-list li strong {
    line-height: 32px;
}

.total_all select {
    height: 32px;
    line-height: 30px;
    margin: 0;
}

.input-list input,
.filter-list input,
.input-list textarea,
.filter-list textarea,
.input-list .member_select a,
.member_select .input-list a,
.filter-list .member_select a,
.member_select .filter-list a,
.input-list select,
.filter-list select {
    margin: 0 5px;
}

select {
    width: 120px;
    height: 25px;
    line-height: 25px;
    cursor: pointer;
    margin: 0 5px 5px 0;
}

option {
    padding: 3px;
}

.total_all .agent_path {
    margin: 0;
}

.agent_path ul {
    background: url(./agent/ag_path_bg.gif);
    border: 1px solid #7E97A7;
    border-radius: 3px;
}

.total_all .btn_replay {
    height: 32px;
    width: 32px;
    margin: 0 0 0 10px;
}

.total_all .btn_replay img {
    margin-right: 0px;
    margin-top: 6px;
    background-position: -44px -980px;
    height: 17px;
    width: 14px;
}

.agent-sprite,
.close_pop,
.go-back img,
.pop-close img,
.bank,
.betting_history,
.gensetting,
.p_l,
.profile,
.status,
.user,
.p2p,
.total_all .btn_replay img,
.add_member img,
.status-active img,
.status-suspend img,
.status-lock img,
.table01 .ico_account img,
.table-s .ico_account img,
.table_one .ico_account img,
.table01 .status_all .icon_active img,
.table-s .status_all .icon_active img,
.table_one .status_all .icon_active img,
.table01 .status_all .icon_locked img,
.table-s .status_all .icon_locked img,
.table_one .status_all .icon_locked img,
.table01 .status_all .icon_suspend img,
.table-s .status_all .icon_suspend img,
.table_one .status_all .icon_suspend img,
.table01 .action .bank:hover,
.table-s .action .bank:hover,
.table_one .action .bank:hover,
.table01 .action .betting_history:hover,
.table-s .action .betting_history:hover,
.table_one .action .betting_history:hover,
.table01 .action .p_l:hover,
.table-s .action .p_l:hover,
.table_one .action .p_l:hover,
.table01 .action .profile:hover,
.table-s .action .profile:hover,
.table_one .action .profile:hover,
.table01 .action .status:hover,
.table-s .action .status:hover,
.table_one .action .status:hover,
.table01 .action .p2p:hover,
.table-s .action .p2p:hover,
.table_one .action .p2p:hover,
.btn_open,
.btn_close,
.ico_dot1,
.ico_dot2,
.ico_dot3,
.toggle_on,
.toggle_off,
.status_but .but_active img,
.status_but .but_suspend img,
.status_but .but_locked img,
.status_but .but_active.open img,
.status_but .open.but_suspend img,
.status_but .open.but_locked img,
.status_but .but_active.disable img,
.status_but .disable.but_suspend img,
.status_but .disable.but_locked img,
.agent_path-L li,
.agent_path-L img,
.currency_tag_all li.currency_tag,
.currency_tag_all li:first-child,
.drop_down_li a,
.col-center .acc-info .user,
.tab-topplay li:before,
.tab-topplay li:after,
.tab-topplay .select:before,
.tab-topplay .select:after,
.sort-this:after,
.open-odds:before,
.close-odds:before,
.icon-date,
.agent-path li,
.agent-path .point-agent-path,
.credit-amount-member .btn,
.credit-amount-member .btn-send,
.credit-amount-member .pages a,
.pages .credit-amount-member a,
.credit-amount-member .calendarTable_inputBox input,
.calendarTable_inputBox .credit-amount-member input,
.credit-amount-member .calendarTable_inputBox textarea,
.calendarTable_inputBox .credit-amount-member textarea,
.credit-amount-member .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .credit-amount-member a,
.credit-amount-member .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .credit-amount-member a,
.credit-amount-member .total_all .btn_replay,
.total_all .credit-amount-member .btn_replay,
.credit-amount-member .btn_expand,
.credit-amount-member .add_member,
.credit-amount-member .btn:hover,
.credit-amount-member .btn-send:hover,
.credit-amount-member .pages a:hover,
.pages .credit-amount-member a:hover,
.credit-amount-member .calendarTable_inputBox input:hover,
.calendarTable_inputBox .credit-amount-member input:hover,
.credit-amount-member .calendarTable_inputBox textarea:hover,
.calendarTable_inputBox .credit-amount-member textarea:hover,
.credit-amount-member .calendarTable_inputBox .member_select a:hover,
.calendarTable_inputBox .member_select .credit-amount-member a:hover,
.credit-amount-member .member_select .calendarTable_inputBox a:hover,
.member_select .calendarTable_inputBox .credit-amount-member a:hover,
.credit-amount-member .total_all .btn_replay:hover,
.total_all .credit-amount-member .btn_replay:hover,
.credit-amount-member .btn_expand:hover,
.credit-amount-member .add_member:hover,
.btn-add:before,
.icon-select_all,
.icon-select_partial {
    background-image: url(./media/agent-sa842cb1158.e0d54d4f.png);
    background-repeat: no-repeat;
}

.btn,
.btn-send,
.marquee-pop .pages a,
.pages a,
.calendarTable_inputBox input,
.calendarTable_inputBox textarea,
.calendarTable_inputBox .member_select a,
.member_select .calendarTable_inputBox a,
.total_all .btn_replay,
.btn_expand,
.add_member {
    background: #eee url(./bg-btn.gif) repeat-x;
    border: 1px solid #bbb;
    border-radius: 4px;
    color: #1e1e1e;
    font-weight: bold;
    line-height: 23px;
    font-size: 12px;
    text-align: center;
}

.skyfair .btn-send {
    background: #121212 url(./skyfair/bg-send-skyfair.png) repeat-x;
    color: #F8D61C;
    border-color: #000;
}

.add_member {
    height: 30px;
    margin: 0;
    line-height: 30px;
    padding: 0 15px;
}

.total_box {
    width: 100%;
    padding: 7px 0 5px !important;
    display: inline-block;
}

.white-wrap,
.total_box,
.fix-content {
    background-color: #fff;
    color: #3b5160;
    padding: 7px 10px 5px;
    border-bottom: 1px solid #7e97a7;
    margin-bottom: 15px;
}

.total_box .total_dl {
    width: 14.96815%;
    padding: 0 10px;
    border-right: 1px solid #D2D2D2;
}

.total_box .total_dl dt {
    font-size: 12px;
    margin: 0 0 5px 0;
    color: #9B9B9B;
}

.total_box .total_dl dd {
    font-size: 15px;
    color: #243A48;
    line-height: 20px;
    font-weight: bold;
}

.loading-wrap {
    display: none;
    top: 20%;
    height: 90px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 50%);
    padding-top: 25px;
    z-index: 50;
    position: absolute;
    left: 50%;
    width: 190px;
    margin-left: -95px;
}

.loading {
    padding: 5% 0;
}

.loading li {
    list-style: none;
    font-size: 11px;
    color: #243a48;
    margin-bottom: 3x;
}

.loading img {
    background-position: -42px -415px;
    height: 22px;
    width: 38px;
}

.loading li {
    list-style: none;
    font-size: 11px;
    color: #243a48;
}

.table01,
.table-s,
.table_one {
    width: 100%;
    background-color: #fff;
    border-collapse: collapse;
    border-bottom: 1px solid #7e97a7;
    margin-bottom: 15px;
}

.align-R,
.table01,
.table-s,
.table_one,
.account-wrap .main-wallet p,
.time_zone,
.path ul,
.change-channel span,
.form_list dt,
.add_account_box dl dt,
.pt_allowed dl dt,
.table-trans .unit-num,
.sports_set.pt_player dd,
.pt_player.sports_set2 dd,
.tab-transfer input,
.tab-transfer textarea,
.tab-transfer .member_select a,
.member_select .tab-transfer a,
.full-amount input,
.full-amount textarea,
.full-amount .member_select a,
.member_select .full-amount a,
.slip-head .col-exp,
.slip-head .col-amount,
.form-edit label,
.table-subaccount th:nth-last-child(-n+2),
.table-subaccount td:nth-last-child(-n+2),
.footer-content,
.casinopt-list dd,
.profile-wrap .dl_list .casinopt-list dd,
.sports_box .casinopt-list dd,
.casinopt-list-head dd,
.profile-wrap .dl_list .casinopt-list-head dd,
.sports_box .casinopt-list-head dd {
    text-align: right;
}

table {
    border-collapse: collapse;
}

.table01 th,
.table-s th,
.table_one th {
    color: #243a48;
    background-color: #E4E4E4;
    border: 1px solid #7e97a7;
    border-width: 1px 0 1px 0;
    padding: 8px 10px;
}

.align-L,
.table01 caption,
.table-s caption,
.table_one caption,
.no-data,
.status-popup,
.access-popup,
.table_one thead th,
.table-pt .expand td.expand-event:first-child,
.pt_allowed dl dt,
.tab-transfer .full-amount,
.tab-transfer .remark,
.tips-popup,
.slip-head .col-playID,
.slip-head .col-type,
.slip-head .col-country,
.slip-head .col-local,
.table01 .td-type,
.table-s .td-type,
.table_one .td-type,
.table01 .td-UID,
.table-s .td-UID,
.table_one .td-UID,
.td-uid,
.DW-amount,
.pop_bg .form-wrap h1 {
    text-align: left;
}

.table01 td,
.table-s td,
.table_one td {
    position: relative;
    border-top: 1px solid #7E97A7;
    padding: 8px 10px;
    vertical-align: middle;
}

.credit-amount-member {
    line-height: 26px;
}

.float-L,
.delete,
.login-wrap li,
.account-wrap li,
.menu li,
.setting-wrap li,
.tab-highlight li,
.marquee-pop .input-list li,
.marquee-pop .filter-list li,
.tab li,
.input-list li,
.filter-list li,
.filter-pop ul li,
.acc-info li,
.total-show li,
.total_info li,
.action li,
.footer_info .action li,
.half_box li,
.pt_allowed dl li,
.status_but li,
.agent_path ul li,
.member_select li,
.tab-topplay li,
.slip-head li,
.agent-path li,
.btn_list-DW li,
.login-panel,
.change_pass .login-panel,
.change_pass .note,
.login-wrap .btn-login,
.login-wrap .btn-signup,
.header h1,
.login-wrap input,
.login-wrap textarea,
.login-wrap .member_select a,
.member_select .login-wrap a,
.menu,
.setting-wrap,
.stake-set dd,
.odds-set dd,
.favor,
.event-left,
.event-right,
.event-left .slip-head span,
.event-right .slip-head span,
.live-left,
.live-right,
.live-channel span,
.marquee-box .marquee a,
.profile-wrap dt,
.form_list dt,
.add_account_box dl dt,
.pt_allowed dl dt,
.filter-pop .btn-wrap .btn,
.filter-pop .btn-wrap .btn-send,
.filter-pop .btn-wrap .pages a,
.pages .filter-pop .btn-wrap a,
.filter-pop .btn-wrap .calendarTable_inputBox input,
.calendarTable_inputBox .filter-pop .btn-wrap input,
.filter-pop .btn-wrap .calendarTable_inputBox textarea,
.calendarTable_inputBox .filter-pop .btn-wrap textarea,
.filter-pop .btn-wrap .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .filter-pop .btn-wrap a,
.filter-pop .btn-wrap .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .filter-pop .btn-wrap a,
.filter-pop .btn-wrap .total_all .btn_replay,
.total_all .filter-pop .btn-wrap .btn_replay,
.filter-pop .btn-wrap .btn_expand,
.filter-pop .btn-wrap .add_member,
.market-float,
.sum-pl dt,
.head-balance,
.pop-left,
.pop-left dt,
.select-betting .selection,
.pop-right,
.initial-balance dt,
.final-balance dt,
.initial-balance dd,
.final-balance dd,
.initial-balance dt span,
.final-balance dt span,
.search-wrap,
.total_box .total_dl,
.total_all h2,
.total_all .input-list,
.total_all .filter-list,
.total_info,
.table01 strong.no,
.table-s strong.no,
.table_one strong.no,
.table01 .ico_account,
.table-s .ico_account,
.table_one .ico_account,
.btn_open,
.btn_close,
.lv_0,
.lv_1,
.lv_2,
.lv_3,
.lv_4,
.lv_sub,
.ico_dot1,
.ico_dot2,
.ico_dot3,
.table-pt .expand-close,
.table-pt .expand-open,
.pt_allowed dl,
.status_id p,
.agent_path ul,
.agent_path-L,
.agent_path-L img,
.total_all .agent_path,
.inline-form .form_list,
.inline-form .add_account_box dl,
.add_account_box .inline-form dl,
.inline-form .pt_allowed dl,
.pt_allowed .inline-form dl,
.inline-form .btn,
.inline-form .btn-send,
.inline-form .pages a,
.pages .inline-form a,
.inline-form .calendarTable_inputBox input,
.calendarTable_inputBox .inline-form input,
.inline-form .calendarTable_inputBox textarea,
.calendarTable_inputBox .inline-form textarea,
.inline-form .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .inline-form a,
.inline-form .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .inline-form a,
.inline-form .total_all .btn_replay,
.total_all .inline-form .btn_replay,
.inline-form .btn_expand,
.inline-form .add_member,
.top_player-wrap,
.racing-wrap,
.col-2,
.table01 .order,
.table-s .order,
.table_one .order,
.open-odds,
.close-odds,
.btn-book,
.banking-head dd span,
.DW-amount input,
.DW-amount textarea,
.DW-amount .member_select a,
.member_select .DW-amount a,
.DW-amount .btn,
.DW-amount .btn-send,
.DW-amount .pages a,
.pages .DW-amount a,
.DW-amount .calendarTable_inputBox input,
.calendarTable_inputBox .DW-amount input,
.DW-amount .calendarTable_inputBox textarea,
.calendarTable_inputBox .DW-amount textarea,
.DW-amount .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .DW-amount a,
.DW-amount .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .DW-amount a,
.DW-amount .total_all .btn_replay,
.total_all .DW-amount .btn_replay,
.DW-amount .btn_expand,
.DW-amount .add_member,
.btn_list-DW,
.credit-amount input,
.credit-amount textarea,
.credit-amount .member_select a,
.member_select .credit-amount a,
.wrap-refer_edit dt {
    float: left;
}

.table01 a,
.table-s a,
.table_one a {
    text-decoration: underline;
}

.favor-set {
    line-height: 25px;
    border-bottom-width: 0;
    padding-right: 23px;
    background-position: 100% -2625px;
}

.status-popup,
.access-popup {
    position: absolute;
    top: 10px;
    right: -155px;
    width: 160px;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    border-radius: 4px;
    z-index: 50;
}

.float-R,
.pop-warp.no-match_odds .pop-left .select-betting .selection,
.no-match_odds.log-wrap .pop-left .select-betting .selection,
.no-match_odds.streaming-pop .pop-left .select-betting .selection,
.btn-close,
.login-wrap,
.account-wrap,
.language,
.setting-wrap,
.time_zone,
.gmt,
.menu-list .Go,
.favor-set,
.event-right,
.live-head h3 span,
.total-show .sports-switch,
.menu .logout,
.setting-wrap .logout,
.menu .time_zone,
.setting-wrap .time_zone,
.total_all .btn_replay,
.btn_expand,
.add_member,
.table01 .action a,
.table-s .action a,
.table_one .action a,
.action,
.footer_info .action,
.status_id .status-active,
.status_id .status-suspend,
.status_id .status-lock,
.full-amount .btn,
.full-amount .btn-send,
.full-amount .pages a,
.pages .full-amount a,
.full-amount .calendarTable_inputBox input,
.calendarTable_inputBox .full-amount input,
.full-amount .calendarTable_inputBox textarea,
.calendarTable_inputBox .full-amount textarea,
.full-amount .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .full-amount a,
.full-amount .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .full-amount a,
.full-amount .btn_expand,
.full-amount .add_member,
.credit-amount .btn,
.credit-amount .btn-send,
.credit-amount .pages a,
.pages .credit-amount a,
.credit-amount .calendarTable_inputBox input,
.calendarTable_inputBox .credit-amount input,
.credit-amount .calendarTable_inputBox textarea,
.calendarTable_inputBox .credit-amount textarea,
.credit-amount .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .credit-amount a,
.credit-amount .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .credit-amount a,
.credit-amount .btn_expand,
.credit-amount .add_member,
.credit-amount-member .btn,
.credit-amount-member .btn-send,
.credit-amount-member .pages a,
.pages .credit-amount-member a,
.credit-amount-member .calendarTable_inputBox input,
.calendarTable_inputBox .credit-amount-member input,
.credit-amount-member .calendarTable_inputBox textarea,
.calendarTable_inputBox .credit-amount-member textarea,
.credit-amount-member .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .credit-amount-member a,
.credit-amount-member .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .credit-amount-member a,
.credit-amount-member .btn_expand,
.credit-amount-member .add_member,
.wrap-refer_edit dd .btn,
.wrap-refer_edit dd .btn-send,
.wrap-refer_edit dd .pages a,
.pages .wrap-refer_edit dd a,
.wrap-refer_edit dd .calendarTable_inputBox input,
.calendarTable_inputBox .wrap-refer_edit dd input,
.wrap-refer_edit dd .calendarTable_inputBox textarea,
.calendarTable_inputBox .wrap-refer_edit dd textarea,
.wrap-refer_edit dd .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .wrap-refer_edit dd a,
.wrap-refer_edit dd .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .wrap-refer_edit dd a,
.wrap-refer_edit dd .total_all .btn_replay,
.total_all .wrap-refer_edit dd .btn_replay,
.wrap-refer_edit dd .btn_expand,
.wrap-refer_edit dd .add_member,
.btn-add {
    float: right;
}

.table01 .action a,
.table-s .action a,
.table_one .action a {
    width: 26px;
    height: 26px;
    margin-left: 3px;
}

.delete,
.btn-close,
.header h1,
.mysports247 .header h1,
.maxinplay .header h1,
.maxexch9 .header h1,
.mazaplay .header h1,
.kingfair24 .header h1,
.alphaexch .header h1,
.alphaexchlive .header h1,
.dashboard .header h1,
.skyfair .header h1,
.mcwex .header h1,
.goexch365 .header h1,
.agexch247 .header h1,
.suninplay .header h1,
.oxyinplay .header h1,
.iceEX .header h1,
.o_spondemo .header h1,
.o_12bet .header h1,
.dafabet .header h1,
.skyinplay .header h1,
.probetx .header h1,
.etoroexch .header h1,
.probet247 .header h1,
.bigexch .header h1,
.sixball .header h1,
.sixballio .header h1,
.sgexch247 .header h1,
.sharet365 .header h1,
.baji .header h1,
.baji365 .header h1,
.baazi365 .header h1,
.lionexch .header h1,
.masterexchange .header h1,
.matador .header h1,
.fairenter .header h1,
.lucky7 .header h1,
.dhoom .header h1,
.qexch .header h1,
.iceinplay .header h1,
.betmygame .header h1,
.regalexch365 .header h1,
.khelexch .header h1,
.fancyfair .header h1,
.maruti .header h1,
.marutibook .header h1,
.dashboard247 .header h1,
.dashboard247com .header h1,
.oceanexch1 .header h1,
.oceanbook1 .header h1,
.wicketspro .header h1,
.yabo101sport .header h1,
.aecricket888 .header h1,
.cftbet365 .header h1,
.crickzoom .header h1,
.crickzoomlive .header h1,
.betbarter .header h1,
.wickets247 .header h1,
.crickex .header h1,
.hmsbet .header h1,
.cricfair .header h1,
.jeetbuzz .header h1,
.enterfair .header h1,
.language,
.menu-list .Go,
.path .path-back,
.favor,
.tag-live strong,
.transfer-wrap .close,
.transfer-wrap-slider .close,
.transferId-con dd .copy-but,
.transferId-con .mainBalance dd .refresh-but,
.login_to_go .close,
.close_pop,
.table01 .action a,
.table-s .action a,
.table_one .action a,
.btn_open,
.btn_close,
.odd-add a,
.open-odds,
.close-odds,
.credit-amount-member .btn,
.credit-amount-member .btn-send,
.credit-amount-member .pages a,
.pages .credit-amount-member a,
.credit-amount-member .calendarTable_inputBox input,
.calendarTable_inputBox .credit-amount-member input,
.credit-amount-member .calendarTable_inputBox textarea,
.calendarTable_inputBox .credit-amount-member textarea,
.credit-amount-member .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .credit-amount-member a,
.credit-amount-member .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .credit-amount-member a,
.credit-amount-member .total_all .btn_replay,
.total_all .credit-amount-member .btn_replay,
.credit-amount-member .btn_expand,
.credit-amount-member .add_member {
    font-size: 0;
    text-indent: -99999px;
}

.bank {
    background-position: -32px -196px;
}

.betting_history {
    background-position: -32px -308px;
}

.p_l {
    background-position: -32px -1475px;
}

.status {
    background-position: -32px -1618px;
}

.profile {
    background-position: -32px -1527px;
}

.gensetting {
    background-position: -30px -1193px;
}

.margin-table:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 36px;
    border-top: 1px solid #7E97A7;
}

.expand-balance {
    table-layout: fixed;
}

.expand {
    background-color: #E2E8ED;
}

.expand-balance>td {
    padding: 0;
}

.expand>td {
    position: relative;
    padding: 8px 10px;
    border-top-color: #7e97a7;
    border-bottom: 1px solid #7e97a7;
}

.expand>td,
.table01 .border-t td,
.table-s .border-t td,
.table_one .border-t td,
.slip-head {
    box-shadow: inset 0 2px 0 rgb(0 0 0 / 10%);
}

.expand-arrow {
    position: absolute;
    left: 15%;
    margin-left: -10px;
    background-position: -17px -127px;
    height: 9px;
    width: 10px;
}

.report-sprite,
.cal-input,
.upcome dd,
.filter-list .filter-content span,
.filter-list.golf-list .compt img,
.filter-list.golf-list .compt:hover img,
.expand-close,
.expand-open,
.align-L .expand-close,
.table01 caption .expand-close,
.table-s caption .expand-close,
.table_one caption .expand-close,
.no-data .expand-close,
.status-popup .expand-close,
.access-popup .expand-close,
.table_one thead th .expand-close,
.table-pt .expand td.expand-event:first-child .expand-close,
.pt_allowed dl dt .expand-close,
.tab-transfer .full-amount .expand-close,
.tab-transfer .remark .expand-close,
.tips-popup .expand-close,
.slip-head .col-playID .expand-close,
.slip-head .col-type .expand-close,
.slip-head .col-country .expand-close,
.slip-head .col-local .expand-close,
.table01 .td-type .expand-close,
.table-s .td-type .expand-close,
.table_one .td-type .expand-close,
.table01 .td-UID .expand-close,
.table-s .td-UID .expand-close,
.table_one .td-UID .expand-close,
.td-uid .expand-close,
.DW-amount .expand-close,
.pop_bg .form-wrap h1 .expand-close,
.align-L .expand-open,
.table01 caption .expand-open,
.table-s caption .expand-open,
.table_one caption .expand-open,
.no-data .expand-open,
.status-popup .expand-open,
.access-popup .expand-open,
.table_one thead th .expand-open,
.table-pt .expand td.expand-event:first-child .expand-open,
.pt_allowed dl dt .expand-open,
.tab-transfer .full-amount .expand-open,
.tab-transfer .remark .expand-open,
.tips-popup .expand-open,
.slip-head .col-playID .expand-open,
.slip-head .col-type .expand-open,
.slip-head .col-country .expand-open,
.slip-head .col-local .expand-open,
.table01 .td-type .expand-open,
.table-s .td-type .expand-open,
.table_one .td-type .expand-open,
.table01 .td-UID .expand-open,
.table-s .td-UID .expand-open,
.table_one .td-UID .expand-open,
.td-uid .expand-open,
.DW-amount .expand-open,
.pop_bg .form-wrap h1 .expand-open,
.expand-arrow,
.expand-arrow-R,
.acc-info .user,
.acc-info .time,
.fromto,
.prevMonth,
.nextMonth,
.selection,
.trans-replay,
.btn_expand:before,
.btn_expand.close:before {
    background-image: url(./sprite/report-sf227858fbc.png);
    background-repeat: no-repeat;
}

.expand table {
    width: 85%;
    border-left: 1px solid #7e97a7;
    border-bottom-width: 0;
    margin-left: 15%;
}

.expand table th {
    background-color: transparent;
    border-top-width: 0;
}

.expand-balance th {
    padding: 8px 10px;
}

.btn-recall {
    color: #3b5160;
    font-size: 15px;
    font-weight: bold;
    line-height: 2.2;
    padding: 0 8px;
    background-color: rgba(94, 190, 255, 0.15);
    border-radius: 4px;
    border: 1px solid #7e97a7;
    cursor: pointer;
}

.footer_info {
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 51;
    display: flex;
}

.footer_info .main_wrap,
.footer_info .header,
.footer_info .footer-content {
    border-top: 1px solid #d4d4d4;
    background-color: #eee;
}

.footer_info .action {
    margin: 0 auto;
    padding: 5px 0;
}

.footer_info .action li {
    height: 26px;
    font-size: 11px;
    padding-left: 30px;
}

.bank {
    background-position: -32px -196px;
}

.footer_info .action p {
    line-height: 11px;
    padding-top: 7px;
    margin-right: 20px;
}

.p_l {
    background-position: -32px -1475px;
}

.betting_history {
    background-position: -32px -308px;
}

.profile {
    background-position: -32px -1527px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 53;
}

.news-wrap {
    top: 72px;
    width: 640px;
}

.announce-wrap,
.casino-result {
    position: relative;
    top: 12%;
    width: 720px;
    height: auto;
    margin: 0 auto;
    margin-bottom: 24px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 20px rgb(0 0 0 / 50%);
}

.dashboard .news-wrap .announce-header {
    background: url(./popup-header-skyexchange.png) center no-repeat;
}

.news-wrap .announce-header {
    height: 60px;
    background-color: #FFB80C;
    background-image: linear-gradient(-180deg, #FFCC2E 0%, #FFB80C 58%);
    font-size: 24px;
    font-weight: bold;
    line-height: 60px;
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 8px 8px 0 0;
}

.announce-content {
    font-size: 16px;
    line-height: 22px;
}

.news-wrap .announce-content {
    height: 412px;
    padding: 24px 20px;
}

.tc-content,
.tc-content_few,
.announce-content {
    overflow-y: scroll;
    height: calc(580px - 56px - 72px);
    padding: 16px 24px;
    font-size: 14px;
    line-height: 24px;
}

.announce-content p {
    margin-bottom: 8px;
}

.news-wrap .announce-content img {
    width: 100%;
    box-shadow: 0px 1px 5px #ddd;
    margin: 20px 0;
}

.announce-footer {
    max-height: 73px;
    padding: 12px 0;
    background: #ffffff;
    border-top: 1px solid #E0E6E6;
    font-size: 16px;
    border-radius: 0 0 8px 8px;
}

.dashboard .btn-send {
    background: #121212 url(./skyexchange/bg-send-skyEX.png) repeat-x;
    color: #F8D61C;
    border-color: #000;
}

.function-wrap {
    position: relative;
    padding: 10px 10px 0;
    background-color: #E0E6E6;
    border-bottom: 1px solid #7e97a7;
    margin-bottom: 10px;
}

.banking-head {
    margin-bottom: 10px;
}

.banking-head dt {
    font-size: 13px;
    font-weight: bold;
    color: #3b5160;
    padding-right: 10px;
    margin-right: -5px;
    border-right: 1px solid #7e97a7;
}

.banking-head dt,
.banking-head dd {
    line-height: 30px;
    vertical-align: top;
}

.banking-head dd {
    height: 30px;
    font-size: 23px;
    font-weight: bold;
    padding-left: 10px;
}

.banking-head dd span {
    font-size: 13px;
    line-height: 15px;
    font-weight: normal;
    color: #3b5160;
    margin: 8px 5px 0 0;
}

.function-wrap.clearfix {
    display: flex;
}

.tab-transfer th {
    padding: 1px 10px;
    line-height: 15px;
}

.sort-this:after {
    content: '';
    background-position: -47px -163px;
    height: 7px;
    width: 11px;
}

.check_balance .btn,
.check_balance .btn-send,
.check_balance .pages a,
.pages .check_balance a,
.check_balance .calendarTable_inputBox input,
.calendarTable_inputBox .check_balance input,
.check_balance .calendarTable_inputBox textarea,
.calendarTable_inputBox .check_balance textarea,
.check_balance .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .check_balance a,
.check_balance .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .check_balance a,
.check_balance .total_all .btn_replay,
.total_all .check_balance .btn_replay,
.check_balance .btn_expand,
.check_balance .add_member {
    padding: 0 5px;
    width: fit-content;
    float: left;
    margin-right: 5px;
}

.tab-transfer .btn,
.tab-transfer .btn-send,
.tab-transfer .pages a,
.pages .tab-transfer a,
.tab-transfer .calendarTable_inputBox input,
.calendarTable_inputBox .tab-transfer input,
.tab-transfer .calendarTable_inputBox textarea,
.calendarTable_inputBox .tab-transfer textarea,
.tab-transfer .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .tab-transfer a,
.tab-transfer .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .tab-transfer a,
.tab-transfer .total_all .btn_replay,
.total_all .tab-transfer .btn_replay,
.tab-transfer .btn_expand,
.tab-transfer .add_member {
    height: 28px;
    line-height: 28px;
    width: 50px;
}

.table01 .border-l,
.table-s .border-l,
.table_one .border-l {
    border-left: 1px solid #7E97A7;
}

.check_balance span {
    display: inline-block;
    line-height: 28px;
    width: 100%;
}

#checkBankingBalance {
    margin-bottom: 0;
}

.submit-wrap {
    position: sticky;
    width: 100%;
    bottom: 0;
    z-index: 51;
    padding: 10px 0;
    background-color: #eee;
    text-align: center;
}

.submit-wrap li {
    vertical-align: middle;
}

.submit-wrap .btn,
.submit-wrap .btn-send,
.submit-wrap .pages a,
.pages .submit-wrap a,
.submit-wrap .calendarTable_inputBox input,
.calendarTable_inputBox .submit-wrap input,
.submit-wrap .calendarTable_inputBox textarea,
.calendarTable_inputBox .submit-wrap textarea,
.submit-wrap .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .submit-wrap a,
.submit-wrap .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .submit-wrap a,
.submit-wrap .total_all .btn_replay,
.total_all .submit-wrap .btn_replay,
.submit-wrap .btn_expand,
.submit-wrap .add_member {
    width: 130px;
    line-height: 33px;
    font-size: 14px;
    margin: 0;
}

.submit-wrap .submit-payment {
    background-color: #c5d0d7;
    border-radius: 6px;
    padding: 5px;
    margin-left: 10px;
}

.submit-wrap li {
    vertical-align: middle;
}

.submit-wrap input,
.submit-wrap textarea,
.submit-wrap .member_select a,
.member_select .submit-wrap a {
    width: 180px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    padding: 0;
    margin: 0;
    text-align: center;
}

.skyfair .btn-send {
    background: #121212 url(./skyfair/bg-send-skyfair.png) repeat-x;
    color: #F8D61C;
    border-color: #000;
}

.submit-wrap .btn-send {
    width: 228px;
    margin-left: 5px;
    float: right;
}

.skyfair .submit-wrap .btn-send span {
    background-color: #666;
}

.submit-wrap .btn-send span {
    width: 24px;
    height: 24px;
    background-color: #CB8009;
    color: #fff;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    border-radius: 50%;
    vertical-align: top;
    margin-top: 4px;
}

h2 {
    font-size: 13px;
    line-height: 20px;
    padding-top: 6px;
    margin-bottom: 6px;
}

.function-wrap {
    position: relative;
    background-color: #E0E6E6;
    border-bottom: 1px solid #7e97a7;
    margin-bottom: 10px;
    display: inline-block;
    padding-top: 10px;
    width: 99%;
    padding: 10px 0px 0px 10px;
}

.dashboard .main_wrap .function-wrap ul {
    width: 100%;
}

.head-wrap-profit p {
    display: inline-block;
}

.cal-input {
    width: 135px;
    cursor: pointer;
    background-position: 100% -23px;
}

input.disable,
textarea.disable,
.member_select a.disable {
    background-color: #dcdcdc;
    color: #666;
    box-shadow: none;
    border: 1px solid #bbb;
}

.time-input {
    width: 45px;
}

.input-list .btn,
.filter-list .btn,
.input-list .btn-send,
.filter-list .btn-send,
.input-list .pages a,
.pages .input-list a,
.filter-list .pages a,
.pages .filter-list a,
.input-list .calendarTable_inputBox input,
.calendarTable_inputBox .input-list input,
.filter-list .calendarTable_inputBox input,
.calendarTable_inputBox .filter-list input,
.input-list .calendarTable_inputBox textarea,
.calendarTable_inputBox .input-list textarea,
.filter-list .calendarTable_inputBox textarea,
.calendarTable_inputBox .filter-list textarea,
.input-list .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .input-list a,
.filter-list .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .filter-list a,
.input-list .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .input-list a,
.filter-list .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .filter-list a,
.input-list .total_all .btn_replay,
.total_all .input-list .btn_replay,
.filter-list .total_all .btn_replay,
.total_all .filter-list .btn_replay,
.input-list .btn_expand,
.filter-list .btn_expand,
.input-list .add_member,
.filter-list .add_member {
    width: 95px;
    margin: 0;
    font-weight: normal;
}

.input-list .btn-send,
.filter-list .btn-send {
    font-weight: bold;
}

.downlineprofitloss,
.betlist {
    height: 100vh;
}

#eventRadioBtnList input[type=checkbox],
textarea[type=checkbox],
.member_select a[type=checkbox],
input[type=radio],
textarea[type=radio],
.member_select a[type=radio] {
    width: auto !important;
    height: auto !important;
    border-width: 0;
    border-radius: 0;
    background-color: transparent !important;
    padding: 0 !important;
    box-shadow: none;
}

p {
    margin-bottom: 7px;
}

.play_race-wrap {
    margin-bottom: 20px;
}

.top_player-wrap {
    width: calc(100% - 467px - 15px);
    margin-right: 15px;
}

.top_player-wrap,
.racing-wrap {
    margin-bottom: 0;
}

.top_player-wrap,
.racing-wrap,
.game-wrap,
.game-highlight-wrap,
.favor-wrap,
.sub_path,
.pop-title {
    background-color: #fff;
    border-bottom: 1px solid #7e97a7;
    margin-bottom: 1px;
}

.top_player-wrap h3 {
    position: relative;
    height: 25px;
}

.top_player-wrap h3,
.racing-wrap h3 {
    box-shadow: 0 2px 0 rgb(0 0 0 / 10%);
    background-position: 0 -7280px;
}

.top_player-wrap h3,
.racing-wrap h3,
.game-wrap h3,
.game-highlight-wrap h3,
.favor-wrap h3,
.sub_path h3,
.pop-title h3 {
    color: #fff;
    font-size: 12px;
    line-height: 25px;
    font-weight: normal;
    padding-left: 10px;
    background-repeat: repeat-x;
    background-position: 0 -7155px;
}

.tab-topplay {
    position: absolute;
    top: -3px;
    left: 12px;
}

.tab-topplay .select {
    height: 30px;
    z-index: 2;
}

.tab-topplay li {
    position: relative;
    height: 28px;
    line-height: 28px;
    font-weight: bold;
    padding: 0 19px 0 5px;
    margin-left: -12px;
}

.tab-topplay li:before {
    position: absolute;
    left: 0;
    content: '';
    background-position: -53px -364px;
    height: 28px;
    width: 5px;
}

.tab-topplay .select:after {
    background-position: -39px -392px;
    height: 30px;
    width: 19px;
}

.tab-topplay li:after {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    background-position: -39px -422px;
    height: 28px;
    width: 19px;
}

.tab-topplay .select a {
    color: #254D6A;
    background-repeat: repeat-x;
    background-position: 0 -12340px !important;
}

.top_player-wrap h3 a,
.racing-wrap h3 a,
.game-wrap h3 a,
.game-highlight-wrap h3 a,
.favor-wrap h3 a,
.sub_path h3 a,
.pop-title h3 a {
    color: #fff;
}

.col-2 {
    width: 50%;
}

.slip-head {
    background-color: #CED5DA;
    padding: 0px 11px;
    width: 95%;
}

.slip-head .col-playID {
    width: calc(100% - 100px - 130px);
}

.slip-head li {
    padding: 5px 0;
}

.slip-head .col-exp {
    width: 100px;
}

.top_player-wrap .slip-head .col-amount {
    width: 130px;
}

.slip-head .col-amount {
    width: 111px;
}

.top_player-wrap .table01,
.top_player-wrap .table-s,
.top_player-wrap .table_one,
.racing-wrap .table01,
.racing-wrap .table-s,
.racing-wrap .table_one {
    margin-bottom: 0;
    border-right: 1px solid #eee;
    border-bottom-width: 0;
}

.match-wrap {
    background: #DDDCD7;
    box-shadow: 0 2px 0 0 #fff, inset 0 2px 0 0 rgb(0 0 0 / 10%);
    border-radius: 5px;
    padding: 7px 10px 5px;
    margin-bottom: 25px;
}

.bg-sprite,
.top_player-wrap h3,
.racing-wrap h3,
.game-wrap h3,
.game-highlight-wrap h3,
.favor-wrap h3,
.sub_path h3,
.pop-title h3,
.one_click,
.one_click.select,
.path,
.upcome-head,
.tab-highlight,
.live-head h3,
.live-channel,
.change-channel,
.tab-topplay a,
.tab-topplay .select a,
.o_12bet .path,
.o_12bet .slip-wrap h3,
.o_12bet .matched-wrap h3,
.o_12bet .slider-bar span,
.o_12bet .upcome-head,
.o_12bet .tab-highlight,
.o_12bet .one_click-setting,
.dafabet .tab-highlight,
.dafabet .one_click-setting,
.mazaplay .tab-highlight,
.mazaplay .one_click-setting,
.mysports247 .path,
.mysports247 .tab-highlight,
.mysports247 .one_click-setting,
.skyexchange .one_click-setting,
.o_spondemo .path,
.o_spondemo .slip-wrap h3,
.o_spondemo .matched-wrap h3,
.o_spondemo .slider-bar span,
.o_spondemo .upcome-head,
.o_spondemo .one_click-setting,
.skyinplay .path,
.skyinplay .tab-highlight,
.skyinplay .one_click-setting,
.bigexch .path,
.bigexch .tab-highlight,
.bigexch .one_click-setting,
.masterexchange .path,
.masterexchange .tab-highlight,
.masterexchange .one_click-setting,
.matador .tab-highlight,
.matador .one_click-setting,
.fairenter .path,
.fairenter .tab-highlight,
.fairenter .one_click-setting,
.lucky7 .path,
.lucky7 .tab-highlight,
.lucky7 .one_click-setting,
.qexch .path,
.qexch .tab-highlight,
.qexch .one_click-setting,
.betmygame .path,
.betmygame .tab-highlight,
.betmygame .one_click-setting,
.fancyfair .one_click,
.fancyfair .one_click.select,
.fancyfair .path,
.fancyfair .tab-highlight,
.fancyfair .one_click-setting,
.maruti .tab-highlight,
.maruti .one_click-setting,
.marutibook .one_click,
.marutibook .one_click.select,
.marutibook .tab-highlight,
.marutibook .one_click-setting,
.skyexchange247 .one_click-setting,
.oceanexch1 .path,
.oceanexch1 .tab-highlight,
.oceanbook1 .path,
.oceanbook1 .tab-highlight,
.probetx .path,
.probetx .tab-highlight,
.probetx .one_click-setting,
.alphaexch .tab-highlight,
.alphaexch .one_click-setting,
.alphaexchlive .tab-highlight,
.alphaexchlive .one_click-setting,
.maxexch9 .path,
.maxexch9 .tab-highlight,
.maxexch9 .one_click-setting,
.cftbet365 .one_click,
.cftbet365 .one_click.select,
.cftbet365 .tab-highlight,
.cftbet365 .one_click-setting,
.crickzoom .path,
.crickzoom .tab-highlight,
.crickzoom .one_click-setting,
.crickzoomlive .path,
.crickzoomlive .tab-highlight,
.crickzoomlive .one_click-setting,
.maxinplay .path,
.maxinplay .tab-highlight,
.maxinplay .one_click-setting,
.betbarter .path,
.betbarter .slip-wrap h3,
.betbarter .matched-wrap h3,
.betbarter .slider-bar span,
.betbarter .upcome-head,
.betbarter .tab-highlight,
.betbarter .one_click-setting,
.probet247 .path,
.probet247 .tab-highlight,
.probet247 .one_click-setting,
.kingfair24 .tab-highlight,
.kingfair24 .one_click-setting,
.sgexch247 .path,
.sgexch247 .tab-highlight,
.sgexch247 .one_click-setting,
.sixball .path,
.sixball .tab-highlight,
.sixball .one_click-setting,
.sixballio .path,
.sixballio .tab-highlight,
.sixballio .one_click-setting,
.wickets247 .one_click-setting,
.crickex .tab-highlight,
.crickex .one_click,
.crickex .one_click.select,
.crickex .one_click-setting,
.iceEX .one_click,
.iceinplay .path,
.iceinplay .tab-highlight,
.iceinplay .one_click-setting,
.baji .one_click-setting,
.baji365 .one_click-setting,
.sportsexchlive .one_click-setting,
.sharet365 .one_click-setting,
.baazi365 .one_click-setting,
.goexch365 .one_click,
.goexch365 .one_click-setting,
.etoroexch .path,
.etoroexch .one_click-setting,
.oxyinplay .one_click-setting,
.cricfair .tab-highlight,
.cricfair .one_click,
.cricfair .one_click.select,
.cricfair .one_click-setting,
.khelexch .one_click-setting,
.lionexch .one_click-setting,
.hmsbet .path,
.hmsbet .tab-highlight,
.hmsbet .one_click-setting,
.jeetbuzz .one_click-setting,
.skyfair .one_click-setting,
.skyexchange247com .one_click-setting,
.mcwex .one_click-setting,
.enterfair .path,
.enterfair .one_click-setting {
    background: url(./media/bg-s2577d67037.7970b883.png) repeat-x;
    background-repeat: no-repeat;
}

.top_player-wrap ul.react-tabs__tab-list {
    background: #294252;
}

ul.react-tabs__tab-list li {
    width: 22%;
    display: inline-block;
    padding: 10px;
}

ul.react-tabs__tab-list li a {
    color: #fff;
}

.top_player-wrap h3 {
    background: #294252;
}

.total_all h2 {
    font-size: 16px;
    color: #243a48;
}

.bg-yellow {
    background-color: #F3DFB0 !important;
}

/* Start My Account CSS */
.sub_path {
    position: relative;
}

.dashboard .menu-list li {
    border-bottom-color: #EFE1C1;
    width: 100%;
    float: left;
    margin: 0;
    padding: 0;
}

.col-left .class {
    color: #fff;
    padding: 0 10px;
    background: #243a48;
    line-height: 25px;
    padding: 0 10px;
}

.menu-list li {
    border-bottom: 1px solid #E0E6E6;
    list-style: none;
}

.dashboard .menu-list a {
    color: #000;
    width: 91.5%;
}

.dashboard .sub_path .menu-list a {
    background: #fff;
}

.menu-list a {
    line-height: 25px;
    padding: 0 10px;
}

.dashboard .sub_path .menu-list .select {
    background-color: #F2DCA7;
    color: #000;
}

.dashboard .sub_path .menu-list .select a {
    background-color: #F2DCA7;
    color: #000;
}

.pop-warp .game-name p,
.log-wrap .game-name p,
.streaming-pop .game-name p,
.menu-list a,
.path-pop a,
.upcome,
.live-list a,
.live-channel,
.filter-list .filter-content,
.select-betting .selection,
.suggestion-wrap .no-matching,
.suggestion-wrap p,
.suggestion-wrap a,
.table-pt td,
.table-pt .ico_account,
.table01 .td-UID,
.table-s .td-UID,
.table_one .td-UID {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.col-left {
    width: 17.36111%;
    display: inline-block;
}

.agent_path {
    margin: 15px 0 10px 0;
    display: inline-block;
    width: 100%;
}

.total_all .agent_path {
    width: auto;
}

.agent_path ul {
    background: url(./agent/ag_path_bg.gif);
    border: 0px;
    border-radius: 3px;
}

.agent_path ul li {
    position: relative;
    font-size: 16px;
    line-height: 30px;
    font-weight: bold;
    list-style: none;
}

.agent_path-L li {
    padding: 0 15px 0 10px;
    background-position: 100% -45px;
}

.agent_path-L .last_li {
    background-image: none;
    padding-right: 5px;
}

.agent_path-L a {
    color: #1E1E1E;
}

.agent_path a {
    height: 30px;
}

.agent_path-L span {
    margin-top: 7px;
    font-weight: lighter;
    text-align: center;
}

.lv_0,
.lv_1,
.lv_2,
.lv_3,
.lv_4,
.lv_sub {
    width: 26px;
    height: 15px;
    line-height: 15px;
    text-decoration: none !important;
    color: #fff;
    background: #D77319;
    border-radius: 4px;
    margin-right: 5px;
    font-size: 10px;
    text-align: center;
}

.agent_path-L .account_pop {
    top: 30px;
}

.account_pop:not(:target) {
    display: none;
}

.account_pop {
    position: absolute;
    left: 0;
    background-color: #fff;
    z-index: 99;
    overflow-y: auto;
}

.account_pop li {
    border-bottom: 1px solid #E0E6E6;
    float: none;
    background: none;
    padding: 0;
}

.agent_path .account_pop a {
    height: 25px;
    line-height: 25px;
}

.account_pop a {
    min-width: 150px;
    background-image: none;
    padding: 0 15px;
    font-size: 12px;
    font-weight: normal;
    border-left: none;
    font-size: 12px;
    text-indent: 0;
}

.agent_path-L .last_li {
    background-image: none;
    padding-right: 5px;
}

.lv_3 {
    background: #85B352;
}

.dashboard .menu-list li.class {
    padding-left: 10px;
    width: 96%;
}

.col-center.report {
    padding-right: 0;
    margin-right: 0;
    float: right;
    width: 81.63889%;
    margin: 0 !important;
    padding: 0;
}

.displayflexy {
    display: flex;
}

.col-center {
    position: relative;
    margin-left: 17.36111%;
    margin-right: 26.04167%;
    padding: 0 15px;
}

.col-center h2 {
    font-size: 16px;
    line-height: 16px;
    color: #243a48;
    padding-top: 0;
    margin: 15px 0;
}

.white-wrap,
.total_box,
.fix-content {
    background-color: #fff;
    color: #3b5160;
    padding: 7px 10px 5px;
    border-bottom: 1px solid #7e97a7;
    margin-bottom: 15px;
}

.head-balance {
    width: 31.37255%;
    border-right: 1px solid #d8d8d8;
    margin-right: 10px;
}

.head-balance dt {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 7px;
}

.head-balance dd {
    font-size: 30px;
    line-height: 36px;
    font-weight: bold;
    color: #2789ce;
}

.head-balance dd span {
    font-size: 12px;
    color: #7e97a7;
    font-weight: normal;
}

.account-summary h2 {
    margin-top: 5px;
}

.fromto {
    margin: 0 5px;
    background-position: -20px -182px;
    height: 8px;
    width: 7px;
}

.tab-topplay .select:before {
    background-position: -53px -334px;
    height: 30px;
    width: 5px;
}

.event-left,
.event-right {
    width: 49.5%;
}

.profile-wrap {
    background-color: #fff;
    border-bottom: 1px solid #7e97a7;
    margin-bottom: 15px;
}

.profile-wrap h3 {
    background-color: #7e97a7;
    color: #fff;
    line-height: 24px;
    padding: 0 10px;
}

.profile-wrap dt {
    width: 125px;
    padding: 5px 0 5px 10px;
    color: #243a48;
}

.profile-wrap dd:last-child {
    border-bottom-width: 0;
}

.event-left dt,
.event-right dt {
    font-size: 11px;
}

.profile-wrap dd {
    position: relative;
    min-height: 16px;
    padding: 5px 0 5px 10px;
    border-bottom: 1px solid #E0E6E6;
}

.profile-wrap {
    background-color: #fff;
    border-bottom: 1px solid #7e97a7;
    margin-bottom: 15px;
}

.left-sprite,
.top_player-wrap .to-expand,
.racing-wrap .to-expand,
.game-wrap .to-expand,
.game-highlight-wrap .to-expand,
.favor-wrap .to-expand,
.sub_path .to-expand,
.pop-title .to-expand,
.close.top_player-wrap .to-expand,
.close.racing-wrap .to-expand,
.close.game-wrap .to-expand,
.close.game-highlight-wrap .to-expand,
.close.favor-wrap .to-expand,
.close.sub_path .to-expand,
.close.pop-title .to-expand,
.menu-list .Go,
.star,
.favor-wrap h3 .star,
.favor-wrap .menu-list a.btn-star,
.favor-set,
.path li,
.path .path-back,
.path .path-back.select,
.path-pop a,
.to-go,
.o_12bet .path .path-back,
.o_12bet .path .path-back.select,
.o_12bet .path-pop a,
.dafabet .menu-list .Go,
.dafabet .path li,
.dafabet .path .path-back,
.dafabet .path .path-back.select,
.dafabet .path-pop a,
.mazaplay .menu-list .Go,
.mazaplay .path .path-back,
.mazaplay .path .path-back.select,
.mysports247 .menu-list .Go,
.mysports247 .path .path-back,
.mysports247 .path .path-back.select,
.skyexchange .menu-list .Go,
.skyexchange .path .path-back,
.skyexchange .path .path-back.select,
.o_spondemo .path .path-back,
.o_spondemo .path .path-back.select,
.o_spondemo .path-pop a,
.skyinplay .menu-list .Go,
.skyinplay .path .path-back,
.skyinplay .path .path-back.select,
.bigexch .menu-list .Go,
.bigexch .path .path-back,
.bigexch .path .path-back.select,
.masterexchange .menu-list .Go,
.masterexchange .path .path-back,
.masterexchange .path .path-back.select,
.masterexchange .path-pop a,
.matador .menu-list .Go,
.matador .path li,
.matador .path .path-back,
.matador .path .path-back.select,
.matador .path-pop a,
.fairenter .menu-list .Go,
.fairenter .path .path-back,
.fairenter .path .path-back.select,
.lucky7 .menu-list .Go,
.lucky7 .path .path-back,
.lucky7 .path .path-back.select,
.qexch .menu-list .Go,
.qexch .path .path-back,
.qexch .path .path-back.select,
.qexch .path-pop a,
.betmygame .menu-list .Go,
.betmygame .path .path-back,
.betmygame .path .path-back.select,
.betmygame .path-pop a,
.fancyfair .menu-list .Go,
.fancyfair .path .path-back,
.fancyfair .path .path-back.select,
.fancyfair .path-pop a,
.maruti .menu-list .Go,
.maruti .path .path-back,
.maruti .path .path-back.select,
.marutibook .menu-list .Go,
.marutibook .path .path-back,
.marutibook .path .path-back.select,
.skyexchange247 .menu-list .Go,
.skyexchange247 .path .path-back,
.skyexchange247 .path .path-back.select,
.oceanexch1 .menu-list .Go,
.oceanexch1 .path .path-back,
.oceanexch1 .path .path-back.select,
.oceanexch1 .path-pop a,
.oceanbook1 .menu-list .Go,
.oceanbook1 .path .path-back,
.oceanbook1 .path .path-back.select,
.oceanbook1 .path-pop a,
.probetx .menu-list .Go,
.probetx .path .path-back,
.probetx .path .path-back.select,
.alphaexch .menu-list .Go,
.alphaexch .path .path-back,
.alphaexch .path .path-back.select,
.alphaexchlive .menu-list .Go,
.alphaexchlive .path .path-back,
.alphaexchlive .path .path-back.select,
.maxexch9 .menu-list .Go,
.maxexch9 .path .path-back,
.maxexch9 .path .path-back.select,
.cftbet365 .menu-list .Go,
.cftbet365 .path .path-back,
.cftbet365 .path .path-back.select,
.crickzoom .menu-list .Go,
.crickzoom .path .path-back,
.crickzoom .path .path-back.select,
.crickzoomlive .menu-list .Go,
.crickzoomlive .path .path-back,
.crickzoomlive .path .path-back.select,
.maxinplay .menu-list .Go,
.maxinplay .path .path-back,
.maxinplay .path .path-back.select,
.betbarter .path .path-back,
.betbarter .path .path-back.select,
.betbarter .path-pop a,
.probet247 .menu-list .Go,
.probet247 .path .path-back,
.probet247 .path .path-back.select,
.kingfair24 .menu-list .Go,
.kingfair24 .path .path-back,
.kingfair24 .path .path-back.select,
.sgexch247 .menu-list .Go,
.sgexch247 .path .path-back,
.sgexch247 .path .path-back.select,
.sixball .menu-list .Go,
.sixball .path .path-back,
.sixball .path .path-back.select,
.sixballio .menu-list .Go,
.sixballio .path .path-back,
.sixballio .path .path-back.select,
.wickets247 .menu-list .Go,
.wickets247 .path .path-back,
.wickets247 .path .path-back.select,
.crickex .menu-list .Go,
.crickex .path .path-back,
.crickex .path .path-back.select,
.iceEX .menu-list .Go,
.iceEX .path .path-back,
.iceEX .path .path-back.select,
.iceinplay .menu-list .Go,
.iceinplay .path .path-back,
.iceinplay .path .path-back.select,
.iceinplay .path-pop a,
.dhoom .menu-list .Go,
.dhoom .path .path-back,
.dhoom .path .path-back.select,
.baji .menu-list .Go,
.baji .path .path-back,
.baji .path .path-back.select,
.agexch247 .menu-list .Go,
.agexch247 .path .path-back,
.agexch247 .path .path-back.select,
.baji365 .menu-list .Go,
.baji365 .path .path-back,
.baji365 .path .path-back.select,
.sportsexchlive .menu-list .Go,
.sportsexchlive .path .path-back,
.sportsexchlive .path .path-back.select,
.sharet365 .menu-list .Go,
.sharet365 .path .path-back,
.sharet365 .path .path-back.select,
.baazi365 .menu-list .Go,
.baazi365 .path .path-back,
.baazi365 .path .path-back.select,
.goexch365 .menu-list .Go,
.goexch365 .path .path-back,
.goexch365 .path .path-back.select,
.etoroexch .menu-list .Go,
.etoroexch .path .path-back,
.etoroexch .path .path-back.select,
.oxyinplay .menu-list .Go,
.oxyinplay .path .path-back,
.oxyinplay .path .path-back.select,
.cricfair .menu-list .Go,
.cricfair .path .path-back,
.cricfair .path .path-back.select,
.khelexch .menu-list .Go,
.khelexch .path .path-back,
.khelexch .path .path-back.select,
.khelexch .path-pop a,
.lionexch .menu-list .Go,
.lionexch .path .path-back,
.lionexch .path .path-back.select,
.lionexch .path-pop a,
.hmsbet .menu-list .Go,
.hmsbet .path .path-back,
.hmsbet .path .path-back.select,
.regalexch365 .menu-list .Go,
.regalexch365 .path .path-back,
.regalexch365 .path .path-back.select,
.regalexch365 .path-pop a,
.jeetbuzz .menu-list .Go,
.jeetbuzz .path .path-back,
.jeetbuzz .path .path-back.select,
.skyfair .menu-list .Go,
.skyfair .path .path-back,
.skyfair .path .path-back.select,
.skyexchange247com .menu-list .Go,
.skyexchange247com .path .path-back,
.skyexchange247com .path .path-back.select,
.mcwex .menu-list .Go,
.mcwex .path .path-back,
.mcwex .path .path-back.select,
.enterfair .menu-list .Go,
.enterfair .path .path-back,
.enterfair .path .path-back.select,
.suninplay .menu-list .Go,
.suninplay .path .path-back,
.suninplay .path .path-back.select {
    background-image: url(./sprite/left-s80d2893ef0.png);
    background-repeat: no-repeat;
}

.favor-set {
    line-height: 25px;
    border-bottom-width: 0;
    padding-right: 23px;
    background-position: 100% -2625px;
}

.profile-wrap .favor-set {
    position: absolute;
    right: 0;
    top: 1px;
}

.green {
    color: #508d0e !important;
}

.pop_bg {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 80;
}

.pop_box,
.pop_refer {
    position: fixed;
    top: 110px;
    left: 50%;
    background-color: #eee;
    width: 400px;
    border-radius: 5px;
    padding: 15px;
    transform: translate(-50%, 0);
}

.pop-warp,
.log-wrap,
.streaming-pop,
.account-wrap ul,
.selection-list,
.slip_set-pop,
.oneclick_set-pop,
.path-pop,
.message-wrap,
.dialog-wrap-w,
.pop_box,
.pop_refer,
.filter-pop,
.calendarPanel,
.account_pop,
.tips-popup {
    box-shadow: 0 4px 5px rgb(0 0 0 / 50%);
}

.close_pop {
    position: absolute;
    right: 15px;
    background-position: -41px -632px;
    height: 16px;
    width: 17px;
}

.pop_bg .close_pop {
    top: 9px;
    right: 10px;
}

.pop_box h3,
.pop_refer h3 {
    font-size: 16px;
    color: #3b5160;
    margin-bottom: 18px;
}

.pop_box h3,
.pop_refer h3 {
    font-size: 16px;
    color: #3b5160;
    margin-bottom: 18px;
}

.half_box {
    margin-top: 30px;
}

.add-member-box>li:first-of-type {
    width: 360px;
}

.half_box li {
    width: 50%;
}

.add_account_box dl.border_b {
    border-bottom: 1px solid #BFBFBF;
}

.pop_box .form_list,
.pop_refer .form_list,
.pop_box .add_account_box dl,
.add_account_box .pop_box dl,
.pop_refer .add_account_box dl,
.add_account_box .pop_refer dl,
.pop_box .pt_allowed dl,
.pt_allowed .pop_box dl,
.pop_refer .pt_allowed dl,
.pt_allowed .pop_refer dl {
    padding: 0 10px;
}

.add_account_box dl {
    width: 335px;
    padding: 0 0 0 30px;
    margin-bottom: 15px;
}

.form_list,
.add_account_box dl,
.pt_allowed dl {
    padding: 0 20px;
    margin-bottom: 10px;
}

.form_list dt,
.add_account_box dl dt,
.pt_allowed dl dt {
    width: 122px;
    margin-right: 10px;
    line-height: 24px;
}

.form_list dd,
.add_account_box dl dd,
.pt_allowed dl dd {
    line-height: 24px;
}

.add_account_box dl dd {
    margin-bottom: 6px;
}

.form_list dd,
.add_account_box dl dd,
.pt_allowed dl dd {
    position: relative;
    margin-bottom: 10px;
    padding-left: 132px;
}

.form_list dd input,
.add_account_box dl dd input,
.pt_allowed dl dd input,
.form_list dd textarea,
.add_account_box dl dd textarea,
.pt_allowed dl dd textarea,
.form_list dd .member_select a,
.member_select .form_list dd a,
.add_account_box dl dd .member_select a,
.member_select .add_account_box dl dd a,
.pt_allowed dl dd .member_select a,
.member_select .pt_allowed dl dd a {
    width: 160px;
    margin: 0;
}

.must {
    font-size: 13px;
    color: #d0021b;
}

.pop_box h3,
.pop_refer h3 {
    font-size: 16px;
    color: #3b5160;
    margin-bottom: 18px;
}

button#logout {
    position: relative;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 8px 10px;
    width: 100%;
    /* padding-right: 12px; */
    font-weight: bold;
    color: #fff;
}

#resultTable tr {
    text-align: left;
}

@media screen and (max-height: 700px) {
    .news-wrap {
        min-height: 333px;
    }

    .announce-wrap,
    .casino-result {
        top: 24px;
        height: calc(100% - 24px - 24px);
        min-height: 298px;
    }

    .tc-content,
    .news-wrap .announce-content {
        height: calc(100% - 24px - 24px - 56px - 73px);
        min-height: 200px;
    }

    .news-wrap .announce-content {
        height: calc(100% - 24px - 24px - 60px - 73px - 24px);
        min-height: 200px;
    }
}

.message-wrap.error {
    background-color: #d0021b;
    color: #fff;
}

.message-wrap {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 8px 0;
    background-color: green;
    font-size: 14px;
    color: #1e1e1e;
    z-index: 99;
}

.message-wrap p {
    line-height: 22px;
    padding: 0 10px;
    text-align: center;
    color: #fff;
    margin-bottom: 0;
}

#message {
    display: none;
}

.message-wrap .btn-close {
    margin: 6px 10px 0 0;
}

.delete,
.btn-close,
.header h1,
.mysports247 .header h1,
.maxinplay .header h1,
.maxexch9 .header h1,
.mazaplay .header h1,
.kingfair24 .header h1,
.alphaexch .header h1,
.alphaexchlive .header h1,
.skyexchange .header h1,
.skyfair .header h1,
.mcwex .header h1,
.goexch365 .header h1,
.agexch247 .header h1,
.suninplay .header h1,
.oxyinplay .header h1,
.iceEX .header h1,
.o_spondemo .header h1,
.o_12bet .header h1,
.dafabet .header h1,
.skyinplay .header h1,
.probetx .header h1,
.etoroexch .header h1,
.probet247 .header h1,
.bigexch .header h1,
.sixball .header h1,
.sixballio .header h1,
.sgexch247 .header h1,
.sharet365 .header h1,
.baji .header h1,
.baji365 .header h1,
.baazi365 .header h1,
.lionexch .header h1,
.masterexchange .header h1,
.matador .header h1,
.fairenter .header h1,
.lucky7 .header h1,
.dhoom .header h1,
.qexch .header h1,
.iceinplay .header h1,
.betmygame .header h1,
.regalexch365 .header h1,
.khelexch .header h1,
.fancyfair .header h1,
.maruti .header h1,
.marutibook .header h1,
.skyexchange247 .header h1,
.skyexchange247com .header h1,
.oceanexch1 .header h1,
.oceanbook1 .header h1,
.wicketspro .header h1,
.yabo101sport .header h1,
.aecricket888 .header h1,
.cftbet365 .header h1,
.crickzoom .header h1,
.crickzoomlive .header h1,
.betbarter .header h1,
.wickets247 .header h1,
.crickex .header h1,
.hmsbet .header h1,
.cricfair .header h1,
.jeetbuzz .header h1,
.enterfair .header h1,
.language,
.menu-list .Go,
.path .path-back,
.favor,
.tag-live strong,
.transfer-wrap .close,
.transfer-wrap-slider .close,
.transferId-con dd .copy-but,
.transferId-con .mainBalance dd .refresh-but,
.login_to_go .close,
.close_pop,
.table01 .action a,
.table-s .action a,
.table_one .action a,
.btn_open,
.btn_close,
.odd-add a,
.open-odds,
.close-odds,
.credit-amount-member .btn,
.credit-amount-member .btn-send,
.credit-amount-member .pages a,
.pages .credit-amount-member a,
.credit-amount-member .calendarTable_inputBox input,
.calendarTable_inputBox .credit-amount-member input,
.credit-amount-member .calendarTable_inputBox textarea,
.calendarTable_inputBox .credit-amount-member textarea,
.credit-amount-member .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .credit-amount-member a,
.credit-amount-member .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .credit-amount-member a,
.credit-amount-member .total_all .btn_replay,
.total_all .credit-amount-member .btn_replay,
.credit-amount-member .btn_expand,
.credit-amount-member .add_member {
    font-size: 0;
    text-indent: -99999px;
}

.btn-close {
    background-position: -404px -1445px;
    height: 10px;
    width: 10px;
}

.custom-sky-passwordChange {
    width: 400px;
    margin: auto;
    background: #eee;
    padding: 15px;
    position: fixed;
    top: 110px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 10px;
}

.inputtext-pass input {
    padding: 10px 5px;
    box-shadow: inset 0px 1px 0px rgb(0 0 0 / 50%);
    border-radius: 4px;
    box-sizing: border-box;
    border: 0px #aaa solid;
    font-size: 12px;
    background: #fff;
}

#transition-modal-title {
    font-size: 16px;
    color: #3b5160;
    margin-bottom: 18px;
    font-family: Tahoma, Helvetica, sans-serif;
    margin-top: 0;
}

.form-lable-changepass {
    font-size: 12px;
    text-align: right;
}

.custom-sky-passwordChange .chaPassSubmit {
    background-image: url(../assets/bg-send_hover-skyEX.png);
    color: #f8d61c;
    border-color: #000;
    font-weight: bold;
    line-height: 23px;
    font-size: 12px;
    border: 1px solid #bbb;
    border-radius: 4px;
    padding: 0;
    text-align: center;
}

.chaPassSubmit span {
    color: #f8d61c;
}

.chaPassSubmitCont {
    text-align: center;
}

#validateConfirmPass {
    color: red;
    font-size: 10px;
}

.star-must {
    padding-left: 0 !important;
}

.star-must .must {
    color: red;
    position: relative;
    right: 10px;
}

.form-input-changepas {
    padding-right: 0 !important;
}

.close_pop {
    position: absolute;
    right: 15px;
    background-position: -41px -632px;
    height: 16px;
    width: 17px;
    font-size: 0;
    text-indent: -99999px;
    background-image: url(../assets/sprite/agent-sa842cb1158.png);
    background-repeat: no-repeat;
    top: 15px;
}

.profitlossmarket .table_head th {
    color: #243A48;
    background-color: #E4E4E4;
    border: 1px solid #7E97A7;
    border-width: 1px 0 1px 0;
    padding: 4px 10px;
    font-weight: bold;
    font-size: 12px;
}

.profitlossmarket .table_body th,
.profitlossmarket .table_body td {
    color: #243A48;
    background-color: #fff;
    border: 1px solid #7E97A7;
    border-width: 1px 0 1px 0;
    padding: 10px 10px 5px;
    font-size: 12px;
}

tr.second-spec-row {
    background: #E2E8ED;
}

tr.second-spec-row td {
    background: transparent !important;
}

tr.second-spec-row>td {
    padding: 0 !important;
}

.status-active {
    background-color: #E5F1DC;
    border: 1px solid #BEDCA7;
    color: #508D0E;
    font-size: 11px;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 3px;
}

.status-active img {
    background-position: -50px -1303px;
    height: 8px;
    width: 8px;
    margin-right: 5px;
}

.status-inactive {
    background-color: #f3060667;
    border: 1px solid #f586529b;
    color: #8d0e0e;
    font-size: 11px;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 3px;
}

.status-inactive img {
    background-position: -50px -1303px;
    height: 8px;
    width: 8px;
    margin-right: 5px;
    border-radius: 10px;
    background-color: #8d0e0e;
}

.action_info {
    position: sticky !important;
    bottom: 0 !important;
    width: 20vh !important;
    z-index: 51 !important;
    display: flex !important;
}

.action_info .main_wrap {
    border-top: unset !important;
    background-color: unset !important;
}

/* .inttype[data-value] {
    color: green!important;
} */
.inttype[data-value^="-"] {
    color: red !important;
}

.inttyped[data-value] {
    color: green !important;
}

.inttyped[data-value^="-"] {
    color: red !important;
}

/* Tabbing Style CSS */
.owntabfilter button.MuiTab-textColorInherit.Mui-selected {
    background-color: #3B5160;
    color: #fff;
}

.owntabfilter button.MuiTab-root {
    min-width: auto !important;
    min-height: auto;
    background: #fff;
    opacity: 1;
    color: #000;
    line-height: 1.2;
    font-size: 13px;
    border-right: 1px solid #3B5160;
    text-transform: none;
    font-weight: bold;
}

.pandlcont header {
    background-color: #fff;
}

.pandlcont .MuiTabs-fixed,
.pandlcont .MuiTabs-root {
    min-height: auto;
}

.pandlcont div>.MuiBox-root {
    padding: 0;
}

.pandlcont .MuiTabs-indicator {
    display: none;
}

.bets-filter {
    font-weight: bold;
    /* padding: 10px 10px 0; */
    background-color: #E0E6E6;
    border-bottom: 1px solid #7E97A7;
    border-top: 3px solid #3B5160;
    margin-bottom: 5px;
    display: inline-block;
    max-width: 1100px !important;
    width: 100%;
    position: unset;
    padding: 0;
    padding-top: 10px;
}

.input-list {
    margin-bottom: 10px;
    display: flex;
}

.input-list>span {
    line-height: 25px;
    margin: 0 10px 0px 8px;
    white-space: nowrap;
    font-size: 12px;
    font-weight: normal;
}

.bet-status {
    width: 120px;
    height: 25px;
    line-height: 25px;
    cursor: pointer;
}

.bets-box {
    margin-top: 20px;
    background-color: #3B5160;
    border-bottom: 1px solid #7E97A7;
    color: #fff;
    line-height: 24px;
    font-weight: bold;
    padding: 0 10px;
}

.bets-table .table_head th {
    color: #243A48;
    background-color: #E4E4E4;
    border: 1px solid #7E97A7;
    border-width: 1px 0 1px 0;
    padding: 4px 10px;
    color: #243A48;
    font-weight: bold;
    font-size: 12px;
}

.get-fancy-history,
.cpmodal-button {
    background: #121212 url(../assets/getpandlbutton.png) repeat-x;
    color: #f8d61c;
    border-color: #000;
    width: 95px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-top: 5px;
}

.downline-profit-tr td#remark {
    color: #d0021b !important;
}

.bets-table .table_body th,
.bets-table .table_body td {
    color: #243A48;
    background-color: #fff;
    border: 1px solid #7E97A7;
    border-width: 1px 0 1px 0;
    padding: 10px 10px 5px;
    font-size: 12px;
}

a.expand-close {
    background: url(../assets/arrows-list.png);
    background-repeat: no-repeat;
    background-position: -16px -136px;
    padding-left: 20px;
}

.pandlbets a.expand-close {
    background-position: 100% -136px !important;
    background: url(../assets/arrows-list.png);
    background-repeat: no-repeat;
    padding-right: 20px;
}

.pandlbets a.expand-open {
    background-position: 100% -151px !important;
    background: url(../assets/arrows-list.png);
    background-repeat: no-repeat;
    padding-right: 20px;
}

.tab-banking tr td input {
    border: 1px solid #aaa;
    padding: 4px 5px;
    box-shadow: inset 0px 2px 0px rgb(0 0 0 / 10%);
    margin: 0;
    height: 30px;
    width: 50%;
    margin-left: 5px;
}

.depositbutt,
.withdrawbutt {
    width: 30px;
    font-size: 14px;
    font-weight: bold;
    color: #3b5160;
    display: inline-block;
    background-image: url(../assets/bg-btn.gif) repeat-x;
    height: 29px;
    line-height: 27px;
    border: 1px solid #bbb;
}

.fullwithdrawbutt {
    background-image: url(../assets/bg-btn.gif) repeat-x;
    width: 45px;
    height: 29px;
    line-height: 27px;
    margin-left: 5px;
}

.depositbutt {
    border-radius: 4px 0 0 4px;
    border-right-width: 0;
}

.withdrawbutt {
    border-radius: 0 4px 4px 0;
}

.dashboard.betlist .function-wrap input {
    background-image: none;
}

.footer_info ul.action li {
    cursor: pointer;
}

#createModal .pop_box .status_id {
    width: 96%;
    margin: auto;
    background-color: #fff;
    padding: 10px 7px;
    position: unset;
    display: inline-block;
    border-bottom: 1px solid #eee;
}

.dashboard #createModal .pop_box .status_id p {
    margin-bottom: 0;
    line-height: 15px;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 0;
    padding-right: 0;
}

#createModal .pop_box .status_id .lv_4 {
    text-align: center;
    background-color: #568BC8;
    font-size: 10px;
    line-height: 15px;
    font-weight: 400;
}

#createModal .pop_box .status_id .status-active {
    background-color: unset;
    border: none;
}

#createModal .pop_box .status_id .status-active img {
    vertical-align: middle;
}

#createModal .white-wrap {
    background-color: #fff;
    color: #3b5160;
    padding: 7px 10px 5px;
    border-bottom: 1px solid #7e97a7;
    margin-bottom: 15px;
    display: inline-block;
    width: 94.5%;
}

#createModal .status_but {
    width: 351px;
    margin: 8px auto;
    float: unset;
}

.status_but .but_active.disable,
.status_but .disable.but_suspend,
.status_but .disable.but_locked {
    background: #fff;
    color: #b0b0b0;
    border: #ddd 1px solid;
    cursor: not-allowed;
}

.status_but .but_active,
.status_but .but_suspend,
.status_but .but_locked {
    width: 105px;
    height: 50px;
    font-size: 15px;
    line-height: 15px;
    background-image: linear-gradient(-180deg, #FEFEFE 0%, #E0E0E0 100%);
    border: 1px solid #9D9D9D;
    border-radius: 8px;
    margin: 0 5px;
    padding: 8px 0;
    color: #508D0E;
}

.status_but .but_active.disable img,
.status_but .disable.but_suspend img,
.status_but .disable.but_locked img {
    height: 33px;
    width: 33px;
    background-image: url(../assets/sprite/agent-sa842cb1158.png);
}

.status_but .but_active.disable img {
    background-position: 12px -776px;
}

.status_but .disable.but_suspend img {
    background-position: 12px -1030px;
}

.status_but .disable.but_locked img {
    background-position: 12px -875px;
}

.status_but .but_active img,
.status_but .but_suspend img,
.status_but .but_locked img {
    margin: 0 auto;
}

ul#statusBtn li a span {
    width: 100% !important;
    display: inline-block;
    text-align: center;
}

ul#statusBtn li a span img {
    width: 100%;
}

.status_but .but_suspend img {
    background-position: 11px -997px;
    height: 33px;
}

#suspendBtn span {
    color: #E83523;
}

#suspendBtn.open span {
    color: #fff;
}

#suspendBtn.disable span {
    color: #b0b0b0;
}

.status_but .but_locked img {
    background-position: 12px -842px;
    height: 33px;
}

.status_but .but_locked {
    color: #708B9D;
}

.clear {
    clear: both;
}

.inline-form .form_list {
    padding: 0;
    margin-bottom: 0;
    float: left;
}

.inline-form .form_list dt {
    width: 60px;
    margin-right: 10px;
    line-height: 24px;
    text-align: right;
}

.inline-form .form_list dd input {
    width: 140px;
}

.inline-form .form_list dd {
    width: 168px;
    padding-left: 60px;
    margin-bottom: 0;
    line-height: 24px;
    position: relative;
    zoom: 1;
}

.form_list dd:after {
    content: "";
    display: block;
    clear: both;
}

.btn_box .btn-send {
    background: #121212 url(./skyexchange/bg-send-skyEX.png) repeat-x;
    color: #F8D61C;
    border-color: #000;
    width: 150px;
    margin: 0 auto;
}

.status_but .but_active:hover,
.status_but .but_suspend:hover,
.status_but .but_locked:hover {
    text-decoration: none;
    background-image: linear-gradient(-180deg, #E9E9E9 23%, #FEFEFE 100%);
}

.status_but .but_active.disable:hover,
.status_but .disable.but_suspend:hover,
.status_but .disable.but_locked:hover {
    background: none;
}

.status_but .but_active img {
    background-position: 12px -743px;
    height: 33px;
}

.status_but .but_suspend.open {
    background-image: linear-gradient(-180deg, #DB2828 0%, #921313 100%);
    color: #fff;
}

.status_but .but_suspend.open img {
    background-position: 12px -1063px;
}

.status_but .but_locked.open {
    background-image: linear-gradient(-180deg, #9AB6CE 0%, #536174 100%);
    color: #fff;
}

.status_but .but_locked.open img {
    background-position: 12px -908px;
}

.acc-info .user {
    background-position: -43px 0;
    padding-left: 17px;
}

/* BetHistory Page CSS */
.tab button.MuiTab-root {
    min-width: auto !important;
    min-height: auto;
    background: #fff;
    opacity: 1;
    color: #000;
    line-height: 1.2;
    font-size: 13px;
    border-right: 1px solid #3B5160;
    text-transform: none;
    font-weight: bold;
    margin-right: 10px;
    border-radius: 3px 3px 0 0;
    border: 1px solid #3B5160;
    border-bottom: 0;
    padding: 5px 24px 5px;
}

.tab button.MuiTab-textColorInherit.Mui-selected {
    background-color: #3B5160;
    color: #fff;
}

.tab .MuiTabs-indicator {
    display: none;
}

.current-bets-cont header>div:first-child {
    display: contents;
}

.my-bets header {
    min-width: 375px !important;
    width: 48% !important;
}

.current-bets-cont header {
    width: 100% !important;
}

.tab>div {
    min-height: auto;
    border: 1px solid #3B5160;
    border-radius: 6px;
}

.bet-history-pd>div,
.bethistory-filters-fancy>div,
.bethistory-filters-matchodds>div,
.bethistory-filters-bookmaker>div,
.fancy-bet-pandl>div {
    padding: 0;
    margin-top: 15px;
}

.current-bets-cont>div:last-child {
    max-width: 82%;
    flex-basis: 82%;
}

.Bets.History.bet-history-pd {
    padding: 0px 24px;
}

.bethistory-user header.tab.MuiAppBar-colorPrimary {
    background: transparent !important;
    box-shadow: none;
}

header.tab .MuiTabs-flexContainer {
    display: block;
}

.bet-history-pd .MuiBox-root {
    padding: 0;
}

.bet-history-combine .bets-filter,
.profit-combine .bets-filter {
    padding: 10px 10px 0;
}

.message-wrap-new {
    /* position: absolute; */
    /* top: 0; */
    width: 100%;
    padding: 8px 0;
    background-color: green;
    font-size: 14px;
    color: #fff;
    z-index: 99;
    text-align: center;
    box-shadow: 0 4px 5px rgb(0 0 0 / 50%);
}

#createModal .pop_box .status_id .status-inactive {
    background-color: unset;
    border: none;
    float: right;
}

.user_modal {
    background: white;
    padding: 10px;
    width: 40%;
}

button.btn-send {
    background: #121212 url(./skyexchange/bg-send-skyEX.png) repeat-x;
    color: #F8D61C;
    border-color: #000;
    width: 150px;
    margin: 0 auto;
}

button.close-btn {
    background: #ad1b1b;
    color: #F8D61C;
    border-color: rgb(201, 27, 27);
    width: 77px;
    margin: 0 auto;
    line-height: 23px;
    font-size: 12px;
    border: 1px solid #bbb;
    border-radius: 4px;
}

button:disabled,
button[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    opacity: .5;
}

.MuiGrid-item {
    /* margin: 10px 0px 10px 0px !important; */
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    margin: -9px 0px 0px -12px;
}

.red {
    color: red !important;
}

.cposition_model {
    background: white;
    padding: 10px;
    margin: 4% 30% 0% 30%;
}

.bookbtn {

    background: black;
    color: white;
    padding: 5px;
    margin-left: 23px;
    border-radius: 6px;

}

.current-position[data-value] {
    color: green !important;
}

.current-position[data-value^="-"] {
    color: red !important;
}

.wrap-refer_edit {
    border: 1px solid #ccc;
    border-width: 1px 0 1px 0;
    padding: 15px 40px 0;
}

.wrap-refer_edit dd {
    padding-left: 60px;
}

.wrap-refer_edit dl {
    margin-bottom: 10px;
    line-height: 28px;
}

.wrap-refer_edit dd .btn,
.wrap-refer_edit dd .btn-send,
.wrap-refer_edit dd .nova88exch .total_all .search-but,
.nova88exch .total_all .wrap-refer_edit dd .search-but,
.wrap-refer_edit dd .pages a,
.pages .wrap-refer_edit dd a,
.wrap-refer_edit dd .calendarTable_inputBox input,
.calendarTable_inputBox .wrap-refer_edit dd input,
.wrap-refer_edit dd .calendarTable_inputBox textarea,
.calendarTable_inputBox .wrap-refer_edit dd textarea,
.wrap-refer_edit dd .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .wrap-refer_edit dd a,
.wrap-refer_edit dd .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .wrap-refer_edit dd a,
.wrap-refer_edit dd .total_all .btn_replay,
.total_all .wrap-refer_edit dd .btn_replay,
.wrap-refer_edit dd .btn_expand,
.wrap-refer_edit dd .add_member {
    width: 58px;
    height: 26px;
    line-height: 26px;
    margin: 0;
}

.pop_refer .btn-wrap .btn,
.pop_refer .btn-wrap .btn-send,
.pop_refer .btn-wrap .nova88exch .total_all .search-but,
.nova88exch .total_all .pop_refer .btn-wrap .search-but,
.pop_refer .btn-wrap .pages a,
.pages .pop_refer .btn-wrap a,
.pop_refer .btn-wrap .calendarTable_inputBox input,
.calendarTable_inputBox .pop_refer .btn-wrap input,
.pop_refer .btn-wrap .calendarTable_inputBox textarea,
.calendarTable_inputBox .pop_refer .btn-wrap textarea,
.pop_refer .btn-wrap .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .pop_refer .btn-wrap a,
.pop_refer .btn-wrap .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .pop_refer .btn-wrap a,
.pop_refer .btn-wrap .total_all .btn_replay,
.total_all .pop_refer .btn-wrap .btn_replay,
.pop_refer .btn-wrap .btn_expand,
.pop_refer .btn-wrap .add_member {
    width: 140px;
    margin: 0 auto;
}

.wrap-refer_edit strong {
    font-size: 16px;
}

.pop_refer .btn-wrap {
    padding: 15px;
}

.btn-wrap {
    list-style: none;
}

.pop-warp .game-team .game-name,
.log-wrap .game-team .game-name,
.streaming-pop .game-team .game-name,
.log-wrap .game-team .game-name {
    padding-left: 10px;
}

.game-team .game-name {
    padding: 5px 10px 5px 30px;
}

.pop-warp .game-name,
.log-wrap .game-name,
.streaming-pop .game-name {
    position: relative;
    padding-right: 130px;
}

.game-team td {
    position: relative;
    width: 40%;
    height: 28px;
    vertical-align: top;
}

.game-team .game-name span {
    font-size: 15px;
}

.game-team .game-name span {
    background-color: #fff;
    color: #243a48;
    padding: 1px 8px;
    border-radius: 4px;
    margin: 0 5px;
}

.go-back,
.pop-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 85px;
    height: 100%;
    line-height: 38px;
    color: #fff;
    font-weight: normal;
    border-left: 1px solid #5F6C74;
}

.pop-close img {
    background-position: -46px -1242px;
    height: 12px;
    width: 12px !important;
    vertical-align: unset !important;
    margin-right: 8px;
}

.game-team {
    width: 100%;
    background-color: #1b2d38;
    color: #fff;
    line-height: 22px;
    font-size: 18px;
    font-weight: bold;
}

.tab-transfer .full-amount {
    border-left: 1px solid #7E97A7;
}

#editCreditReferenceBtn {
    margin: 0;
}

.login-banner {
    width: 109px;
    z-index: 4;
    top: 39%;
    left: 65px;
    position: absolute;
}

.win[data-value] {
    color: green !important;
}

.win[data-value^="-"] {
    color: red !important;
}

.main_wrap .action {
    min-width: 145px;
}

.dashboard .menu .select a {
    color: #222;
}

.dashboard .menu .select span {
    color: #222;
}

.dashboard .menu .select ul li a {
    color: #fff;
}

.dashboard .menu-wrap .select a.menu-drop:after {
    border-top-color: #222;
}

.dashboard .menu-wrap .select span.menu-drop:after {
    border-top-color: #222;
}

#canv {
    width: 137px;
    position: absolute;
    right: -13px;
    top: 1px;
    height: 31px;
}

.acc-info1 .user1 {
    background-position: -8px -205px;
    padding-left: 25px;
    margin-bottom: 10px;
}

#noReportMessage {
    font-size: 12px;
}

.back {
    color: #1f72ac !important
}

.lay {
    color: #e33a5e !important
}

.expand-close,
.expand-open {
    color: unset;
}

.credit-amount {
    width: 221px;
}

.cpmodal-row {
    display: grid;
    /* place-content: center; */
    padding: 38px;
    width: 90vw;
}
.m-width1300{
min-width: 1300px !important;
}
.expandopclose{
    width:unset !important;
    padding:unset !important;
}
.footer_info_new .main_wrap .action {
   
    min-width: 557px;

}