/* @import url('https://fonts.googleapis.com/css2?family=Helvetica&display=swap'); */

.login-body{
    /* background-image: linear-gradient(#fab700,rgba(255,255,255,0.6)), url('./assets/login-bg.jpg'); */
    /* background-image: linear-gradient(#1d1638,rgb(255 255 255 / 16%)), url(./assets/login-bg.jpg); */
    background-color: #1d1638;
    background-position: inherit;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    /* font-family: 'Helvetica', sans-serif; */
    font-size: 13px;
}
.bgBlack {
    background: #000;
    width: 100%;
    height: 100vh;
    padding-bottom: 13%;
}
a{
    text-decoration: none;
    color: unset;
}

img{
    width: auto;
    max-width: 100%;
}

.login-margin{
    margin-bottom: 20px !important;
}

#wrapper {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 310px;
    overflow: hidden;
    padding: 20px;
    border-radius: 0;
    border: none;
    background: #ffffff;
}

.login-form {
    overflow: hidden;
    display: inline-block;
    border: #C0C0C0 solid 0px;
    border-radius: 10px;
}

.login-form form{
    margin: 20px 0;
}

.login-form input{
    padding: 5px 0;
    min-height: 30px;
    font-size: 14px;
    border-bottom: 1px solid #333;
    color: #333;
}

.login-form input:focus,
.login-form input:hover,
.login-form input:active{
    border-bottom: 1px solid #333 !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before{
    border: 0 !important;
}

.login-form input::placeholder{
    font-size: 14px;
    color: #000;
}

.login-form .remember-me .MuiTypography-body1{
    font-size: 14px;
}

.login-form .remember-me .MuiSvgIcon-root{
    font-size: 16px;
}

.login-form .remember-me .PrivateSwitchBase-root-1{
    padding-right: 8px;
}

.login-btn{
    background-image: linear-gradient(to right, #f18906, #f59800, #f8a700, #fab700, #fbc600, #fbc600, #fbc600, #fbc600, #fab700, #f8a700, #f59800, #f18906);
    color: #000 !important;
    font-size: 15px;
    border-radius: 0 !important;
    width: 100%;
}
/*--- Login CSS--- */
div.skyexchange {
    height: 100vh;
    font-family: Tahoma, Helvetica, sans-serif;
    color: #1E1E1E;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    padding-bottom: 10%;
}
div.skyexchange {
    background: #080a05 url(./assets/skyexchange/bg-login-skyEX.jpg) no-repeat center;
    background-size: cover;
}
.skyexchange .login-wrap, .skyexchange .maintain-wrap {
    top: 12%;
    background-color: #FFB80C;
}
.login-wrap, .maintain-wrap {
    position: absolute;
    top: 15%;
    left: calc(50% - 540px/2);
    width: 540px;
    height: 408px;
    background-color: rgba(255,255,255,0.8);
    border-radius: 8px;
    box-shadow: 0 5px 20px rgb(0 0 0 / 50%);
}
.skyexchange .kv {
    background: #000 url(./assets/images/bg-color-login.png) no-repeat;
}

.kv {
    width: 250px;
    height: 100%;
    background: #FFB80C url(./assets/bg-login_wrap.png) no-repeat;
    border-radius: 8px 0 0 8px;
}
.float-L, .delete, .marquee-pop .input-list li, .login-panel, .change_pass .login-panel, .change_pass .note, .kv {
    float: left;
}
.login-panel {
    color: #243A48;
    margin: 100px 0 0 25px;
}
.login-panel dt {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px;
}
.skyexchange .login-wrap, .skyexchange .maintain-wrap {
    top: 12%;
    background-color: linear-gradient(180deg, #ffb600 1%, #ffb600 100%);
}
.clearfix, .over-wrap, .pop-warp, .log-wrap, .col-center, .marquee-pop .input-list, .change_pass, .login-wrap, .maintain-wrap {
    zoom: 1;
}
.skyexchange .login-panel {
    color: #000;
}
.login-panel dd {
    position: relative;
    width: 220px;
    margin-bottom: 7px;
    margin-left: 0;
    display: flex;
}
.login-panel input, .login-panel textarea {
    width: 100%;
    height: 33px;
    font-size: 14px;
    line-height: 21px;
    border: 1px solid #aaa;
    box-shadow: inset 0px 2px 0px 0px rgb(0 0 0 / 10%);
    margin: 0;
}
input, textarea {
    font-family: Tahoma, Helvetica, sans-serif;
    color: #1E1E1E;
    font-size: 12px;
    border: 0px #aaa solid;
    background: #fff;
    box-shadow: inset 0px 1px 0px rgb(0 0 0 / 50%);
    border-radius: 4px;
    padding: 5px;
    margin: 0 5px 5px 0;
    box-sizing: border-box;
}
.login-panel .valid-code img {
    position: absolute;
    width: 70px;
    height: 19px;
    right: 5px;
    top: 7px;
}
.valid-code {
    margin-bottom: 0 !important;
}
.skyexchange .btn-send {
    background: #121212 url(./assets/skyexchange/bg-send-skyEX.png) repeat-x;
    color: #F8D61C;
    border-color: #000;
}

.login-panel .btn-send {
    width: 218px;
    height: 36px;
    line-height: 36px;
    font-size: 15px;
    margin: 15px 0 0;
    cursor: pointer;
}
.btn-send {
    background: #FFA00C url(./assets/bg-send.gif) repeat-x;
    border-color: #CB8009;
}
.btn, .btn-send, .marquee-pop .pages a {
    background: #eee url(./assets/bg-btn.gif) repeat-x;
    border: 1px solid #bbb;
    border-radius: 4px;
    color: #1E1E1E;
    font-weight: bold;
    line-height: 23px;
    font-size: 12px;
    margin: 0 5px 10px 0;
}
.displayB, .delete, .btn, .btn-send, .marquee-pop .pages a, .marquee-pop .input-list, .marquee-pop .input-list li, .age_box li .secondary:before, .age_box li .btn-send:before, .power-wrap h3 img, .power-wrap-w img, .power-wrap-b img, .announce-footer .confirm-checkbox, body.bigexch:before, body.sgexch247:before, body.baji:before, body.baji365:before, body.sharet365:before, body.baazi365:before {
    display: block;
}
.align-C, .loading li, .btn, .btn-send, .marquee-pop .pages a, .maintain-content, .change_pass, .browser-wrap, .policy-link, .support-wrap, .announce-footer, .urgent-notice .announce-header h1, .announce-content table, .article-date, .news-wrap .announce-header, .apk-img, .marquee-pop .nofound .nofound_box dd {
    text-align: center;
}
a {
    text-decoration: none;
    color: #2789CE;
    outline: none;
    /* hlbr: expression(this.onFocus=this.blur()); */
}
.login-panel .error {
    color: #D0021B;
    font-size: 13px;
    line-height: 16px;
}
.login-panel .success {
    color: green;
    font-size: 13px;
    line-height: 16px;
}
.clearfix:after, .over-wrap:after, .pop-warp:after, .log-wrap:after, .col-center:after, .marquee-pop .input-list:after, .change_pass:after, .login-wrap:after, .maintain-wrap:after {
    content: "";
    display: block;
    clear: both;
}
.skyexchange .moreinfo-wrap {
    top: calc(12% + 428px);
}
.moreinfo-wrap {
    position: absolute;
    top: calc(15% + 428px);
    left: 50%;
    width: 540px;
    margin-left: -270px;
    padding-bottom: 35px;
}
.support-wrap {
    background-color: rgba(0,0,0,0.6);
    margin-bottom: 20px;
}
.support-wrap .support-service {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-top: 1px solid rgba(255,255,255,0.3);
}
.support-wrap .support-service::before, .support-wrap .support-service::after {
    content: "";
    display: block;
    flex: 1;
    align-self: flex-end;
    height: 1px;
    background-color: rgba(255,255,255,0.3);
    margin-bottom: -1px;
}
.support-wrap .support-service a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
}
.support-wrap .support-service img {
    width: 22px;
    height: 22px;
}
.support-mail img {
    background-image: url(./assets/social/tele.png);
}
.support-mail:hover img {
    background-image: url(./assets/social/tele.png);
}
.support-whatsapp img {
    background-image: url(./assets/social/whatsapp-nor.png);
}
.support-whatsapp:hover img {
    background-image: url(./assets/social/whatsapp.png);
}

.support-telegram img {
    background-image: url(./assets/social/telegram-nor.png);
}
.support-telegram:hover img {
    background-image: url(./assets/social/telegram.png);
}

.support-skype img {
    background-image: url(./assets/social/skype-nor.png);
}

.support-skype:hover img {
    background-image: url(./assets/social/skype.png);
}

.support-ig img {
    background-image: url(./assets/social/instagram.png);
}
.support-ig:hover img {
    background-image: url(./assets/social/instagram-hover.png);
}

.support-fb img, .support-ig img, .support-phone img {
    width: 22px !important;
}

a img {
    border: 0px solid #fff;
}
.support-wrap .support-service a::after {
    content: '';
    position: absolute;
    top: 100%;
    display: block;
    width: 50px;
    height: 1px;
    background-color: rgba(255,255,255,0.3);
}
.support-wrap .support-info {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 0 10px;
}
.support-wrap .support-detail {
    display: none;
}
.support-wrap .support-detail a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    color: #fff;
    text-decoration: underline;
}
.support-wrap .support-detail.open {
    display: block;
}
.power-wrap {
    background: rgba(255,255,255,0.25);
    border-radius: 8px;
    padding: 5px 10px;
    color: rgba(0,0,0,0.6);
    margin-bottom: 30px;
    align-items: stretch;
}
.power-wrap h3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid rgba(0,0,0,0.3);
    padding-right: 7px;
    margin-right: 7px;
    width: 21%;
    float: left;
    margin-top: 8px;
}
.power-wrap h3 span {
    font-size: 10px;
    font-weight: normal;
}
.power-wrap h3 span {
    font-size: 10px;
    font-weight: normal;
}
.power-wrap p {
    flex: 1;
    font-size: 11px;
    line-height: 14px;
    margin: 0;
    padding-left: 8px;
}
.power-wrap a {
    color: rgba(0,0,0,0.6);
    text-decoration: underline;
}
.skyexchange .browser-wrap {
    color: rgba(0,0,0,0.6);
}
.skyexchange .browser-wrap img {
    background-position: 0 0;
    height: 20px;
    width: 50px;
}
.browser-wrap {
    font-size: 11px;
    color: rgba(255,255,255,0.6);
}
.skyexchange .browser-wrap {
    color: rgba(0,0,0,0.6);
}

.skyexchange .browser-wrap img {
    background-position: 0 0;
    height: 20px;
    width: 50px;
}
.login-sprite, .power-wrap h3 img, .power-wrap-w img, .power-wrap-b img, .browser-wrap img, .time-space p, .mazaplay .time-space p, .mysports247 .time-space p, .skyexchange .browser-wrap img, .skyexchange .time-space p, .masterexchange .browser-wrap img, .matador .browser-wrap img, .fairenter .time-space p, .lucky7 .browser-wrap img, .qexch .browser-wrap img, .betmygame .browser-wrap img, .fancyfair .browser-wrap img, .maruti .time-space p, .marutibook .time-space p, .skyexchange247 .browser-wrap img, .skyexchange247 .time-space p, .oceanexch1 .browser-wrap img, .oceanbook1 .browser-wrap img, .alphaexch .time-space p, .alphaexchlive .time-space p, .maxexch9 .time-space p, .maxinplay .time-space p, .betbarter .browser-wrap img, .kingfair24 .time-space p, .kingfair24 .icon-login, .wickets247 .browser-wrap img, .wickets247 .time-space p, .crickex .time-space p, .iceEX .browser-wrap img, .iceEX .time-space p, .iceinplay .browser-wrap img, .dhoom .browser-wrap img, .agexch247 .browser-wrap img, .agexch247 .time-space p, .sportsexchlive .browser-wrap img, .sportsexchlive .time-space p, .goexch365 .browser-wrap img, .goexch365 .time-space p, .oxyinplay .browser-wrap img, .oxyinplay .time-space p, .cricfair .time-space p, .khelexch .browser-wrap img, .lionexch .browser-wrap img, .lionexch .time-space p, .skyfair .browser-wrap img, .skyfair .time-space p, .skyexchange247com .time-space p, .mcwex .browser-wrap img, .mcwex .time-space p, .enterfair .time-space p {
    background-image: url(./assets/sprite/login-sc411296a7a.png);
    background-repeat: no-repeat;
}
.browser-wrap img {
    margin-bottom: 5px;
}
#loginBtn img {
    padding-left: 3px;
}
.support-wrap .support-detail#supportDetail_email {
    display: block;
}
.support-wrap .support-service a.open::after {
    content: '';
    position: absolute;
    top: 100%;
    height: 10px;
    background-image: url(./assets/social/support_arrow.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-color: initial;
    pointer-events: none;
}
.power-wrap {
    background: rgba(255,255,255,0.25);
    border-radius: 8px;
    padding: 5px 10px;
    color: rgba(0,0,0,0.6);
    margin-bottom: 30px;
    align-items: stretch;
}
.power-wrap h3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid rgba(0,0,0,0.3);
    padding-right: 7px;
    margin-right: 7px;
}
.power-wrap h3 span {
    font-size: 10px;
    font-weight: normal;
}
.power-wrap h3 img {
    background-position: 0 -1172px;
    height: 18px;
    width: 107px;
}
.licence_embed {
    display: inline-block;
    width: 28%;
    float: left;
}
.moreinfo-wrap .power-wrap {
    padding-bottom: 14px;
}
.moreinfo-wrap .power-wrap p {
    width: 46.5%;
    display: inline-block;
}
.taoster-cont {
    width: 250px;
    margin: auto;
}
.error-val {
    color: #ff0000;
}