html {
    scroll-behavior: smooth;
}

body {
    background: #F0ECE1;
}

.theme-yellow {
    color: #FFB80C !important;
}

.bg-yellow {
    background-color: #FFB80C !important;
}

.w-100 {
    width: 100% !important;
}

.pt-0 {
    padding-top: 0 !important;
}

a {
    text-decoration: none;
}

.b-left {
    border-left: 1px solid #ccc;
}

.header img.logo {
    width: 132px;
}

.v-none {
    visibility: hidden;
}

img {
    width: auto;
    max-width: 100%;
}

.margin-left-auto {
    margin-left: auto;
}

.red-btn {
    background-image: linear-gradient(-180deg, #F72424 0%, #BB1C00 100%);
}

.dark-btn {
    background-image: linear-gradient(-180deg, #666 0%, #333 100%);
}

.align-center {
    align-items: center;
}

.md-none {
    display: none !important;
}

.lg-none {
    display: none !important;
}

.md-tbl-none {
    display: none !important;
}

.clear-fix {
    clear: both;
}

.xs-sport-tab {
    display: none;
}

/* -------------------------- HEADER ---------------------------- */

/* ----- Main Header ----- */

.header>header {
    background: #ffb80c url(./images/bg-top.gif) repeat-x;
    padding-top: 8px;
    padding-bottom: 8px;
    background-size: contain;
}

.header .searchInput {
    font-size: 18px;
}

.validation-input {
    position: relative;
}

.validation-input input {
    height: 95%;
}

.validation-input img {
    position: absolute;
    width: 55px;
    right: 0;
    top: 5px;
}

/* After Login */

.main-balance-btn button,
.account-btn button {
    box-shadow: inset 0 1px 0 0 rgb(255 255 255 / 50%);
    color: #000;
    font-size: 12px;
    text-transform: unset;
    padding: 0 8px;
}

.account-btn button {
    margin-left: 16px;
    font-size: 15px;
}

.main-balance-btn .refresh-btn {
    min-width: auto;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    min-height: 23px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.main-balance-btn .refresh-btn svg,
.account-btn button svg {
    font-size: 16px;
}

.main-balance-btn button.expo-btn {
    /* background: rgba(255, 255, 255, 0.15) !important;
     */
    background-image: linear-gradient(-180deg, #414141 0%, #000000 100%);
    background-color: rgba(255, 255, 255, 0.15);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-top: 3px;
    padding-bottom: 3px;
    color: #FFB80C !important;
}

.main-balance-btn button.expo-btn strong {
    margin: 0 8px;
    font-size: 13px;
}

.main-balance-btn button.expo-btn span.num {
    border: 1px solid #FFB80C;
    padding: 0 8px;
    line-height: 14px;
    border-radius: 4px;
}

#simple-menu .MuiPopover-paper {
    min-width: 310px !important;
    background: #E0E6E6;
    top: 50px !important;
}

.balance-list {
    padding: 0 8px !important;
    position: relative;
}

.balance-list span {
    font-size: 12px;
}

.balance-list>div {
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 30%);
    background: #fff;
    margin: 0;
    padding: 8px;
    /* border-radius: 4px; */
    border-bottom: 1px solid #ccc;
}

.balance-list p span.pth {
    background: #5F849D;
    padding: 2px 6px;
    width: fit-content;
    font-size: 11px;
    font-weight: bold;
    color: #fff;
    border-radius: 4px;
    vertical-align: middle;
}

.balance-list p span.points {
    font-size: 17px;
    margin-left: 4px;
    padding: 2px;
    vertical-align: middle;
    color: #3B5160;
    font-weight: 800;
}

button.recall-btn {
    position: absolute;
    right: 16px;
    border: 1px solid #7E97A7;
    color: #3B5160;
    line-height: 2;
    padding: 0 8px;
    font-weight: bold;
    background: #e7f5ff;
    text-transform: none;
}

button.recall-btn span {
    font-size: 15px !important;
}

.main-balance hr {
    margin: 4px 0 0;
}

.main-balance .main-footer {
    display: inline-block;
    justify-content: space-between;
    padding: 4px 0 0;
    width: 100%;
}

button.close-btn {
    width: calc(100% - 16px) !important;
    margin: 0 8px !important;
    text-transform: none;
    font-weight: bold;
    margin-bottom: 8px !important;
}

.MuiMenu-list {
    width: unset !important;
    padding: 0 !important;
}

#account-menu .MuiPopover-paper {
    min-width: 200px !important;
    top: 50px !important;
}

#account-menu li {
    padding: 0 12px;
    font-size: 12px;
    line-height: 25px;
    border-bottom: 1px solid #ccc;
}

#account-menu li a {
    color: #333;
    width: 100%;
}

button.logout-dark-btn {
    width: calc(100% - 20px);
    margin: 10px !important;
    background-color: #7E97A7 !important;
    color: #fff;
    font-weight: bold;
    border-radius: 4px;
    font-size: 12px;
    padding: 4px 10px
}

button.logout-dark-btn svg {
    font-size: 16px;
    margin-left: 4px;
}

.top-menu-account {
    justify-content: space-between !important;
    color: #3B5160 !important;
    font-weight: bold !important;
}

.top-menu-account .b-left {
    padding: 0 4px;
}

/* ----- NavBar ----- */

.navbar {
    margin-bottom: 4px;
}

.navbar>header {
    /* background-image: linear-gradient(180deg, #FFCC2F 8%, #FFB80C 100%); */
    background: #1e1e1e;

}

.navbar .MuiToolbar-dense {
    min-height: 30px;
}

.navMenu>span.MuiTypography-root {
    padding: 5px 10px;
    border-right: 1px solid rgba(69, 32, 7, 0.2);
    line-height: 22px;
    position: relative;
}

.navMenu span.MuiTypography-root a {
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: .4px;
    vertical-align: text-bottom;
}

.navMenu span.MuiTypography-root.active {
    box-shadow: inset 0 1px 3px 0 rgb(52 9 9 / 50%);
    background: #4d4d4d;
}

.live-number {
    position: absolute;
    top: -6px;
    right: 2px;
    min-width: 33px;
    height: 12px;
    border-radius: 2px;
    padding-right: 4px;
    color: #FFFFFF;
    text-align: center;
    font-size: 10px;
    line-height: 12px;
    background-image: linear-gradient(180deg, #FB3434 0%, #E80505 100%);
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 50%);
}

.live-number strong {
    flex: 1;
    height: 100%;
    margin-right: 3px;
    padding: 0 2px;
    border-radius: 3px 0px 0px 3px;
    background-image: linear-gradient(180deg, #fff 0%, #eee 89%);
}

.sport-xs-tab .MuiTabs-flexContainer {
    padding-top: 7px;
}

.sport-xs-tab button.Mui-selected,
.sport-xs-tab header button {
    overflow: visible;
}

.sport-xs-tab>header {
    padding-top: 2px;
}

.sport-xs-tab .live-number strong img {
    background-image: none !important;
    width: unset;
    height: unset;
}

.navbar .nav-right>span.MuiTypography-root {
    padding: 6px 10px;
    line-height: 22px;
    position: relative;
}

.navbar .nav-right>span a svg {
    font-size: 14px;
    vertical-align: middle;
}

.navbar .one-click-bet {
    background-image: linear-gradient(180deg, #444 13%, #1B1B1B 81%);
    border-top: 3px solid #6bbd11;
}

.navbar .one-click-bet a {
    color: #fff !important;
}

.navbar .one-click-bet svg {
    font-size: 20px !important;
    vertical-align: text-top !important;
    color: #666;
}

.navbar .time-zone {
    color: #666 !important;
    font-size: 13px;
    letter-spacing: .4px;
    vertical-align: text-bottom;
    margin-right: 12px !important;
}

.navbar .time-zone>strong {
    color: #fff !important;
}

.dark-menu {
    background: #000 url('./images/bg-top.jpg');
    padding: 4px 10px 4px 16px !important;
}

.dark-menu a::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 29px;
    width: 29px;
    background: url('./images/top-icon.png');
    background-repeat: no-repeat;
    background-position: 100% -8618px;
}

.dark-menu a::after {
    content: '';
    display: inline-block;
    position: relative;
    top: 4px;
    left: 2px;
    width: 18px;
    height: 18px;
    background: url('./images/top-icon.png');
    background-repeat: no-repeat;
    background-position: 100% -8600px;
}

.dark-menu a {
    color: #ffffff !important;
}

#setting-list .MuiMenu-paper {
    min-width: 270px;
    background: #E0E6E6;
    top: 110px !important;
}

.default-setting {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.default-setting span {
    font-size: 11px;
    font-weight: bold;
    color: #3B5160;
}

.default-setting>div {
    margin: 0;
}

.setting-first-list span {
    display: inline-block;
}

.setting-first-list p {
    display: inline-block;
}

.setting-first-list input {
    width: 70px;
    margin-left: 8px;
    font-size: 11px;
    border: none;
    box-shadow: inset 0px 1px 0px rgb(0 0 0 / 50%);
    height: 20px;
    border-radius: 4px;
}

.setting-stack a {
    color: #1E1E1E;
    width: calc(100% - 4px);
    border: 1px solid #bbb;
    background: #fff;
    display: inline-block;
    margin: 2px;
    text-align: center;
    border-radius: 4px;
    font-size: 11px;
    height: 18px;
    line-height: 18px;
}

.setting-stack a.select {
    background: #444;
    color: #F8D61C;
    border-color: #222;
}

a.edit-settings {
    height: calc(100% - 4px);
    line-height: 42px;
    background: transparent !important;
    margin-left: 4px;
    width: calc(100% - 8px);
}

.edit-settings svg {
    font-size: 14px;
    vertical-align: middle;
}

.odd-list label span {
    font-weight: normal;
}

.odd-list .MuiCheckbox-root {
    padding: 0 0 0 8px;
}

.odd-list .MuiCheckbox-root span {
    font-size: 12px;
}

.setting-button button {
    padding: 2px 6px;
}

.setting-button button.save-btn {
    background: #000 url('./images/bg-top.jpg');
}

.setting-button button.save-btn span {
    color: #F8D61C;
}

/* Login Modal */

.login-modal {
    width: 100%;
    max-width: 540px;
    background-color: #FFB80C;
    border-radius: 8px;
}

.login-modal .login-modal-img {
    height: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.login-modal .validation-input img {
    top: 44%;
    transform: translate(0, -50%);
    right: 2px;
}

.popup-login-btn img {
    background-image: url(./images/login-icons.png);
    background-position: 0 -533px;
    height: 12px;
    width: 12px;
}

.right-login {
    padding: 0 38px 0 12px;
    margin-top: 90px !important;
    width: -webkit-fill-available;
}

.right-login h3 {
    font-size: 20px;
    font-weight: 500;
}

.right-login>div {
    display: flex;
}

.right-login>div>input {
    width: 100%;
    padding: 5px 6px;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
    margin-left: 0;
    line-height: 19px;
    /* min-height: 28px; */
    box-shadow: inset 0px 2px 0px 0px rgb(0 0 0 / 10%);
    border: 1px solid #aaaaaa;
    font-size: 4vw;
    position: relative;
    background: #fff;
    border: 1px solid #aaa;
    box-shadow: inset 0 0.53333vw 0 0 rgb(0 0 0 / 10%);
    border-radius: 1.6vw;
    color: #1E1E1E;
    font-size: 4vw;
    font-family: Helvetica, Tahoma, sans-serif;
    padding: 2.66667vw 1.86667vw;
    margin-bottom: 3.2vw;
    -webkit-appearance: none;

}

.right-login button {
    position: relative;
    background:linear-gradient(180deg, #474747 0%, #070707 100%);
    height: 36px;
    border: 1px solid #000;
    border-radius: 4px;
    font-size: 15px;
    color: #ffb200;
    letter-spacing: .6px;
    font-weight: bold;

    position: relative;
    /* background: #000 url(../images/bg-top.jpg); */
    height: 40px;
    border: 1px solid #000;
    border-radius: 4px;
    font-size: 15px;
    letter-spacing: .6px;
    font-weight: bold;
    display: block;
    border: 1px solid #aaa;
    border-radius: 1.6vw;
    font-size: 4vw;
    font-weight: bold;
    text-transform: capitalize;
}

/* -------------------------- HEADER END ---------------------------- */

/* -------------------------- DASHBOARD ---------------------------- */

/* Slider */

.dashborad-slider img {
    width: 100%;
}

/* Slider */

.sport-block {
    position: relative;
    background: #ffb80c;
}

.sport-block .bottom-title {
    position: absolute;
    background-image: linear-gradient(270deg, rgba(69, 94, 104, 0) 4%, #000 97%);
    color: #fff;
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    bottom: -2px;
    border-bottom: 6px solid #FFB80C;
    width: 100%;
}

.sport-block .bottom-title .title {
    font-size: 18px;
    line-height: 30px;
    text-indent: 10px;
}

.sport-block .bottom-title .play-now {
    width: 18.13333vw;
    text-align: center;
    line-height: 7.46667vw;
    font-size: 2.93333vw;
    color: #000;
    background-color: #ffb80c;
    position: relative;
    height: 6.455vw;
}

.sport-block .bottom-title .play-now::before {
    content: "";
    width: 14.93333vw;
    background-image: url('./images/play-now.svg');
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    left: -15px;
    height: 6.4vw;
}

.live-count {
    position: absolute;
    width: 120px;
    padding: 8px;
    top: 0;
    right: 0;
    background-image: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.7) 82%, transparent 100%);
}

.live-count .live {
    height: 20px;
    line-height: 20px;
    color: #fff;
    padding-right: 5px;
    font-size: 12px;
    font-weight: bold;
    background-image: linear-gradient(180deg, #FB3434 0%, #E80505 100%);
    border-radius: 3px;
    margin-bottom: 14px;
    display: inline-flex;
}

.live-count .live span {
    height: 100%;
    padding: 0 3px;
    margin-right: 5px;
    background-image: linear-gradient(180deg, #fff 0%, #E8E8E8 100%);
    border-radius: 3px 0 0 3px;
}

.live-count .sport-count {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 6px;
    font-size: 14px;
}

.live-count .sport-count span:first-child {
    flex: 1;
    line-height: 16px;
    color: #fff;
}

.live-count .sport-count .show-number {
    color: #333333;
    background: #FFFFFF;
    text-align: center;
    border-radius: 3px;
    padding: 0 3px;
    min-width: 12px;
    height: 18px;
    font-size: 12px;
}

/* ----- Footer ----- */

.social {
    margin-top: 12px;
    background: #fff;
}

.social .MuiTabs-root {
    border-bottom: 1px solid #aaaaaa;
}

.social .MuiTabs-root button {
    min-width: unset;
    /* color: red; */
}

.social .MuiTab-textColorPrimary {
    color: #a6a6a6;
}

.social .MuiTab-textColorPrimary.Mui-selected {
    color: #616161;
}

.tab-description>div {
    padding: 12px;
    text-align: center;
}

.tab-description>div p {
    font-size: 14px;
    margin: 0;
    ;
}

.tab-description>div a {
    padding: 0 8px;
    color: #00000099;
    text-decoration: underline;
}

.tab-description>div a.borderLeft {
    border-left: 1px solid #999999;
}

.social .MuiTabs-root .MuiTabs-indicator {
    display: none;
}

/* ----- Powered By ----- */

.powered-block {
    border: 1px solid #999999;
    border-radius: 8px;
    padding: 6px;
    font-size: 12px;
    margin-top: 18px;
}

.powered-block p,
.footer-bottom p {
    margin: 0;
    font-size: 12px;
}

.footer-bottom {
    margin-top: 18px;
    text-align: center;
    font-size: 13px;
}

.footer-bottom a {
    color: #00000099;
    text-decoration: underline;
}

.download-app {
    width: 150px;
    margin: auto;
    margin-top: 18px;
}

/* -------------------------- DASHBOARD CLOSE ---------------------------- */

/* ----- IN-PLAY ------ */

.bet-slip-bar {
    height: 30px;
    color: #ffffff !important;
    min-height: 10px !important;
    background-position: 0 -7126px;
}

.bet-slip-bar svg {
    color: #ffffff !important;
}

.bet-slip-content {
    font-size: 13px !important;
    padding: 15px 5px 2px !important;
}

.bet-slip-block {
    min-height: calc(100vh - 180px);
}

.place-bet-block .checkbox .MuiCheckbox-root {
    padding: 0;
    margin-right: 2px;
}

.place-bet-block .place-bet-td>label {
    margin-right: 8px !important;
}

.place-bet-block .checkbox .MuiCheckbox-root svg {
    font-size: 16px;
}

.place-bet-block .checkbox span {
    font-size: 12px;
}

button.site-btn {
    background: #eee url(./images/bg-btn.gif);
    color: #1e1e1e !important;
    background-size: contain;
    border: 1px solid #bbb;
    padding: 4px 14px;
    text-transform: none;
    font-weight: bold;
}

.place-bet-block button.cancel-btn {
    margin-top: 5px;
    margin-bottom: 5px;
    height: 31px;
    line-height: 31px;
    font-size: 12px;
}

.place-bet-block .odds-block {
    display: inline-block;
    vertical-align: middle;
}

.place-bet-block .odds {
    width: 50px;
    background: #ffffff80;
    margin: 0 4px;
    height: 33px;
    display: inline-flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    padding: 0 4px;
    border-radius: 4px;
}

.place-bet-block .odds li {
    line-height: 18px;
    font-size: 12px;
    font-weight: bold;
    list-style: none;
}

.place-bet-block .odds li:last-child {
    font-size: 10px;
    line-height: 12px;
    opacity: .5;
    color: #222;
}

.place-bet-block {
    background-color: #BEDDF4;
    border-bottom: 1px solid #7dbbe9;
}

.place-bet-block input {
    line-height: 33px;
    height: 33px;
    width: 75px;
    border: 0;
    box-shadow: inset 0px 1px 0px rgb(0 0 0 / 50%);
    border-radius: 4px;
    color: #243a48;
    text-align: right;
    padding: 0 6px 0 0;
}

button.place-bet-btn {
    background: #A4A4A4 url(./images/bg-disable.png) repeat-x;
    /* background: #A4A4A4; */
    color: #E6DFCD !important;
    margin-left: 8px;
    border-color: #aaa;
    height: 33px;
    line-height: 33px;
    font-size: 12px;
    width: 133px;
    font-weight: bold;
    border: 1px solid #bbb;
}

.place-bet-block .place-bet-td {
    width: 50%;
}

.place-bet-sec-block {
    background-color: #d4e8f8;
}

.place-bet-sec-block td {
    text-align: right !important;
}

.place-bet-sec-block .bet-value {
    margin: 4px;
    font-weight: normal;
    font-size: 12px;
    padding: 2px 10px;
    width: 89px;
    height: 24px;
}

/* HERE */

.bet-slip-bar {
    height: 30px;
    color: #ffffff !important;
    min-height: 10px !important;
    background-position: 0 -7126px;
}

.bet-slip-bar svg {
    color: #ffffff !important;
}

.bet-slip-content {
    font-size: 13px !important;
    padding: 15px 5px 2px !important;
}

.MuiTabPanel-root {
    padding: 0 !important;
}

.multitable>div {
    border-radius: 0;
    margin-top: 4px;
}

.mini-games-grid {
    flex: 1;
}

.tab {
    min-width: 375px;
    width: 375px !important;
    margin: 0 0 0px;
    border-left: 1px solid #3B5160;
    border-radius: 5px;
    background-color: #fff !important;
}

.tab button.MuiTab-root {
    min-width: auto !important;
    min-height: auto;
    background: #fff;
    opacity: 1;
    color: #000;
    line-height: 1.2;
    font-size: 13px;
    border-right: 1px solid #3B5160;
    text-transform: none;
    font-weight: bold;
}

.tab button.MuiTab-textColorInherit.Mui-selected {
    background-color: #3B5160;
    color: #fff;
}

.tab button:last-child {
    /* border-right: none; */
}

.tab>div {
    min-height: auto;
    border: 1px solid #3B5160;
    border-radius: 6px;
}

.tab .MuiTabs-indicator {
    display: none;
}

.inplay-tabs {
    height: calc(100vh - 150px);
    overflow-y: scroll;
}

.tbl-top-head {
    background: #FFB80C;
    padding: 8px 12px;
    font-size: 12px;
    font-weight: bold;
}

.inplay-tabs>div {
    padding: 0;
}

.tbl_head th {
    padding: 2px;
    color: #000000 !important;
    font-size: 12px;
}

.tbl_head {
    background: #CED5DA;
}

.tbl_head .MuiTableCell-alignRight {
    text-align: center;
}

.tbl_body th {
    padding: 1px;
    vertical-align: middle;
}

.pin-icon a {
    background-image: url("./images/main.png");
    background-position: -398px -57px;
    height: 21px;
    width: 16px !important;
    display: block;
    margin: auto;
}

.table_first_row {
    align-items: right !important;
}

.tbl_body .MuiTableCell-root {
    height: 100%;
    padding: 2px;
    vertical-align: middle;
    text-align: center;
}

.tbl_body .table_first_row {
    height: 100%;
    padding-left: 10px;
    vertical-align: middle;
    text-align: left;
    /* display: inline-block; */
}

.iplay-now-head img {
    width: 16px;
    margin-left: 4px;
    margin-right: 4px;
    vertical-align: middle;
}

.table_first_row .text_left-in-play {
    font-weight: bold;
    color: #2789CE;
    font-size: 12px;
}

.table_first_row .in-play {
    color: #508D0E;
    font-weight: bold;
}

.table_first_row .in-play img {
    vertical-align: middle;
}

.table_first_row .circle-size {
    color: #508D0E;
    font-size: 14px;
    vertical-align: middle;
}

.odd_even_clr .MuiTableCell-root.MuiTableCell-alignRight:nth-child(2n+1) span {
    background: #fca4b7;
}

.odd_even_clr td {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.odd_even_clr .MuiTableCell-root.MuiTableCell-alignRight:nth-child(2n),
.odd_even_clr .MuiTableCell-root.MuiTableCell-alignRight:nth-child(2n+1) {
    width: 70px;
}

.odd_even_clr .MuiTableCell-root.MuiTableCell-alignRight:nth-child(2n) span {
    background: #7cc4f7;
}

.odd_even_clr .MuiTableCell-root.MuiTableCell-alignRight span {
    display: inline-block;
    width: 95%;
    font-weight: bold;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    color: #000000;
}

.game-name img {
    width: 50px;
    vertical-align: middle;
    margin-left: 8px;
}

.icon-on-right {
    float: right;
}

.icon-on-right img {
    margin-right: 8px;
}

.p-0 {
    padding: 0px !important;
}

/* here */

.in-play-table .MuiBox-root {
    padding: 0px !important;
}

.in-play-table .in-play-box {
    width: '100%';
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.in-play-box .MuiBox-root {
    background-color: #E0E6E6;
    padding: 0px 10px 0px !important;
    border-bottom: 1px solid #7E97A7;
    font-size: 14px;
    align-items: center;
}

.in-play-box .circle-size {
    font-size: 9px !important;
    color: #243A48;
}

.paddingX-1 {
    padding: 10px !important;
}

.in-play-box .MuiBox-root button {
    margin-left: auto !important;
    margin-right: 0px !important;
    text-transform: none;
}

/* CRICKET SECTION */

/* CRICKET SECTION */

.cricket-section {
    margin: 10px;
    /* overflow: hidden; */
    /* overflow-y: auto; */
    /* max-height: calc(100vh - 150px); */
}

.cricket-banner img {
    width: 100% !important;
}

.cricket_tbl_body {
    background-color: #fff;
}

.cricket_tbl_body th {
    padding: 1px;
    vertical-align: middle;
}

.cricket_tbl_body p {
    margin: 0;
}

.cricket_tbl_body .MuiTableCell-root {
    height: 100%;
    padding: 0px;
    vertical-align: middle;
    text-align: center;
}

.cricket_tbl_body .table_first_row {
    height: 100%;
    padding-left: 10px;
    vertical-align: middle;
    text-align: left;
    /* display: inline-block; */
    padding-left: 6px !important;
}

.special_bet th {
    padding: 2px;
    color: #fff;
}

.special_bet {
    background: #243A48 !important;
}

.special_bet .MuiTableCell-alignRight {
    text-align: center;
}

.header-pin img {
    width: 25px;
    margin-bottom: -6px;
    margin-right: 6px;
}

.fullmarket_tbl_body {
    background-color: #FAF8D8;
}

.fullmarket_tbl_body th {
    padding: 1px;
    vertical-align: middle;
}

.fullmarket_tbl_body p {
    margin: 0;
}

.fullmarket_tbl_body .MuiTableCell-root {
    height: 100%;
    padding: 0px;
    vertical-align: middle;
    text-align: center;
}

.fullmarket_tbl_body .table_first_row {
    height: 46px;
    padding-left: 10px;
    vertical-align: middle;
    text-align: left;
    /* display: inline-block; */
}

.fancy-table-cricket {
    background-color: #fff;
    margin: 2px;
}

.back-gradient {
    background-image: linear-gradient(90deg, rgba(130, 183, 221, 0.15) 0%, rgba(130, 183, 221, 0.8) 65%);
}

.back-gradient .back-box {
    background: #72BBEF;
}

.back-block,
.lay-block {
    margin: 0;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    height: 48px;
    width: 100%;
}

.back-block>span,
.lay-block>span {
    width: 80px;
}

.back-block .back-box,
.lay-block .lay-box {
    height: 40px;
    line-height: 40px;
    border: 1px solid #fff;
    border-radius: 4px;
}

.lay-gradient {
    background-image: linear-gradient(270deg, rgba(231, 170, 184, 0.15) 5%, rgba(231, 170, 184, 0.8) 60%);
}

.lay-gradient .lay-box {
    background: #FAA9BA;
}

.match-btn {
    display: flex;
    justify-content: center;
}

.match-btn span {
    display: inline-flex;
}

.match-btn span a {
    background-size: contain;
    background-repeat: no-repeat;
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: .2px;
    width: 107px;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
}

.match-btn span a img {
    width: 8px !important;
    vertical-align: middle;
}

.match-btn span a svg {
    font-size: 16px;
    vertical-align: middle;
}

.match-btn .btn-pin {
    background-image: url('./images/match-odd-left.png');
}

.match-btn .btn-refresh {
    background-image: url('./images/match-odd-right.png');
}

/* -- Multi Market -- */

.multi-marketing {
    margin: 16px;
    min-height: calc(100vh - 140px);
    overflow-y: scroll;
}

.multi-marketing h4,
.multi-marketing p {
    margin: 0;
}

.multi-marketing h4 {
    font-size: 13px;
    margin-bottom: 4px;
}

.multi-marketing p {
    font-size: 12px;
}

/* SIDE LIST */

.side_list {
    background: #ffffff !important;
    margin: 0 0 0 12px !important;
    padding: 0 !important;
    height: calc(100vh - 140px);
}

.side_list .list-icon svg {
    font-size: 10px;
    padding: 3px;
    background: orange;
    border-radius: 4px;
}

.side_list .MuiTypography-displayBlock {
    font-size: 12px;
}

.side_list>a {
    padding: 4px 8px;
    border-bottom: 1px solid #EFE1C1;
}

.side_list>a>div {
    margin: 0;
}

.first-list-icon {
    font-size: 12px;
}

.first-item {
    background-color: #222222 !important;
    color: orange !important;
}

/* IPL Section */

.ipl-section .tbl_head {
    font-weight: bold;
    background: #1B2D38;
}

.tbl_head .winner {
    background-color: #fff;
    color: #243A48;
    padding: 1px 8px;
    border-radius: 4px;
    margin: 0 5px;
}

.ipl-section .tbl_head .refresh-icon {
    line-height: 0.5;
    text-align: end;
    float: right;
}

.ipl-section .ipl-table-space-bet {
    background-color: #fff;
    display: flex;
    padding: 0px;
    justify-content: space-between;
    border-bottom: 1px solid #7E97A7;
    margin-bottom: 15px;
}

.ipl-section .game-info {
    padding: 7px 0;
    margin-left: 8px;
}

.fancy-info {
    padding: 0 6px;
    margin: 7px 0;
    background-color: #BED5D8;
    font-size: 12px;
    line-height: 16px;
    border-radius: 3px;
}

.game-matched-bold {
    font-weight: bold;
    color: #1E1E1E;
    padding: 7px 0 7px 5px;
}

.margin-match {
    margin: 7px 0px;
}

.ipl-tbl-head .MuiTableCell-alignRight {
    text-align: right;
}

.ipl-section .ipl-tbl-head {
    font-weight: bold;
    /* background: #1B2D38; */
    padding: 0px;
}

.ipl-tbl-head th {
    padding: 2px;
    font-size: 11px;
    line-height: 17px;
}

.ipl_tbl_body .spark>div>div {
    font-size: 12px;
}

.ipl_tbl_body .spark>div>span {
    font-size: 11px;
    font-weight: normal;
}

.ipl-tbl-head .ipl-middle-head {
    text-align: center !important;
    color: #000 !important;
    background-repeat: no-repeat;
    font-size: 12px;
    font-weight: bold;
}

.ipl-tbl-head .ipl-back {
    background-position: -274px -273px;
}

.ipl-tbl-head .ipl-lay {
    background-position: 100% -399px;
}

.ipl-section .tbl_head th {
    color: #ffffff !important;
}

.ipl_tbl_body td {
    background-color: #fff;
    padding: 0px 0px;
}

.ipl_tbl_body .odd_even_clr .MuiTableCell-root {
    padding: 0,
}

.ipl_tbl_body .odd_even_clr .MuiTableCell-root.MuiTableCell-alignCenter {
    font-weight: bold;
    width: 9%;
    padding: 8px 10px;
    line-height: 1;
}

.bookmark-full-market .odd_even_clr .MuiTableCell-root.MuiTableCell-alignCenter span {
    font-weight: normal;
    font-size: 11px;
}

.bookmark-full-market td {
    background-color: #fff;
    padding: 0px 0px;
}

.bookmark-full-market .odd_even_clr .MuiTableCell-root {
    padding: 0,
}

.bookmark-full-market .odd_even_clr .MuiTableCell-root.MuiTableCell-alignCenter {
    font-weight: bold;
    width: 12%;
    padding: 2px 8px;
}

.bookmark-full-market .odd_even_clr .MuiTableCell-root.MuiTableCell-alignCenter span {
    font-weight: normal;
    font-size: 11px;
    padding: 20px;
    margin: 2px;
}

.table_first_row .text_left-in-bookmark {
    font-weight: bold;
    font: 11px;
}

.disabled_td {
    cursor: not-allowed;
    background: rgba(51, 51, 51, 0.2) url(./images/bg-disabled.png);
}

.table_first_row .text_left-in-ipl {
    font-weight: bold;
    padding-left: 10px;
    font-size: 12px;
}

.icon-predict {
    background-position: -398px -2028px;
    /* height: 16px; */
    width: 16px;
    background-image: url("./images/main.png");
    margin-right: 5px;
    vertical-align: middle;
}

/* financial market */

.financial-tbl-head {
    border-bottom: 4px solid #6AB00F;
}

.financial-tbl-head th {
    padding: 0px;
    padding-bottom: 1px;
    color: #fff !important;
    font-size: 13px;
}

.financial-tbl-head th>span {
    padding: 10px;
}

.financial-tbl-head th>span>span {
    background-color: #51821C;
    padding: 3px;
    border-radius: 6px;
    margin-left: 5px;
}

.financial-tbl-head th .helpIcon {
    background-color: #BED5D8;
    border-radius: 5px;
    width: 10px;
    align-items: center;
    justify-content: center;
    padding: 3px;
    padding-top: 15px;
}

.financial_tbl_body td {
    background-color: #fff;
    padding: 4px 9px;
}

.financial_tbl_body .odd_even_clr .MuiTableCell-root {
    padding-top: 0px;
    padding-bottom: 0px;
}

.financial_tbl_body td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft {
    padding-left: 13px;
}

.financial_tbl_body .odd_even_clr .MuiTableCell-root.MuiTableCell-alignCenter {
    font-weight: bold;
    width: 10%;
    padding: 8px 10px;
    line-height: 1;
}

.financial_tbl_body .odd_even_clr .MuiTableCell-root.MuiTableCell-alignCenter span {
    font-weight: normal;
    font-size: 10px;
}

/* HorseRacing */

.horse-section {
    margin: 10px;
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 150px);
}

.margin-table-bottom {
    margin-bottom: 20px;
}

.horse-tbl-top {
    margin-bottom: 1px;
    background-repeat: repeat-x;
    background-image: linear-gradient(180deg, #2A3A43 27%, #1C282D 83%);
    color: #fff;
    line-height: 25px;
    height: 25px;
    font-size: 13px;
    font-weight: bold;
    padding: 1px 3px;
}

.horse-section .tbl_head {
    font-weight: bold;
    background: #1B2D38;
}

.horse-section .tbl_head th {
    color: #ffffff !important;
}

.horse-section .horse-table-space {
    background-color: #fff;
    display: flex;
    padding: 0px;
    /* justify-content: space-between; */
    border-bottom: 1px solid #7E97A7;
    margin-bottom: 15px;
}

.horse-section .header-one-link>a {
    color: #75BFF5;
    font-size: 14px;
}

.horse-section .header-one-link {
    padding-left: 9px;
    font-weight: bold;
    color: #C5D0D7;
}

.horse-section .upcomeHeader {
    font-size: 11px;
    line-height: 16px;
    color: #7E97A7;
    border: 1px solid #7E97A7;
    border-radius: 4px;
    padding: 0 5px;
    margin: 7px 0;
    margin-left: 12px;
}

.horse-section .header-link>a {
    padding-left: 9px;
    font-weight: bold;
    color: #2789CE;
}

.horse-section .header-link {
    align-self: center;
}

.horse-section .horse-tabs>div {
    padding: 0px;
}

.horse-section .horse-link>span {
    font-weight: bold;
    font-size: 14px;
    color: #2789CE;
}

.horse-section .horse-link>a {
    padding: 5px 9px;
    align-self: center;
    /* font-weight: bold; */
    font-size: 12px;
    color: #2789CE;
    border: 1px solid #fff;
}

.horse-section .horse-link>a:hover {
    border: 1px solid #2789CE;
    border-radius: 3px;
}

/* Tennis Section*/

.tennis-section {
    margin: 10px;
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 150px);
}

.tennis-banner img {
    width: 100% !important;
}

.tennis_tbl_body {
    background-color: #fff;
}

.tennis_tbl_body th {
    padding: 1px;
    vertical-align: middle;
}

.tennis_tbl_body p {
    margin: 0;
}

.tennis_tbl_body .MuiTableCell-root {
    height: 100%;
    padding: 0px;
    vertical-align: middle;
    text-align: center;
}

.tennis_tbl_body .table_first_row {
    height: 100%;
    padding-left: 10px;
    vertical-align: middle;
    text-align: left;
    /* display: inline-block; */
}

/* SOCCER SECTION */

.soccer-section {
    margin: 10px;
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 150px);
}

.soccer-banner img {
    width: 100% !important;
}

.soccer_tbl_body {
    background-color: #fff;
}

.soccer_tbl_body th {
    padding: 1px;
    vertical-align: middle;
}

.soccer_tbl_body p {
    margin: 0;
}

.soccer_tbl_body .MuiTableCell-root {
    height: 100%;
    padding: 0px;
    vertical-align: middle;
    text-align: center;
}

.soccer_tbl_body .table_first_row {
    height: 100%;
    padding-left: 10px;
    vertical-align: middle;
    text-align: left;
    /* display: inline-block; */
}

/* Horse Single */

.horse-section .tbl_head {
    font-weight: bold;
    background: #1B2D38;
}

.horse-section .tbl_head .refresh-icon {
    line-height: 0.5;
    text-align: end;
    float: right;
}

.horse-section .ipl-table-space-bet {
    background-color: #fff;
    display: flex;
    padding: 0px;
    justify-content: space-between;
    border-bottom: 1px solid #7E97A7;
    margin-bottom: 15px;
}

.horse-section .game-info {
    margin-left: 8px;
}

.horse-section .ipl-tbl-head {
    font-weight: bold;
    /* background: #1B2D38; */
    padding: 0px;
}

.horse-section .tbl_head th {
    color: #ffffff !important;
}

.horse-section .pin-icon a {
    display: inline-block;
    background-position: -393px -78px;
    height: 21px !important;
    width: 25px !important;
}

.font-xs {
    font-size: 20px !important;
    font-weight: bold !important;
    padding: 8px 8px 8px 6px !important;
}

.game-matched {
    background-color: #D0021B;
    color: white;
    padding: 10px;
    margin-left: 4px;
}

.horse-single-table .MuiBox-root {
    padding: 0px;
}

.horse-tabbing {
    padding: 0px !important;
}

.horse-table-flex {
    text-align: center;
    padding: 0px 3px !important;
}

.lay-all {
    background-color: #FAA9BA;
    font-size: 14px !important;
    border-left: 1px solid #fff;
    border-top-right-radius: 10px;
    color: #000 !important;
}

.back-all {
    background-color: #72BBEF;
    font-size: 14px !important;
    border-top-left-radius: 10px;
    color: #000 !important;
}

.text-black {
    color: #000;
}

.account-table {
    width: 100%;
    margin-bottom: 15px;
    min-width: auto !important;
}

.account-table .table_head th {
    font-weight: bold;
    padding: 0px 10px;
    background-color: #7E97A7;
    color: #fff;
}

.account-table .table_body th {
    padding: 3px 10px;
    width: 30%;
    background-color: #fff;
    font-size: 12px !important;
}

.account-table .table_body td {
    background-color: #fff;
    font-size: 12px !important;
    padding: 3px 10px;
}

.balance-overview {
    background-color: #fff;
    border-bottom: 1px solid #7E97A7;
    color: #3B5160;
    padding: 7px 10px 5px;
    margin-bottom: 15px;
}

.balance-section {
    border-right: 1px solid #d8d8d8;
}

.balance-section .balance {
    font-weight: bold;
}

.balance-section .balance-amount {
    font-size: 30px;
    font-weight: bold;
    color: #2789CE;
    display: flex;
}

.balance-section .balance-amount .balance-currency {
    font-size: 12px;
    color: #7E97A7;
    font-weight: normal;
    margin-left: 8px;
    margin-top: 20px;
}

.welcome-section {
    padding-left: 10px;
}

.welcome-section .welcome-text {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 7px;
}

.welcome-section .welcome-content {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 5px;
}

.balance-table .table_head th,
.statement-table .table_head th {
    color: #243A48;
    background-color: #E4E4E4;
    border: 1px solid #7E97A7;
    border-width: 1px 0 1px 0;
    padding: 4px 10px;
    font-weight: bold;
    font-size: 12px;
}

.statement-table .table_body th,
.statement-table .table_body td {
    color: #243A48;
    background-color: #fff;
    border: 1px solid #7E97A7;
    border-width: 1px 0 1px 0;
    padding: 10px 10px 5px;
    font-size: 12px;
}

.green {
    color: #508D0E !important;
}

.green {
    color: #508D0E !important;
}

.my-bets header {
    min-width: 375px !important;
    width: 48% !important;
}

.current-bet>div {
    padding: 0px;
    margin-top: 16px;
}

.current-bet header {
    min-width: 375px !important;
    width: fit-content !important;
}

.current-bet header .MuiTabs-root .MuiTabs-flexContainer {
    width: fit-content;
}

.current-bet header button.MuiTab-root {
    padding: 6px 35px;
}

/* RESULT */

.result-table>div {
    padding: 8px 0px;
}

.result-box {
    background-color: #3B5160;
    border-bottom: 1px solid #7E97A7;
    color: #fff;
    line-height: 24px;
    font-weight: bold;
    padding: 0 10px;
}

.result-table .table_head th {
    color: #243A48;
    background-color: #E4E4E4;
    border: 1px solid #7E97A7;
    border-width: 1px 0 1px 0;
    padding: 4px 10px;
    color: #243A48;
    font-weight: bold;
    font-size: 12px;
}

.result-table .table_body th,
.result-table .table_body td {
    color: #243A48;
    background-color: #fff;
    border: 1px solid #7E97A7;
    border-width: 1px 0 1px 0;
    padding: 10px 10px 5px;
    font-size: 12px;
}

.arrow-with-danger {
    font-size: 10px;
    color: rgb(148, 19, 19);
    font-weight: 'bold';
}

.arrow-with-danger>svg {
    font-size: 14px;
    vertical-align: bottom;
}

/* SignUp */

.brand-column>div {
    margin-top: calc(100vh/2);
}

.betbarter-column {
    background-image: url(./images/KV-pic-9wicketslive.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left
}

.bethive-column {
    background-image: url(./images/bethive-background.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center
}

.betbazar-column {
    background-image: url(./images/Background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center
}

.setsport-column {
    background-image: url(./images/betbuzbg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center
}


.sky247-column {
    background-image: url(./images/sky-background.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right
}

.signup-body {
    background-color: #000;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    height: 100vh;
}

.brand-column {
    width: 100%;
    height: 100vh;
    background-color: gray;
    /* display: flex; */
    flex-wrap: wrap;
    position: relative;
}

.middle-bar {
    height: 150px;
    width: 100%;
    background-color: red;
    align-self: center;
    display: flex
}

.middle-bar-content {
    margin-top: auto;
    display: flex;
    flex-direction: column
}

.middle-bar-content span {
    color: #fff;
    font-size: .7em;
    font-weight: 600;
    letter-spacing: .4px
}

.middle-bar-logo {
    width: 180px;
    margin: 0 0 16px 0
}

.align-start {
    justify-content: flex-start
}

.align-center {
    justify-content: center
}

.align-end {
    justify-content: flex-end
}

.align-start .middle-bar-content {
    padding: 0 0 16px 16px
}

.align-center .middle-bar-content {
    padding: 0 0 16px 0;
    align-items: center
}

.align-end .middle-bar-content {
    padding: 0 16px 16px 0;
    align-items: flex-end
}

.bottom-bar {
    position: absolute;
    bottom: 16px;
    width: 100%;
    display: flex;
    z-index: 99;
    flex-wrap: wrap
}

.text-align-center {
    justify-content: center;
    bottom: 90px
}

.text-align-right {
    justify-content: flex-end;
    right: 32px;
    bottom: 90px
}

.text-align-left {
    justify-content: flex-start;
    left: 32px;
    bottom: 90px
}

.betbarter-column .middle-bar {
    background: #6c0102;
    background: linear-gradient(90deg, #6c0102 0, #b20f20 100%)
}

.bethive-column .middle-bar {
    background: #4e2ace;
    background: linear-gradient(90deg, rgba(78, 42, 206) 0, rgba(78, 42, 206) 100%)
}

.sky247-column .middle-bar {
    background: #fdce32;
    background: linear-gradient(90deg, #fdce32 0, #fba70b 100%)
}


.setsport-column .middle-bar {
    background: #4e2ace;
    background: linear-gradient(90deg, rgba(78, 42, 206) 0, rgba(78, 42, 206) 100%)
}

.betbazar-column .middle-bar {
    background: #4e2ace;
    background: linear-gradient(90deg, rgba(78, 42, 206) 0, rgba(78, 42, 206) 100%)
}

.inner-text {
    color: #fff;
    width: 100%;
    text-transform: uppercase;
    font-size: .9em;
    font-weight: 600;
    letter-spacing: .4px
}

.inner-text .big-text {
    font-size: 1.5em;
    margin: 0
}

.inner-text .small-text {
    margin: 0;
    font-size: 1.5em;
}

.bold {
    font-weight: 700
}

.text-align-right .inner-text {
    text-align: right
}

.text-align-center .inner-text {
    text-align: center
}



.btn-betbarter {
    color: red;
    border: 2px solid red;
    text-shadow: 0 2px 10px red
}

.btn-bethive {
    color: #4e2ace;
    border: 2px solid #4e2ace;
    text-shadow: 0 2px 10px #4e2ace
}

.btn-sky247 {
    color: #ff0;
    border: 2px solid #ff0;
    text-shadow: 0 2px 10px #e1e137
}

.btn-betbarter:hover {
    background-color: red;
    color: #fff;
    transition: .4s
}

.btn-bethive:hover {
    background-color: #4e2ace;
    color: #fff;
    transition: .4s;
}

.btn-sky247:hover {
    color: #000;
    background-color: #ff0;
    transition: .4s;
}

.characters {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 100vh;
    z-index: 9;
    max-height: 800px;
}

.character {
    height: 100vh;
}

.football-player {
    position: absolute;
    top: 0;
    left: calc(-100% / 2);
    z-index: 2;
}

.cricket-player {
    position: absolute;
    top: 0;
    right: calc(-100% / 2);
    z-index: 2;
}

.character img {
    height: 100%;
}

.modal-container {
    position: absolute;
    z-index: 9999;
    background-color: #000000cf;
    width: 100vw;
    height: 100vh;
    top: 0;
    display: flex;
    transition: 1s;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.modal-inner {
    background-color: #fff;
    max-width: 90vw;
    box-sizing: border-box;
    padding: 16px;
    width: 500px;
    border-radius: 8px;
}

.modal-body {
    font-size: 1.2em;
    line-height: 28px;
    color: #000;
    display: flex;
    flex-direction: column;
    font-weight: 400;
}

.modal-text {
    margin: 24px 0;
}

.modal-logo {
    width: 140px;
    margin: 0 auto;
}

/* Content */

.content {
    height: 100%;
    display: flex;
    background: #fff;
}

.announce-header {
    background-image: linear-gradient(180deg, #424242 0%, #000 71%);
    position: fixed;
    width: 100%;
    height: 56px;
    padding-left: 24px;
    border-bottom: 1px solid #E0E6E6;
}

.announce-header h1:before {
    top: 4px;
    width: 6px;
    height: 24px;
    margin-right: 8px;
    content: "|";
    background: #FFB80C;
    border-radius: 100px;
}

.announce-header h1 {
    color: #fff;
    font-size: 20px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: bold;
    margin-top: 16px;
}

.tc-content {
    padding: 25px;
    margin-top: 30px;
}

.account-tabs>div {
    border-bottom: 1px solid;
}

.account-tabs .MuiTabs-indicator {
    display: none;
}

.account-tabs button {
    min-height: auto;
    text-align: left;
    font-size: 12px;
    text-transform: none;
    color: #222222;
    opacity: 1;
    line-height: 1.5;
    border-bottom: 1px solid #EFE1C1;
}

.account-tabs button.Mui-selected {
    background: #F2DCA7;
}

.account-tabs button span {
    align-items: flex-start;
}

.account-tabs>a {
    width: 230px !important;
    margin-left: auto;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 4px;
    font-size: 12px;
    align-items: center;
}

.account-tabs>a .MuiTypography-displayBlock {
    line-height: 0;
}

/* My Bets */

.bets-table>div {
    padding: 8px 0px;
}

.bets-box {
    margin-top: 20px;
    background-color: #3B5160;
    border-bottom: 1px solid #7E97A7;
    color: #fff;
    line-height: 24px;
    font-weight: bold;
    padding: 0 10px;
}


.input-list {
    margin-bottom: 10px;
    display: flex;
}

.bet-status {
    width: 120px;
    height: 25px;
    line-height: 25px;
    cursor: pointer;
}

.ml-0 {
    margin-left: 0px !important;
}

.input-list>span {
    line-height: 25px;
    margin: 0 10px 0px 8px;
    white-space: nowrap;
    font-size: 12px;
    font-weight: normal;
}

.bets-table .table_head th {
    color: #243A48;
    background-color: #E4E4E4;
    border: 1px solid #7E97A7;
    border-width: 1px 0 1px 0;
    padding: 0px 10px;
    color: #243A48;
    font-weight: bold;
    font-size: 12px;
}

.bets-table .table_body th,
.bets-table .table_body td {
    color: #243A48;
    background-color: #fff;
    border: 1px solid #7E97A7;
    border-width: 1px 0 1px 0;
    padding: 5px 10px 3px;
    font-size: 12px;
}

.binary-tag {
    font-weight: bold;
    background-color: #6AB00F;
    border-top-right-radius: 10px;
    position: relative;
    top: 5px;
    padding: 7px !important;
}

.bookmarket-header-text {
    margin: 0px 8px 0px 3px !important;
    font-size: 10px;
}

.bookmark-last-icon {
    background-image: url("./images/main.png");
    background-position: -392px -76px;
    height: 23px;
    width: 23px !important;
    margin-right: 7px !important;
}

.bookmark-last-icon-table {
    background-image: url("./images/main.png");
    background-position: -392px -76px;
    height: 23px;
    width: 23px !important;
    margin-right: 7px !important;
    margin-left: -12px !important;
}

.full-market-margin {
    margin-bottom: 20px;
}

/* SABA POPUP */

.saga-modal .awcTitle {
    font-size: 20px;
    font-weight: bold;
}

.close-saga {
    position: absolute !important;
    top: 0;
    right: 0;
}

.saga-modal .MuiDialog-paperWidthSm {
    min-width: 540px;
}

.saga-footer button,
.close-btn {
    width: 100%;
    border: 1px solid #bbb;
    border-radius: 4px;
    height: unset;
    background: #eee url(./images/bg-disable.png);
    color: #E6DFCD !important;
    background-size: contain;
    font-size: 12px;
    font-weight: bold;
    text-transform: unset;
}

.saga-footer button.cancel,
.close-btn {
    background: #eee url(./images/bg-btn.gif);
    color: #1e1e1e !important;
    background-size: contain;
}

.saga-footer {
    padding: 10px !important;
}

.saga-login-msg p {
    padding: 6px 12px;
    margin: 0;
    font-size: 12px;
    color: red;
}

.range-parent {
    background-color: #CDE3F0;
}

.ranger-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #999;
    border-radius: 8px;
    background: #fff;
    min-height: 50px;
}

.ranger-bar div {
    text-align: center;
}

.ranger-bar div.range {
    width: 80%;
    padding: 0 16px;
}

.ranger-bar div.start {
    border-right: 1px solid #999;
}

.ranger-bar div.max {
    border-left: 1px solid #999;
}

.ranger-bar div.start,
.ranger-bar div.max {
    width: 10%;
    line-height: 51px;
    font-weight: bold;
    cursor: pointer;
}

.MuiSlider-thumb>span {
    display: none;
}

.range-show {
    display: flex;
    padding: 0 !important;
    position: relative;
}

.range-show .left-saga,
.range-show .right-saga {
    width: 100%;
    min-height: 90px;
    font-size: 14px;
    padding: 8px 18px;
}

.range-show .right-saga {
    border-left: 1px solid #999;
    text-align: right;
}

.balance-show {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
}

.balance-show input {
    background: #fff;
    border: 1px solid #FFB80C;
    color: #2963A7;
    border-radius: 6px;
    max-width: 240px;
    line-height: 20px;
    padding: 5px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    position: relative;
}

.balance-show::before {
    position: absolute;
    content: '';
    left: 50%;
    bottom: -11px;
    width: 10px;
    height: 10px;
    background: #FFFFFF;
    border-bottom: 1px solid #DA8700;
    border-right: 1px solid #DA8700;
    transform: translate(-50%, -50%) rotate(45deg);
    z-index: 99999;
}

.fancy_cricket_body {
    background-color: #fff;
}

.fancy-tab-content>div {
    padding: 0px;
}

.tab-cricket-fancy>header {
    background-image: linear-gradient(180deg, #0A92A5 15%, #076875 100%);
}

.tab-cricket-fancy>header>.MuiTabs-root {
    min-height: auto;
}

.tab-fancy {
    min-width: 375px;
}

.tab-fancy button.MuiTab-root {
    min-width: auto !important;
    min-height: auto;
    background: #84c3cc;
    opacity: 1;
    color: #076875;
    line-height: 0.9;
    font-size: 13px;
    text-transform: none;
    font-weight: bold;
    padding: 4px 20px;
    margin: 5px 0px;
}

.tab-fancy button:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.tab-fancy button:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.tab-fancy button.MuiTab-textColorInherit.Mui-selected {
    background-color: #fff;
    color: #076875;
    position: relative;
    border-radius: 5px;
}

.tab-fancy .MuiTabs-indicator {
    display: none;
}

.cricket-fancy-head .fancy-header-text img {
    vertical-align: middle;
    width: 30px;
}

.cricket-fancy-head .fancy-header-text span {
    vertical-align: middle;
}

.cricket-fancy-head .fancy-header-text {
    font-weight: bold;
    font-size: 12px;
    vertical-align: top;
}

.cricket-fancy-head>span {
    /*  background-color: #0a94a6; */
    padding: 4px 30px 7px 10px;
    background-image: linear-gradient(-180deg, #0A92A5 0%, #087989 82%);
    color: #fff;
    border-bottom: 2px solid #0b6a77;
}

.cricket-fancy-head .btn-fancybet_rules {
    background: url(./images/main.png);
    vertical-align: top;
    margin-left: -4px;
    margin-top: -4px;
    height: 25px;
    width: 37px;
    display: inline-block;
    background-position: -377px -1395px;
}

/* Horse Single Tabbing */

.horse-tabbing {
    height: 34px;
}

.horse-tabbing>header {
    background: #c1ced6 url(./images/bg-horse-tab.png);
    background-position: 0 -11538px;
}

.horse-tabbing .MuiTabs-indicator {
    display: none;
}

.horse-tabbing button span {
    height: 32px;
}

.horse-tabbing button {
    min-height: 32px;
    max-height: 32px;
    line-height: 28px;
    padding: 0px 12px;
    border-right: 1px solid #96abb8;
    color: #333333;
    text-transform: none;
    font-size: 13px;
    opacity: 1;
}

.horse-tabbing button.Mui-selected {
    border-top: 2px solid #FFB80C;
    background: #fff;
}

.horse-tabbing .MuiTabs-root {
    min-height: 30px;
}

/* Toggle Button */

.switch {
    position: relative;
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    font-size: 13px;
    color: #243848;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #a2b1ba;
    transition: .4s;
    box-shadow: inset 0 0.26667vw 0.8vw 0 rgb(0 0 0 / 50%);
}

.slider:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 8px;
    left: 5px;
    top: 50%;
    transform: translate(0px, -50%);
    background-color: white;
    transition: .4s;
    border-radius: 50px !important;
}

input:checked+.slider {
    background-color: #2ab934;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translate(14px, -50%);
    -ms-transform: translate(14px, -50%);
    transform: translate(14px, -50%);
}

/*------ ADDED CSS ---------*/

.on {
    display: none;
}

.on,
.off {
    color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 10px;
    font-family: Verdana, sans-serif;
}

input:checked+.slider .on {
    display: block;
}

input:checked+.slider .off {
    display: none;
}

.slider.round {
    border-radius: 7px;
    height: 32px;
    width: 32px;
}

.slider.round:before {
    border-radius: 10%;
}

.pbb {
    display: none !important;
}

.typeing:after {
    content: '';
    width: 0.66667vw;
    height: 5.33333vw;
    background-color: #1F72AC;
    animation: typeing 1s infinite;
    box-shadow: none !important;
}

@keyframes typeing {
    0% {
        opacity: 0
    }

    50% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.game-fancy {
    display: none !important;
}

/* Login Disable Toast */

.Toastify__toast-container div#success {
    display: none;
}

/* Loader */

.loading-wrap {
    display: grid;
    top: 35%;
    height: 100px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 50%);
    padding-top: 8px;
    z-index: 99999;
    position: absolute;
    left: 0;
    right: 0;
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    place-items: center
}

.loading {
    padding: 5% 0;
}

.loading li {
    list-style: none;
    font-size: 11px;
    color: #243a48;
    margin-bottom: 3x;
}

.loading img {
    background-position: -42px -415px;
    height: 22px;
    width: 38px;
}

.right-sprite,
.loading img,
.delete,
.slip-wrap .to-expand,
.matched-wrap .to-expand,
.live-wrap .to-expand,
.tv-wrap .to-expand,
.close.slip-wrap .to-expand,
.close.matched-wrap .to-expand,
.close.live-wrap .to-expand,
.close.tv-wrap .to-expand,
.slip_set-pop .slip_set,
.oneclick_set-pop .slip_set,
.slip-wrap .slip_set,
.odd-add .up,
.odd-add .down,
.odd-add.disable .up,
.odd-add.disable .down,
.slip_refresh,
.live:before,
.tv_popup,
.icon-TV,
.multi_refresh,
.o_12bet .slip-wrap .to-expand,
.o_12bet .slip-wrap.close .to-expand,
.o_12bet .matched-wrap .to-expand,
.o_12bet .matched-wrap.close .to-expand,
.o_12bet .slip_refresh,
.o_spondemo .slip-wrap .to-expand,
.o_spondemo .slip-wrap.close .to-expand,
.o_spondemo .matched-wrap .to-expand,
.o_spondemo .matched-wrap.close .to-expand,
.o_spondemo .slip_refresh,
.o_spondemo .multi_refresh,
.betbarter .slip-wrap .to-expand,
.betbarter .slip-wrap.close .to-expand,
.betbarter .matched-wrap .to-expand,
.betbarter .matched-wrap.close .to-expand,
.betbarter .slip_refresh {
    background-image: url(./images/right-sdc390e89c4.png);
    background-repeat: no-repeat;
}

.align-C,
.loading li,
.btn,
.btn-send,
.marquee-pop .pages a,
.pages a,
.calendarTable_inputBox input,
.calendarTable_inputBox textarea,
.change_pass,
.slip-back dd,
.slip-lay dd,
.slip-book dd,
.btn-live,
.btn-book,
.fancy-suspend span,
.fancy-suspend-white span,
.browser-wrap,
.saddle_1,
.saddle_1A,
.saddle_1B,
.saddle_1C,
.saddle_1D,
.saddle_1X,
.saddle_1Y,
.saddle_2,
.saddle_2B,
.saddle_2C,
.saddle_2X,
.saddle_3,
.saddle_3C,
.saddle_3D,
.saddle_3E,
.saddle_3X,
.saddle_4,
.saddle_4D,
.saddle_4X,
.saddle_5,
.saddle_6,
.saddle_7,
.saddle_8,
.saddle_9,
.saddle_10,
.saddle_11,
.saddle_11F,
.saddle_12,
.saddle_12F,
.saddle_13,
.saddle_13F,
.saddle_14,
.saddle_14F,
.saddle_15,
.saddle_15F,
.saddle_16,
.saddle_16F,
.saddle_17,
.saddle_17F,
.saddle_18,
.saddle_18F,
.saddle_19,
.saddle_19F,
.saddle_20,
.saddle_20F,
.saddle_21,
.saddle_21F,
.saddle_22,
.saddle_22F,
.saddle_23,
.saddle_23F,
.saddle_24,
.saddle_24F,
.saddle_25,
.saddle_25F,
.saddle_26,
.saddle_26F,
.saddle_27,
.saddle_27F,
.saddle_28,
.saddle_28F,
.saddle_29,
.saddle_29F,
.saddle_30,
.saddle_30F,
.login-wrap .btn-login,
.login-wrap .btn-signup,
.policy-link,
.account-wrap ul .logout a,
.stake-set .stake_edit-input,
.odds-set .stake_edit-input,
.col-edit a,
.slip-wrap p,
.slip-head li,
.matched-head li,
.stake-popup a,
.load-pending,
.tab-highlight li,
.tab-markets li,
.message-wrap p,
.dialog-wrap h4,
.dialog-wrap-w h4,
.dialog-wrap .tc-content h5,
.tc-content .dialog-wrap h5,
.dialog-wrap-w .tc-content h5,
.tc-content .dialog-wrap-w h5,
.dialog-wrap .break li,
.dialog-wrap-w .break li,
.support-wrap,
.app-link,
.game-list-col dd a,
.game-team .game-vs,
.game-scores .game-vs,
.game-scores ul .scores-box,
.tennis .scores-box,
.sum-status,
.tab-HS a,
.bets td,
.bets-HS td,
.bets-GH td,
.horse-box li,
.suspend,
.btn-rules,
.rules-wrap h3,
.closed-next,
.one_click-setting a,
.one_click-stake,
.one_click-stake input,
.one_click-stake textarea,
.quick_bet-wrap .max-bet a,
.quick_bet-progress .progress-bar-txt,
.quick_bet-message dd,
.fancy-info dt span,
.back-gradient p,
.lay-gradient p,
.special_bets-tab a,
.announce-footer,
.urgent-notice .announce-header h1,
.announce-content table,
.article-date,
.news-wrap .announce-header,
.apk-img,
.marquee-pop .nofound .nofound_box dd,
.confirm_box .white-wrap,
.go-back,
.pop-close,
.tab a,
.pages,
.chart-wrap,
.initial-balance dt span,
.final-balance dt span,
.table-trans td {
    text-align: center;
}

.header.logged-out button.MuiButtonBase-root {
    font-size: 3.46667vw;
    padding-bottom: 1px;
    padding-top: 1px;
    height: auto;
    color: #fff;
}

/* Sports Page Slider */

#root .sportsSlider {
    padding-bottom: 0;
}

.sportsSlider .Carousel-indicators-15 {
    margin: 0;
}

/* Sports Page Slider End */

/* Sports Page Tabbing */

.tab-sportsname header {
    padding-bottom: 0;
}

/* Sports Page Tabbing End */

.header-info-ico::before {
    content: "";
    background-image: url(./images/header-info.png);
    background-size: contain;
    width: 4vw;
    height: 4vw;
    float: right;
    margin-top: 5.5px;
    margin-right: 5px;
    text-align: right;
    background-repeat: no-repeat;
}

/* .header-info-ico {
    position: absolute;
    right: 0;
    width: 8vw;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: transparent;
    line-height: inherit;
    align-items: center;
    justify-content: center;
} */

.cricket-fancy-head span {
    border: none;
}

.fancy_bet-head {
    border-bottom-color: #087989;
}

.fancy_bet-head {
    position: relative;
    line-height: 8vw;
    margin-top: 0px;
    display: flex;
}

.fancy_bet-head h4 {
    height: 3vw;
    color: #fff;
    font-size: 1.2vw;
    margin-right: 2.66667vw;
    z-index: 1;
    display: flex;
}

.fancy_bet-head .pin-on,
.fancy_bet-head .pin-off {
    position: relative;
    width: 5vw;
    height: 3vw;
    margin-right: 0;
    background: url(./images/sports-dark-clip.png), linear-gradient(-180deg, #0A92A5 0%, #087989 82%);
    background-repeat: no-repeat, no-repeat;
    background-position: left, right;
    background-size: cover, cover;
    font-size: 0;
}

.fancy_bet-head .pin-off:before {
    background: url(./images/dark-pin.png) no-repeat center;
    background-size: cover;
    content: '';
}

.fancy_bet-head .pin-on:before,
.fancy_bet-head .pin-off:before {
    position: absolute;
    left: 1.6vw;
    top: 1.06667vw;
    display: block;
    width: 1.6vw;
    height: 1.6vw;
}

.fancy_bet-head h4 span,
.sportsbook_bet-head h4 span {
    height: 100%;
    padding-left: 2.66667vw;
    align-items: center;
}

.fancy_bet-head .in-play span,
.sportsbook_bet-head .in-play span {
    align-items: center;
    /* padding-right: 1.86667vw; */
    background-image: linear-gradient(-180deg, #0A92A5 0%, #087989 82%);
    display: flex;
}

.fancy_bet-head .in-play pre,
.sportsbook_bet-head .in-play pre {
    width: 1.8vw;
    height: 1.8vw;
    background-image: linear-gradient(-180deg, #91d527 0%, #60ba1e 79%);
    border-radius: 1.06667vw;
    margin-right: 1.6vw;
    justify-content: center;
    align-items: center;
    font-size: 0;
    text-indent: -99999px;
}

.fancy_bet-head .in-play pre:before,
.sportsbook_bet-head .in-play pre:before {
    content: "";
    width: 1.6vw;
    height: 1.6vw;
    background: url(./images/time-png.png) no-repeat;
    background-size: contain;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2px;
    background-position: center;
}

.fancy_bet-head .btn-head_rules {
    background-image: url(./images/bg-fanctbet_rules.png);
}

.btn-head_rules {
    width: 10.93333vw;
    height: 100%;
    background-size: 101% 101%;
    background-repeat: no-repeat;
    align-items: center;
    justify-content: center;
    display: flex;
    vertical-align: bottom;
    font-size: 0;
    text-indent: -999999999px;
}

.btn-head_rules:before {
    content: '';
    width: 2vw;
    height: 2vw;
    background-image: url(./images/icon-questionmark.png);
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
}

.tab-fancy {
    padding-left: 0;
}

.tab-fancy .MuiTabs-root {
    padding-left: 0 !important;
}

.tab-fancy .MuiTabs-root .MuiTabs-centered {
    padding: 3px 6px;
}

.tab-cricket-fancy>header.tab-fancy {
    padding-top: 0;
    padding-bottom: 0;
}

.fancy_bet-head h4.in-play {
    margin-bottom: 0;
}

.fancy_bet-head .other-tab {
    position: relative;
    height: 3vw;
    line-height: 3vw;
    color: #C5D0D7;
    font-size: 1.2vw;
    font-weight: bold;
    background-color: #243A48;
    padding: 0 0.8vw;
    width: 41%;
}

.other-tab:before {
    content: '';
    position: absolute;
    top: 0;
    left: -4.53333vw;
    width: 4.53333vw;
    height: 3vw;
    background-image: url(./images/right-dark-clip.png);
    background-repeat: no-repeat;
    background-size: 101% 101%;
    display: block;
}

.other-tab:after {
    content: '';
    position: absolute;
    top: 0;
    left: inherit;
    right: -4.53333vw;
    width: 4.53333vw;
    height: 3vw;
    background-image: url(./images/left-dark-clipp.png);
    background-repeat: no-repeat;
    background-size: 101% 101%;
    display: block;
}

.other-tab {
    /* margin-top: 4.999999vw; */
}

.other-tab .tag-new {
    top: -1.6vw;
    right: -4vw;
}

.other-tab .tag-new {
    position: absolute;
    width: 3.53333vw;
    height: 4.8vw;
    background: url(./images/tag-new-red.png) center no-repeat;
    background-size: contain;
    color: #FFFFFF;
    line-height: 4.2vw;
    font-size: 1vw;
    font-weight: bold;
    text-align: center;
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.6));
    z-index: 2;
}

/* Sports Page Tabbing End */

/* Fancy Bet Design */

.bets-wrap.fancy-bet {
    position: relative;
    background-color: #fff;
    border-bottom: 1px solid #7e97a7;
    margin-bottom: 0;
}

.bets-wrap.fancy-bet h5 {
    color: #3b5160;
    background-color: #e4f1f9;
    position: relative;
    height: 2.53333vw;
    background-color: #3b5160;
    color: #fff;
    font-size: 1.1vw;
    line-height: 8.53333vw;
    padding: 0 0 0 1.86667vw;
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin: 0;
}

.fancy-bet>h5 {
    color: #3b5160 !important;
    background-color: #e4f1f9 !important;
}

.bets-selections:last-child,
.bets-selections-head:last-child,
.trade:last-child,
.trade-2:last-child {
    border-bottom-width: 0;
}

.bets-selections,
.bets-selections-head {
    position: relative;
    min-height: 11.2vw;
    border-bottom: 1px solid #7e97a7;
    display: flex;
}

.fancy-bet .bets-selections dt {
    padding: 0.53333vw 1.86667vw;
    position: relative;
    box-sizing: border-box;
    display: flex;
}

.bets-selections dt,
.bets-selections-head dt,
.trade dt,
.trade-2 dt {
    flex: 1;
    font-size: 4vw;
    font-weight: bold;
    padding: 0 1.86667vw;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
}

.fancy-bet .bets-selections .tips {
    width: 100%;
    font-size: 2.93333vw;
    list-style-type: none;
    font-weight: normal;
    display: block;
}

.fancy-bet .bets-selections .tips dt {
    padding: 0;
    line-height: 3.46667vw;
    display: flex;
}

.fancy-bet .bets-selections .tips .win,
.fancy-bet .bets-selections .tips .to-win {
    padding-right: 1.33333vw;
}

.bets-selections span,
.bets-selections-head span,
.trade span,
.trade-2 span {
    font-weight: normal;
    font-size: 2.93333vw;
}

.fancy-bet>h5 span {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.fancy-bet .btn-fancy_info,
.bets-wrap .btn-fancy_info {
    flex-basis: 8vw;
}

.fancy-bet .btn-fancy_info,
.bets-wrap .btn-fancy_info {
    width: 8vw;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: transparent;
    line-height: inherit;
    align-items: center;
    justify-content: center;
}

.fancy-bet .btn-fancy_info {
    font-size: 0;
    text-indent: -99999px;
    display: flex;
}

.fancy-bet h5 .btn-fancy_info:before {
    background: url(./images/icon-info-blue.png) no-repeat;
    background-size: contain;
    width: 4vw;
    height: 4vw;
    float: none;
    margin: 0;
}

.fancy_info-popup,
.limit_info-popup {
    display: none;
    position: absolute;
    top: 0;
    right: 1.86667vw;
    background-color: #fff;
    border-radius: 1.06667vw;
    align-items: flex-start;
    z-index: 9;
    color: #1e1e1e;
    font-weight: normal;
    box-shadow: 0 6px 10px rgb(0 0 0 / 70%);
}

.fancy_info-popup dt,
.limit_info-popup dt {
    height: 3.2vw;
    color: #577C94;
    font-size: 2.66667vw;
    line-height: 3.2vw;
    padding: 0.8vw 0 1.06667vw;
}

.fancy_info-popup dd:last-of-type,
.limit_info-popup dd:last-of-type {
    padding-bottom: 0;
}

.fancy_info-popup dd,
.limit_info-popup dd {
    line-height: 3.73333vw;
    padding-bottom: 1.33333vw;
    color: #1e1e1e;
}

.fancy_info-popup dl:last-of-type,
.limit_info-popup dl:last-of-type {
    border-left: 0.26667vw solid #a5a5a5;
}

.fancy_info-popup .close,
.limit_info-popup .close {
    width: 6.66667vw;
    height: 6.66667vw;
}

.close {
    justify-content: center;
    align-items: center;
}

.bets-selections,
.bets-selections-head,
.trade,
.trade-2 {
    position: relative;
    min-height: 1vw;
    border-bottom: 1px solid #7e97a7;
    display: flex;
}

.fancy-bet .bets-selections dt {
    padding: 0.53333vw 1.86667vw;
    position: relative;
}

.fancy-bet .bets-selections .tips dd {
    color: #1e1e1e;
    line-height: 2.93333vw;
    margin-top: 0.53333vw;
}

.bets-selections dd,
.bets-selections-head dd,
.trade dd,
.trade-2 dd {
    border-right: 1px solid #fff;
}

.bets-selections dd {
    display: flex;
}


.bets-selections .suspend-fancy {
    width: 38.33333vw;
    font-size: 3.46667vw;
    box-sizing: border-box;
}

.suspend-fancy p {
    opacity: 0.8;
}

.suspend-fancy {
    position: absolute;
    right: 0;
    width: 37.33333vw;
    height: 100%;
}

.suspend-fancy,
.suspend-spb {
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    font-weight: bold;
    text-shadow: 0 0.26667vw 1.06667vw rgb(0 0 0 / 50%);
    justify-content: center;
    align-items: center;
}

.bets-selections>dd {
    width: 5vw;
    margin-left: 0;
}

.bets-selections a,
.trade p,
.trade-2 p {
    width: 54px;
    font-weight: bold;
    color: #1e1e1e;
    padding: 8px 10px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    text-align: center;
    font-size: 1.2vw;
    position: relative;
    text-decoration: none;
}

.bets-selections a:hover {
    text-decoration: none;
}

.bets-selections a span {
    /* overflow: hidden; */
    text-overflow: ellipsis;
    white-space: nowrap;
}

.bets-selections span,
.bets-selections-head span,
.trade span,
.trade-2 span {
    font-weight: normal;
    font-size: 2.93333vw;
}

#blue-fancy-bg {
    background-color: #e4f1f9;
    color: #3b5160;
}

#blue-fancy-bg .bg-bllue {
    background: transparent;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #3b5160;
}

#blue-fancy-bg .bg-bllue div {
    font-size: 3.46667vw;
    font-family: Tahoma, Helvetica, sans-serif;
}

#open-fancy_info:before {
    background: url(./images/icon-info-blue.png) no-repeat;
    background-size: contain;
    width: 1vw;
    height: 2vw;
    content: "";
    background-position: right;
    margin-left: auto;
    margin-right: 8px;
    display: block;
}

.widthhundredfancy {
    width: 100%;
}

.lay-1 {
    background-color: #faa9ba;
}

.back-1 {
    background-color: #72bbef;
}

.suspend_div {
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    font-weight: bold;
    text-shadow: 0 0.26667vw 1.06667vw rgb(0 0 0 / 50%);
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    width: 37.33333vw;
    height: 100%;
}

.odd_even_clr.bets-selections {
    margin: 0;
    height: 3.3vw;
}

.custom-container-fancy .widthhundredfancy {
    display: block;
    text-align: center;
}

.custom-container-fancy a.blue-core div.widthhundredfancy,
.custom-container-fancy a.pink-core div.widthhundredfancy {
    font-size: 1.1vw;
}

.custom-container-fancy a.blue-core span.widthhundredfancy,
.custom-container-fancy a.pink-core span.widthhundredfancy {
    font-size: 0.8vw;
}

.btn-book {
    background: #FFCC51;
    border: 0.26667vw solid #CF9A47;
    border-radius: 1.33333vw;
    font-size: 3.2vw;
    width: inherit;
}

.suspend-fancy.suspended {
    display: block !important;
    text-align: center;
    background: #000;
    z-index: 999;
    opacity: 0.4;
}

.suspend-fancy.ballrunning-sus {
    display: block !important;
    text-align: center;
}

/* Fancy Bet Design End */

/* InPlay Tabbing CSS */

header.headerInplay .main_tabbing_border {
    border-color: #fff;
}

.bottom-nav>.MuiBottomNavigation-root {
    background: #213543;
}

img.bookmarker-f-icon {
    background-color: #226BC3 !important;
}

img.fancy-f-icon {
    background-color: #0A92A5 !important;
}

.sportsSlider div div:last-child {
    margin-top: 0;
    margin-bottom: 0;
}

#reload_href {
    display: none;
}

.canvas-captcha {
    position: absolute;
    right: 22px;
    top: 6px;
}

img.errow-ex {
    width: 4.26667vw;
    height: 4.26667vw;
    display: none;
}

span.error-ex-cont {
    position: absolute;
    right: 10%;
    margin-top: 15px;
}

/* Match Odds CSS */

.market-type {
    position: relative;
    height: 1.6vw;
    background-color: #E0E6E6;
    padding: 1.86667vw;
    overflow: hidden;
}

.market-type ul {
    position: absolute;
    white-space: nowrap;
    padding-right: 0.53333vw;
    display: flex;
}

.market-type a {
    background-color: #222;
}

.market-type .select a {
    color: #1e1e1e;
    background-image: linear-gradient(-180deg, #cccccc 15%, #e8e8e8 100%);
    border-color: rgba(51, 51, 51, 0.3);
}

.market-type a {
    color: #fff;
    line-height: 2.06667vw;
    font-weight: bold;
    background: #243A48;
    border: 1px solid #243A48;
    border-radius: 4.8vw;
    padding: 0 2.4vw;
    display: block;
    font-size: 12px;
}

#naviMarket_1_188841345 {
    display: list-item;
}

.ps__scrollbar-x-rail {
    left: 0px;
    bottom: 0px;
    display: block;
}

.ps__scrollbar-x {
    left: 0px;
    width: 0px;
}

ul#naviMarketList {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
}

#naviMarketTemaplate {
    display: none;
}

.bets-wrap {
    position: relative;
    background-color: #fff;
    border-bottom: 1px solid #7e97a7;
    margin-bottom: 0;
}

.bet-limit+.bets-selections-head {
    padding-left: 6.26667vw;
}

.bets-selections-head {
    min-height: auto;
    line-height: 1.1;
    display: flex;
}

.bet-limit {
    position: absolute;
    z-index: 1;
    width: 4.4vw;
    height: 4vw;
    background: #E0E6E6;
    display: inline-block;
}

.bet-limit:before {
    content: '';
    width: 2vw;
    height: 2.66667vw;
    background: url(./images/min-maxx.png) no-repeat;
    background-size: contain;
    margin: 1.13333vw 1.86667vw 0.53333vw 1.86667vw;
    display: inline-block;
}

.bet-limit:after {
    position: absolute;
    left: 4.4vw;
    top: 0;
    content: '';
    width: 0;
    height: 0;
    border-top: 0px solid transparent;
    border-bottom: 4.33333vw solid transparent;
    border-left: 1.86667vw solid #E0E6E6;
    display: inline-block;
}

.bets-selections-head dt {
    font-size: 1vw;
    padding: 1vw 0.86667vw;
    align-items: flex-end;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.bets-selections-head .a-depth {
    /* width: 6.66667vw;
    height: 6.66667vw; */
    background: url(./images/analytics.png) no-repeat;
    background-size: contain;
    padding: 0;
    margin-right: 1.86667vw;
    font-size: 0;
    text-indent: -99999px;
    display: block;
}

.bets-selections span,
.bets-selections-head span,
.trade span,
.trade-2 span {
    font-weight: normal;
    font-size: 0.8vw;
}

.bets-selections-head span {
    display: block;
}

.bets-selections-head dd {
    /* width: 18.66667vw; */
    font-weight: bold;
    padding: 1.86667vw 1.86667vw 0.8vw;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0;
    font-size: 1vw;
}

#betsHead p {
    margin: 0;
}

.bets-selections-head dd,
.bets-selections .suspend {
    box-sizing: border-box;
}

.bets-selections,
.bets-selections-head {
    margin-top: 0;
    margin-bottom: 0;
}

.bets-selections h4 {
    width: 70%;
    line-height: 1.2;
    color: #1E1E1E;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    font-size: 15px;
}

.bets-bookmaker .bets-selections-head ul,
.bets-selections dt ul,
.fancy-bet .bets-selections-head ul {
    font-weight: normal;
    font-size: 1vw;
}

.bets-selections dt ul {
    display: flex;
    margin: 0;
    list-style: none;
}

.bets-selections .suspend {
    position: absolute;
    right: 0;
    width: 37.33333vw;
    height: 100%;
    z-index: 5;
    display: flex;
}

.suspend-full,
.bets-selections .suspend {
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #d0021b;
    color: #d0021b;
    justify-content: center;
    align-items: center;
}

.pin-on,
.pin-off {
    width: 6.66667vw;
    height: 6.66667vw;
    background: url(./images/book-marker-on.png) no-repeat;
    background-size: contain;
    margin-right: 1.86667vw;
}

.pin-off {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAMAAADzN3VRAAABJlBMVEUAAABVqqqAgL9mmZmAqqptkraAmbN0oqJ6maOAk6d7laeAmap7lKWAl6d8maiAlaqAlaV9l6eAmah9lqWAmKd9laiAl6WAlqh9mKaAmKl9lqaAl6aAlah+l6WAmKZ+lqiAmKV+lqeAl6h+mKaAlqd+mKh+l6d+l6Z9lqZ9lqh9mKd9mKZ9l6h9l6d+mKh9lqZ+l6d9mKh+l6Z9mKd+lqd/mKd/l6d+l6h/l6h/lqd+l6h/mKd+l6d/mKd9l6d+l6h9l6h+l6d+l6Z9mKd+l6d9l6Z+l6d+mKZ9l6d+l6d+l6Z+l6d+lqd+lqd+l6d+l6d+l6d+l6h+l6d+l6d+mKh+l6d+mKd+l6d+l6d+l6d+l6d+l6d+l6d+l6d+l6d+l6d+l6f///8LKer0AAAAYHRSTlMAAwQFBgcKCxkaHR4fICMkMDEyMzQ1Njg5Pj9CRkdISUpLTE1OT1FTZGZoamxub3BxcnN0d4OFjJWXmJmao6ustba4ubq7vL6/wMHCw8jJysvMzc7P09TW4+Tl5vj5+v2nt3d5AAAAAWJLR0RhsrBMhgAAATVJREFUGBlNwYdaglAAgNFfglKxMon2tqFlpu29LMum2OSaed//KYJ7oc9ziFgLJ612p+2dzFv0MtbF/ZaTNJNOpeEXDf5l3q+zxEZqLZvImCjB1K42CZvCRcmIJeBCaudAXtgEjPcSAaMqQ1WDQPnNANavUVZkaAXltgiWyKIcyNABSs63WLxDe5GhZ7SHOU7LKP0dGfodQKke4Tko01KbRnGbtJOEMg2p3WcIpQQdExi+6spY93IIsH5oJ4FP2esDSAm8UeBD9voC3CbHFWL7co/YziHzDWIluUHsaQbLHyGyJleJON8mFGtEJuQ4kXoBMFqbaIPSRqt4CQK2yKNY3T6UZZFGcUUZ5Qul4rtE7LebHIEzAk7dS/PPKPoPVTdlptztx+9Cgl7m3HFTdMTr0ayJ9gc8UUgOiRE66gAAAABJRU5ErkJggg==) no-repeat;
    background-size: 28%;
    /* font-size: 0; */
    text-indent: -99999px;
    content: "";
    display: inline-block;
    margin-right: 0;
    background-position: 0px 4px;
}

.bets-bookmaker {
    background-color: #F8F6E1;
}

.bets-wrap:last-of-type {
    margin-bottom: 2.66667vw;
}

.bets-bookmaker>h4 {
    justify-content: flex-start;
    margin-top: 0;
    margin-bottom: 0;
}

.fancy-bet>h5,
.bets-wrap>h4,
.fancy-bet>h4,
.sports-book>h4,
.bets-bookmaker>h4 {
    position: relative;
    height: 2.8vw;
    background-color: #3b5160;
    color: #fff;
    font-size: 1.1vw;
    line-height: 2.8vw;
    padding: 0 0 0 1.86667vw;
    align-items: center;
    justify-content: space-between;
}

.bets-bookmaker>h4 {
    display: block;
}

.fancy-bet>h5 span,
.bets-wrap>h4 span,
.fancy-bet>h4 span,
.sports-book>h4 span,
.bets-bookmaker>h4 span {
    flex: 1;
}

.bets-bookmaker>h4 span {
    margin-left: -43px;
    flex: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 5px;
}

.bets-bookmaker>h4 p {
    font-weight: normal;
    margin-left: 1.86667vw;
    opacity: .7;
    display: inline;
}

.bets-bookmaker .btn-fancy_info {
    position: absolute;
    right: 0;
}

.fancy-bet .btn-fancy_info,
.sports-book .btn-fancy_info,
.bets-wrap .btn-fancy_info {
    flex-basis: 8vw;
}

.mode-land .btn-fancy_info,
.fancy-bet .btn-fancy_info,
.sports-book .btn-fancy_info,
.bets-wrap .btn-fancy_info {
    width: 8vw;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: transparent;
    line-height: inherit;
    align-items: center;
    justify-content: center;
}

.bets-wrap .btn-fancy_info {
    font-size: 0;
    text-indent: -99999px;
}

.bets-wrap .btn-fancy_info:before {
    background: url(./images/header-info.png);
    background-size: contain;
    display: block;
    content: "";
    width: 22%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.fancy_info-popup,
.limit_info-popup {
    display: none;
    position: absolute;
    top: 0;
    right: 1.86667vw;
    background-color: #fff;
    border-radius: 1.06667vw;
    align-items: flex-start;
    z-index: 9;
}

.fancy_info-popup dl,
.limit_info-popup dl {
    padding: 0;
    margin: 0;
    padding-left: 7px;
}

.fancy_info-popup dt,
.limit_info-popup dt {
    height: 3.2vw;
    color: #577C94;
    font-size: 3.66667vw;
    line-height: 3.2vw;
    padding: 0.8vw 0 1.06667vw;
}

.bets-bookmaker .fancy_info-popup dd,
.bets-bookmaker .limit_info-popup dd {
    color: #1E1E1E;
}

.fancy_info-popup dd:last-of-type,
.limit_info-popup dd:last-of-type {
    padding-bottom: 0;
}

.back-lay-bookmarker {
    min-height: auto;
    height: auto;
}

.bets-selections dt {
    padding: 0.5vw 0.5vw;
}

dd.backlay_box {
    display: none;
}

.bookmaker-back-1 a,
.bookmaker-lay-1 a {
    padding: 0px 6px;
}

.sectionbookmaker>.bets-selections {
    min-height: 0vw;
}

.bets-bookmaker .bets-selections-head {
    min-height: auto;
}

.sectionbookmaker #back_1 a div,
.sectionbookmaker #lay_1 a div {
    padding: 1%;
    border: 1px solid #fff;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 0;
    width: 90%;
    margin: auto;
    border-radius: 4px;
}

#marketBetsWrap .bets-selections dd#back_1 a.suspended,
#marketBetsWrap .bets-selections dd#lay_1 a.suspended {
    cursor: not-allowed;
    text-decoration: none;
    pointer-events: none;
}

#marketBetsWrap .bets-selections dd#back_1 a.suspended::before,
#marketBetsWrap .bets-selections dd#lay_1 a.suspended::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(51, 51, 51, 0.2) url(./images/bg-disabled.png);
    width: 100%;
    height: 100%;
    display: block;
}

.sectionbookmaker .suspend-fancy p {
    margin-top: 9px;
}

.backprice-match,
.layprice-match {
    font-weight: bold !important;
    font-size: 1.1vw !important;
}

.form-login-all {
    position: relative;
    bottom: 45px;
}

.fancyImgall {
    background-color: #0A92A5 !important;
}

.time {
    color: #777;
}

strong.negative-exposure {
    background: #bf0000;
    margin-left: 5px !important;
    color: #f2e9cf !important;
    padding: 1px 5px;
    border-radius: 5px;
}

strong.negative-desktop-exposure {
    background: #fff;
    color: #bf0000;
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 3px;
}

.loading-wrap .loading img {
    width: 73%;
    height: 100%;
}

/* Bet Place Toast */

.message-wrap-full {
    color: #1e1e1e;
    display: flex;
    width: 100%;
}

.message-wrap-full.success {
    background: #6bbd11;
    border-color: #43760a;
}

.message-wrap-full.error {
    background: #bd3311;
    border-color: #760a0a;
}

.message-wrap-full.success.to-open_bets:before {
    background-image: url(./images/open-bet-arrow.png);
}

.message-wrap-full {
    position: absolute;
    left: 0;
    width: 100%;
    background-color: #f8e71c;
    border: 0.26667vw solid #9b9011;
    border-width: 0.26667vw 0 0.26667vw 0;
    box-shadow: 0 0.8vw 1.06667vw 0 rgb(0 0 0 / 50%);
    margin-top: 0;
    z-index: 10;
    top: 50px;
}

.message-wrap-full.to-open_bets:before {
    position: absolute;
    content: "";
    width: 4.26667vw;
    height: 3.2vw;
    background: url(./images/open-bet-yellow.png) no-repeat;
    background-size: contain;
    margin-top: -3.2vw;
    margin-left: 14.13333vw;
    display: block;
}

.message {
    flex: 1;
    line-height: 1.4;
    padding: 1.86667vw;
}

.message-wrap-full .message .back,
.message-wrap-full .message .lay {
    font-size: 3.2vw;
    color: #1e1e1e;
    border-radius: 1.06667vw;
    padding: 0 1.86667vw;
    background-color: #b7d5eb;
    margin-right: 1.33333vw;
}

.message-wrap-full .message .back,
.message-wrap-full .message .back {
    display: inline-block;
}

.message-wrap-full a {
    background-color: transparent;
    box-shadow: none;
}

.message-wrap-full.success .close {
    border-left-color: #43760a;
}

.message-wrap-full .close {
    border-left: 1px solid #9b9011;
    padding: 1.86667vw 2.93333vw;
}

.message-wrap-full a {
    height: auto;
    border-width: 0;
    border-radius: 0;
    box-shadow: none;
}

.message-wrap-full a.close {
    display: flex;
    font-size: 0;
}

.close {
    justify-content: center;
    align-items: center;
}

.message-wrap-full .close:before {
    content: "";
    width: 2.4vw;
    height: 2.4vw;
    background: url(./images/cross-open-bet.png);
    background-size: contain;
    display: block;
}

.message-wrap-full h4,
.message-wrap-full p {
    margin: 0;
    font-size: 3.46667vw;
}

.pink-type,
.blue-type {
    background: #efd3d9;
    font-size: 3.2vw;
    padding: 2px 1.86667vw;
    margin-right: 5px;
    border-radius: 1.06667vw;
}

.blue-type {
    background: #b7d5eb !important;
}

.red-type {
    background: red !important;
}

.detail-open-head {
    background-color: #1e1e1e;
    color: #fff;
    font-weight: bold;
    line-height: 10.66667vw;
    display: flex;
}

.detail-open-head ul {
    display: inline;
    flex: 1;
    padding-left: 1.86667vw;
    padding-right: 1.06667vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.detail-open-head li {
    margin-right: 0;
    padding-right: 0;
    list-style: none;
    position: relative;
    white-space: nowrap;
    font-size: 3.46667vw;
}

.detail-back {
    width: 10.66667vw;
    height: 10.66667vw;
    background: url(./images/leftarrowfill.png) no-repeat;
    background-size: contain;
    border-right: 1px solid #4B4B4B;
    font-size: 0;
    text-indent: -99999px;
    display: block;
}

#openheadHeader {
    display: flex;
    padding: 0 1.86667vw;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%);
    color: #fff;
    font-size: 3.73333vw;
    line-height: 2.2;
    margin-top: 0;
    margin-bottom: 0;
}

.slip-list {
    background-color: #fff;
}

.bets-head {
    background-color: #fff;
    font-size: 2.93333vw;
    border-bottom-width: 0;
    border-bottom: 1px solid #7da7c6;
    align-items: stretch;
    flex-wrap: wrap;
    display: inline-block;
    margin: 0;
    width: 100%;
}

.bets-head dt,
.bets-head dd {
    padding: 0.8vw 1.86667vw;
}

.bets-back h4,
.bets-lay h4 {
    font-size: 3.46667vw;
}

.bets-head dd {
    color: #1b2d38;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    width: 16%;
    padding-left: 0 !important;
    text-align: right;
}

.bets-back dd,
.bets-lay dd {
    width: 16%;
    padding-left: 0 !important;
    text-align: right;
    font-size: 3.46667vw;
}

.bets-head dt {
    flex: 1;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}

.bets-head h4 {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
}

.bets-head .col-odds {
    flex: 0 0 auto !important;
    padding-right: 0;
}

.bets-head .col-stake {
    flex: 0 0 14.66667vw;
    padding-right: 0;
}

.bets-head dd span {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

.bets-back .col-profit,
.bets-lay .col-profit,
.bets-book .col-profit,
.bets-head .col-profit {
    flex: 0 1 13.33333vw;
    margin-left: 0;
}

.bets-back {
    background-color: #beddf4;
    border-bottom: 1px solid #7da7c6;
    align-items: stretch;
    flex-wrap: wrap;
}

.bets-back,
.bets-lay {
    margin: 0;
}

.bets-back dt,
.bets-lay dt,
.bets-book dt,
.bets-head dt {
    flex: 1;
}

.bets-back dt,
.bets-lay dt,
.bets-book dt,
.bets-head dt,
.bets-back dd,
.bets-lay dd,
.bets-book dd,
.bets-head dd {
    align-items: center;
    padding: 1.33333vw 1.86667vw;
}

.bets-back dd,
.bets-lay dd {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.bets-back dt,
.bets-lay dt {
    float: left;
    width: 45%;
}

.bets-back dt span,
.bets-lay dt span {
    width: 12vw;
    line-height: 8vw;
    border-radius: 1.06667vw;
    margin-right: 1.86667vw;
    display: inline-block;
    font-size: 3.46667vw;
    text-align: center;
}

dl.bets-head dt {
    width: 45%;
}

.bets-back dt span.back {
    background-color: #72bbef;
}

.bets-lay dt span.lay {
    background-color: #faa9ba;
}

.bets-lay {
    background-color: #f3dce2;
    border-bottom: 1px solid #d38e9c;
}

/* .bets-back h4, .bets-lay h4, .bets-book h4, .bets-head h4 {
    flex: 1;
} */

.bets-back h4,
.bets-lay h4 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    display: inline;
}

.bets-back .col-odds,
.bets-lay .col-odds,
.bets-book .col-odds,
.bets-head .col-odds {
    flex: 0 1 9.6vw;
    flex: 0 1 16vw;
    padding-right: 0;
    margin-left: 0;
}

.bets-back dd,
.bets-lay dd,
.bets-book dd,
.bets-head dd {
    color: #1b2d38;
}

.bets-back dd span,
.bets-lay dd span,
.bets-book dd span,
.bets-head dd span {
    flex: 1;
}

.bets-back dd span,
.bets-lay dd span {
    display: block;
}

.bets-back .col-stake,
.bets-lay .col-stake,
.bets-book .col-stake,
.bets-head .col-stake {
    flex: 0 0 14.66667vw;
    padding-right: 0;
    margin-left: 0;
}

.bets-back .col-ref,
.bets-lay .col-ref,
.bets-book .col-ref,
.bets-head .col-ref {
    order: -1;
    color: #3b5160;
    background-color: rgba(255, 255, 255, 0.3);
    border-top-width: 0;
}

.bets-back .info-keep,
.bets-lay .info-keep,
.bets-book .info-keep,
.bets-head .info-keep,
.bets-back .col-ref,
.bets-lay .col-ref,
.bets-book .col-ref,
.bets-head .col-ref {
    flex: 1 1 100%;
    border-top: 1px solid #9dc2dc;
    font-size: 2.93333vw;
    line-height: 1.1;
    padding: 1.06667vw 1.86667vw;
}

.bets-back .col-ref ul,
.bets-lay .col-ref ul {
    display: inline-block;
}

.bets-back .col-ref ul li,
.bets-lay .col-ref ul li {
    list-style: none;
}

.bets-back .col-ref li,
.bets-lay .col-ref li,
.bets-book .col-ref li,
.bets-head .col-ref li {
    margin-right: 2.66667vw;
}

.check-list li {
    margin-left: 2.66667vw;
    list-style: none;
}

.check-list a.checked:before {
    border-width: 0;
    box-shadow: none;
    background: #2789ce url(./images/checked-button.png) no-repeat;
    background-size: contain;
}

.check-list a:before {
    content: "";
    width: 5.33333vw;
    height: 5.33333vw;
    background: #fff;
    border: 0.26667vw solid #2789ce;
    margin-right: 1.33333vw;
    box-shadow: inset 0 0.53333vw 0 0 rgb(0 0 0 / 20%);
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
}

ul#openBetOption {
    padding-left: 0;
}

a#showBetInfo {
    display: flex;
    color: #1b2d38;
    font-weight: normal;
    height: 9.6vw;
    align-items: center;
    text-decoration: none;
    font-size: 3.46667vw;
}

#open-bets-table .table_first_row {
    width: 95.5%;
}

.show-bet-info {
    display: block;
}

.hide-bet-info {
    display: none !important;
}

#betInfo {
    margin-left: 0;
    width: 100%;
    font-size: 2.93333vw;
}

#betInfo.layy {
    background-color: #faa9ba24 !important;
}

#betInfo.backk {
    background-color: #beddf4b5 !important;
}

#betInfo ul li {
    width: 46%;
    display: inline-block;
    padding-top: 2px;
    padding-bottom: 2px;
}

#betInfo ul li:first-child {
    width: 20%;
}

#betInfo ul {
    padding-left: 0;
}

#open-bets-table tr td.arrow-icon {
    width: 8% !important;
    text-align: right;
}

.popup-setting-body .stake-custom-edit.setting-stack input {
    display: block;
}

/* Edit Stake Values */

.grid-text-boxes {
    width: 100%;
}

.stake-custom-edit dd {
    margin-left: 0;
    width: 24.5%;
    display: inline-block;
    margin: auto;
    float: none;
    margin-bottom: 10px;
}

.stake-custom-edit .ui-input-text {
    margin-left: 0;
    width: 100%;
}

#open-stakeform {
    display: none;
}

.clear {
    clear: both;
}

.account-stat>div:first-child {
    max-width: 100%;
    flex-basis: 18%;
}

.account-stat>div:last-child {
    max-width: 100%;
    flex-basis: 82%;
}

.current-bets-cont>div:first-child {
    max-width: 100%;
    flex-basis: 18%;
}

.current-bets-cont>div:last-child {
    max-width: 82%;
    flex-basis: 82%;
}

.fromto {
    margin: 0 5px;
    background-position: -20px -182px;
    height: 8px;
    width: 7px;
    background-image: url(./images/report-sf227858fbc.png);
    background-repeat: no-repeat;
}

.current-bets-cont header {
    width: 100% !important;
}

.current-bets-cont header>div:first-child {
    display: contents;
}

.bet-history-pd>div,
.bethistory-filters-fancy>div,
.bethistory-filters-matchodds>div,
.bethistory-filters-bookmaker>div,
.fancy-bet-pandl>div {
    padding: 0;
    margin-top: 15px;
}

.get-fancy-history {
    background: #121212 url(./images/bg-send-skyEX.png) repeat-x;
    color: #f8d61c;
    border-color: #000;
    width: 95px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.pandlcont>div {
    padding: 0;
}

.bethistory-filters-fancy input[type=text],
.bethistory-filters-matchodds input[type=text],
.bethistory-filters-bookmaker input[type=text] {
    margin-left: 10px;
}

.second-spec-row>td {
    padding: 0 !important;
}

tr.second-spec-row {
    background: #E2E8ED;
}

tr.second-spec-row td {
    background: transparent !important;
    position: relative;
    border-top: 1px solid #7E97A7;
    padding: 8px 10px !important;
    vertical-align: middle;
}

tr.second-spec-row td.second-spec-cl table {
    border-left: 1px solid #7e97a7;
    width: 85%;
    min-width: 85%;
    margin-left: 15%;
}

tr.second-spec-row td.second-spec-cl table thead tr {
    background: #e2e8ed;
}

tr.second-spec-row td.second-spec-cl table thead tr th {
    background: transparent;
    padding: 5px;
}

tr.second-spec-row td.second-spec-cl table tbody tr {
    background: #f2f4f7;
}

tr.second-spec-row td.second-spec-cl table tbody tr td {
    background: transparent;
    padding: 5px;
}

tr.second-spec-row td.second-spec-cl table tbody tr td.withliability {
    color: #d0021b;
}

.bethistory-filters-fancy input[type=text] {
    margin-left: 10px;
}

.a-open_tv img {
    width: 7.6vw;
    height: 6.06667vw;
    background: url("./images/downloadn.svg") no-repeat;
}

.a-open_tv {
    padding: 6px 0px;
    display: grid;
    place-items: center;
}

.xs-bets-tv {
    width: 27% !important;
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: inset 0 1px 0 0 rgb(255 255 255 / 40%) !important;
    border-color: #062a2e;
    background-color: #0a7b89
}

.tv-fix {
    position: absolute;
    top: 18.6vw;
    width: 100vw;
    z-index: 1000;
    /* background: white; */
}

a.expand-close {
    background: url(./images/report-sf227858fbc.png);
    background-repeat: no-repeat;
    background-position: -16px -136px;
    padding: 8px;
    width: 8px;
}

span.expand-close {
    background: url(./images/report-sf227858fbc.png);
    background-repeat: no-repeat;
    background-position: -16px -141px;
    padding: 7px;
    background-size: 28px;
    width: 8px;
}


.pandlbets a.expand-close {
    background-position: 100% -136px !important;
    background: url(./images/report-sf227858fbc.png);
    background-repeat: no-repeat;
    padding-right: 20px;
}

.pandlbets span.expand-close {
    background-position: 100% -141px !important;
    background: url(./images/report-sf227858fbc.png);
    background-repeat: no-repeat;
    padding-right: 0px;
    background-size: 28px;
    float: none;
}

.pandlbets a.expand-open {
    background-position: 100% -155px !important;
    background: url(./images/report-sf227858fbc.png);
    background-repeat: no-repeat;
    padding-right: 20px;
    background-size: 28px;
}

.pandlbets span.expand-open {
    background-position: 100% -155px !important;
    background: url(./images/report-sf227858fbc.png);
    background-repeat: no-repeat;
    padding-right: 0px;
    background-size: 28px;
    float: none;
}

.sum-pl {
    border: none;
}

.sum-pl td {
    border: none !important;
}

.dottedborder-td td {
    border: none !important;
    border-top: 1px dotted #000 !important;
}

a.expand-open {
    background-position: -16px -155px !important;
    background: url(./images/report-sf227858fbc.png);
    background-repeat: no-repeat;
    padding: 9px;
    width: 8px;
    background-size: 28px;
}

span.expand-open {
    background-position: -16px -155px !important;
    background: url(./images/report-sf227858fbc.png);
    background-repeat: no-repeat;
    padding: 9px;
    width: 8px;
    background-size: 28px;
}

.expand-arrow {
    position: absolute;
    left: 49.5%;
    margin-left: -10px;
    background-position: -17px -127px !important;
    height: 9px;
    width: 10px;
    background: url(./images/report-sf227858fbc.png);
    background-repeat: no-repeat;
}

.custom-sky-passwordChange {
    width: 400px;
    margin: auto;
    background: #eee;
    padding: 15px;
    position: fixed;
    top: 110px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 10px;
}

.inputtext-pass input {
    padding: 10px 5px;
    box-shadow: inset 0px 1px 0px rgb(0 0 0 / 50%);
    border-radius: 4px;
    box-sizing: border-box;
    border: 0px #aaa solid;
    font-size: 12px;
    background: #fff;
}

#transition-modal-title {
    font-size: 16px;
    color: #3b5160;
    margin-bottom: 18px;
    font-family: Tahoma, Helvetica, sans-serif;
    margin-top: 0;
}

.form-lable-changepass {
    font-size: 12px;
    text-align: right;
}

.custom-sky-passwordChange .chaPassSubmit {
    background-image: url(./images/bg-send_hover-skyEX.png);
    color: #f8d61c;
    border-color: #000;
    font-weight: bold;
    line-height: 23px;
    font-size: 12px;
    border: 1px solid #bbb;
    border-radius: 4px;
    padding: 0;
    text-align: center;
}

.chaPassSubmit span {
    color: #f8d61c;
}

.chaPassSubmitCont {
    text-align: center;
}

#validateConfirmPass {
    color: red;
    font-size: 10px;
}

.star-must {
    padding-left: 0 !important;
}

.star-must .must {
    color: red;
    position: relative;
    right: 10px;
}

.form-input-changepas {
    padding-right: 0 !important;
}

.close_pop {
    position: absolute;
    right: 15px;
    background-position: -41px -632px;
    height: 16px;
    width: 17px;
    font-size: 0;
    text-indent: -99999px;
    background-image: url(./images/agent-sa842cb1158.png);
    background-repeat: no-repeat;
    top: 15px;
}

.darktblhead {
    background: #314b5b;
}

.darktblhead th {
    color: #fff;
    font-weight: 700;
    padding: 1px 3px !important;
}



.loading-bar {
    width: 100%;
    margin-right: 0 !important;
    align-items: center;
    justify-content: center;
}

.loading-bar span {
    width: 1.06667vw;
    height: 1.06667vw;
    border-radius: 50%;
    background-color: #000000;
    margin-right: 1.6vw;
    /* opacity: 0; */
    animation: loadBar 0.8s ease infinite;
}

.loading-bar span:nth-child(1) {
    animation-delay: 0s;
}

.loading-bar span:nth-child(2) {
    animation-delay: 0.1s;
}

.loading-bar span:nth-child(3) {
    animation-delay: 0.2s;
}

.loading-bar span:nth-child(4) {
    animation-delay: 0.3s;
}

.loading-bar span:nth-child(5) {
    animation-delay: 0.4s;
}

.loading-bar span:nth-child(6) {
    animation-delay: 0.5s;
}

.loading-bar span:nth-child(7) {
    animation-delay: 0.6s;
}

.loading-bar span:nth-child(8) {
    animation-delay: 0.7s;
}

@media screen and (min-aspect-ratio: 16 / 9) {


    .loading-bar {
        width: 11.994vmax;
    }

    .loading-bar span {
        width: 0.5997vmax;
        height: 0.5997vmax;
        margin-right: 0.89955vmax;
    }
}

@keyframes loadBar {
    0% {
        opacity: 0.1;
    }

    50% {
        opacity: 1;
        transform: scale(1.4);
    }

    100% {
        opacity: 0.1;
    }
}

.suspended .odd_even_clr:after {
    content: attr(data-title);
    font-family: "Roboto Condensed", sans-serif;
    font-size: 12px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: calc(100% - 84%);
    background: #000;
    color: #fff;
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    text-transform: capitalize;
    text-shadow: 0 0.26667vw 1.06667vw rgb(0 0 0 / 50%);
    font-weight: 900;
    font-size: 18px;
    top: 0;
    position: absolute;
    right: 0;
    opacity: .4;
}

.suspended.bets-selections:after {
    content: attr(data-title);
    font-family: "Roboto Condensed", sans-serif;
    font-size: 12px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: calc(100% - 85.4%);
    background: #000;
    color: #fff;
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    text-transform: capitalize;
    text-shadow: 0 0.26667vw 1.06667vw rgb(0 0 0 / 50%);
    font-weight: 900;
    font-size: 18px;
    top: 0;
    position: absolute;
    right: 0;
    opacity: .4;
}


/* Get the bourbon mixin from http://bourbon.io */
/* Reset */
.flip-clock * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-clock-list-item-inner {
    cursor: pointer;
    text-decoration: none;
    color: #ccc;
}

.flip-clock-list-item-inner:hover {
    color: #fff;
}

.flip-clock .flip-clock-list {
    list-style: none;
}

.flip-clock.clearfix:before,
.flip-clock.clearfix:after {
    content: " ";
    display: table;
}

.flip-clock.clearfix:after {
    clear: both;
}

.flip-clock.clearfix {
    *zoom: 1;
}

/* Main */
.flip-clock {
    font: normal 11px "Helvetica Neue", Helvetica, sans-serif;
    -webkit-user-select: none;
}

.flip-clock-meridium {
    background: none !important;
    box-shadow: 0 0 0 !important;
    font-size: 36px !important;
}

.flip-clock-meridium a {
    color: #313333;
}

.flip-clock {
    text-align: center;
    position: relative;
    width: 100%;
    /* margin: 1em; */
}

.flip-clock:before,
.flip-clock:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
}

.flip-clock:after {
    clear: both;
}

/* Skeleton */
.flip-clock .flip-clock-list {
    position: relative;
    float: left;
    margin: 5px;
    width: 40px;
    height: 55px;
    font-size: 40px;
    font-weight: bold;
    line-height: 35px;
    border-radius: 6px;
    background: #000;
}

.flip-clock .flip-clock-list .flip-clock-list-item {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 59px;
    text-decoration: none !important;
}

.flip-clock .flip-clock-list .flip-clock-list-item:first-child {
    z-index: 2;
}

.flip-clock .flip-clock-list .flip-clock-list-item .flip-clock-list-item-inner {
    display: block;
    height: 100%;
    -webkit-perspective: 200px;
    -moz-perspective: 200px;
    perspective: 200px;
    margin: 0 !important;
    overflow: visible !important;
    cursor: default !important;
}

.flip-clock .flip-clock-list .flip-clock-list-item .flip-clock-list-item-inner div {
    z-index: 1;
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    font-size: 80px;
    overflow: hidden;
    outline: 1px solid transparent;
}

.flip-clock .flip-clock-list .flip-clock-list-item .flip-clock-list-item-inner div {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.flip-clock .flip-clock-list .flip-clock-list-item .flip-clock-list-item-inner div.tops {
    -webkit-transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    top: 0;
}

.flip-clock .flip-clock-list .flip-clock-list-item .flip-clock-list-item-inner .tops:after {
    content: "";
    position: absolute;
    top: 44px;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 3px;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.4);
}


.flip-clock .flip-clock-list .flip-clock-list-item .flip-clock-list-item-inner .bottoms {
    -webkit-transform-origin: 50% 0;
    -moz-transform-origin: 50% 0;
    -ms-transform-origin: 50% 0;
    -o-transform-origin: 50% 0;
    transform-origin: 50% 0;
    bottom: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.flip-clock .flip-clock-list .flip-clock-list-item .flip-clock-list-item-inner div {
    position: absolute;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    color: #ccc;
    text-shadow: 0 1px 2px #000;
    text-align: center;
    background-color: #333;
    border-radius: 6px;
    font-size: 30px;
}

.flip-clock .flip-clock-list .flip-clock-list-item .flip-clock-list-item-inner div.tops {
    top: 0;
}

.flip-clock .flip-clock-list .flip-clock-list-item .flip-clock-list-item-inner div.bottoms {
    bottom: 0;
}

/* PLAY */
.flip-clock .flip-clock-list .before {
    z-index: 3;
}

.flip-clock .flip {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
}

.flip-clock .flip-clock-list .active {
    -webkit-animation: asd 0.01s 0.49s linear both;
    -moz-animation: asd 0.01s 0.49s linear both;
    animation: asd 0.01s 0.49s linear both;
    z-index: 5;
}

.flip-clock-divider {
    float: left;
    display: inline-block;
    position: relative;
    width: 20px;
    height: 100px;
}

.flip-clock-divider:first-child {
    width: 0;
}

.flip-clock-dot {
    display: block;
    background: #323434;
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    left: 5px;
}

.flip-clock-label {
    display: none;
    position: absolute;
    top: -1.5em;
    right: -86px;
    color: black;
    text-shadow: none;
}

.flip-clock-divider.minutes .flip-clock-label {
    right: -88px;
}

.flip-clock-divider.seconds .flip-clock-label {
    right: -91px;
}

.flip-clock-dot.tops {
    top: 30px;
}

.flip-clock-dot.bottoms {
    bottom: 30px;
}

@-webkit-keyframes asd {
    0% {
        z-index: 2;
    }

    100% {
        z-index: 4;
    }
}

@-moz-keyframes asd {
    0% {
        z-index: 2;
    }

    100% {
        z-index: 4;
    }
}

@-o-keyframes asd {
    0% {
        z-index: 2;
    }

    100% {
        z-index: 4;
    }
}

@keyframes asd {
    0% {
        z-index: 2;
    }

    100% {
        z-index: 4;
    }
}

.flip-clock .flip-clock-list .active .flip-clock-list-item-inner .bottoms {
    z-index: 2;
    -webkit-animation: turn 0.5s 0.5s linear both;
    -moz-animation: turn 0.5s 0.5s linear both;
    animation: turn 0.5s 0.5s linear both;
}

@-webkit-keyframes turn {
    0% {
        -webkit-transform: rotateX(90deg);
    }

    100% {
        -webkit-transform: rotateX(0deg);
    }
}

@-moz-keyframes turn {
    0% {
        -moz-transform: rotateX(90deg);
    }

    100% {
        -moz-transform: rotateX(0deg);
    }
}

@-o-keyframes turn {
    0% {
        -o-transform: rotateX(90deg);
    }

    100% {
        -o-transform: rotateX(0deg);
    }
}

@keyframes turn {
    0% {
        transform: rotateX(90deg);
    }

    100% {
        transform: rotateX(0deg);
    }
}

.flip-clock .flip-clock-list .before .flip-clock-list-item-inner .tops {
    z-index: 2;
    -webkit-animation: turn2 0.5s linear both;
    -moz-animation: turn2 0.5s linear both;
    animation: turn2 0.5s linear both;
}

@-webkit-keyframes turn2 {
    0% {
        -webkit-transform: rotateX(0deg);
    }

    100% {
        -webkit-transform: rotateX(-90deg);
    }
}

@-moz-keyframes turn2 {
    0% {
        -moz-transform: rotateX(0deg);
    }

    100% {
        -moz-transform: rotateX(-90deg);
    }
}

@-o-keyframes turn2 {
    0% {
        -o-transform: rotateX(0deg);
    }

    100% {
        -o-transform: rotateX(-90deg);
    }
}

@keyframes turn2 {
    0% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(-90deg);
    }
}

.flip-clock .flip-clock-list .flip-clock-list-item .active {
    z-index: 3;
}

/* SHADOW */
.flip-clock .flip-clock-list .before .flip-clock-list-item-inner .tops {
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.1)), color-stop(100%, black));
    background: linear, top, rgba(0, 0, 0, 0.1) 0%, black 100%;
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
    background: linear, to bottom, rgba(0, 0, 0, 0.1) 0%, black 100%;
    -webkit-animation: show 0.5s linear both;
    -moz-animation: show 0.5s linear both;
    animation: show 0.5s linear both;
}

.flip-clock .flip-clock-list .active .flip-clock-list-item-inner .tops {
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.1)), color-stop(100%, black));
    background: linear, top, rgba(0, 0, 0, 0.1) 0%, black 100%;
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
    background: linear, to bottom, rgba(0, 0, 0, 0.1) 0%, black 100%;
    -webkit-animation: hide 0.5s 0.3s linear both;
    -moz-animation: hide 0.5s 0.3s linear both;
    animation: hide 0.5s 0.3s linear both;
}

/*DOWN*/
.flip-clock .flip-clock-list .before .flip-clock-list-item-inner .bottoms {
    background: -moz-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, black), color-stop(100%, rgba(0, 0, 0, 0.1)));
    background: linear, top, black 0%, rgba(0, 0, 0, 0.1) 100%;
    background: -o-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -ms-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
    background: linear, to bottom, black 0%, rgba(0, 0, 0, 0.1) 100%;
    -webkit-animation: show 0.5s linear both;
    -moz-animation: show 0.5s linear both;
    animation: show 0.5s linear both;
}

.flip-clock .flip-clock-list .active .flip-clock-list-item-inner .bottoms {
    background: -moz-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, black), color-stop(100%, rgba(0, 0, 0, 0.1)));
    background: linear, top, black 0%, rgba(0, 0, 0, 0.1) 100%;
    background: -o-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -ms-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
    background: linear, to bottom, black 0%, rgba(0, 0, 0, 0.1) 100%;
    -webkit-animation: hide 0.5s 0.3s linear both;
    -moz-animation: hide 0.5s 0.3s linear both;
    animation: hide 0.5s 0.2s linear both;
}

@-webkit-keyframes show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes hide {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-moz-keyframes hide {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-o-keyframes hide {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes hide {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.clock {
    position: absolute;
    right: 0;
    top: 60%;
    float: right;

}

.ball-runs.playera {
    background: #355e3b;
    color: #ff4500;
}

.ball-runs {
    background: #08c;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: inline-block;
    line-height: 30px;
    text-align: center;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    margin: 0 2px;
}


.video-overlay {
    position: absolute;
    top: 0%;
    background: rgba(0, 0, 0, .4);
    height: auto;
    content: "";
    padding: 5px;
}

.video-overlay.right {
    right: 0;
    left: auto;
    text-align: right;
}

.video-overlay h3 {
    font-size: 0.8em;
    font-weight: bold;
    margin-bottom: 2px;
    text-transform: uppercase;
}

.video-overlay .card-inner {
    margin-bottom: 5px;
}

.video-overlay img {
    width: 21px !important;
    height: auto;
    margin-right: 2px;
    margin-left: 2px;
}

.text-white {
    color: white;
}

.col-md-6 {
    padding: 24px;
}

.andar-bahar-image,
.player-type img,
.result-image img,
.sixplayer-image img {
    width: 50px;
}

.round-id {
    padding: 10px;
}

.card-result {
    background: white;
    border-radius: 2%;
    width: 95vw;
}

.card-result-title {
    background-color: #aaaaaa9e;
    margin: 0% !important;
    width: 95vw !important;
}

.bookmakerSize {
    display: none;
}

.market-exposure[data-value] {
    color: green !important;
}

.market-exposure[data-value^="-"] {
    color: Red !important;
}

.signuphead {
    color: #fff;
    font-size: 27px;
    letter-spacing: .5px;
    text-shadow: 3px 4px 7px #f9b710;
    padding: 11px;
    background: #d9d2c169;
    width: 100%;
    text-align: center;
    font-weight: 600;

}

strong.negative-exposure-popup {
    /* margin-left: 5px !important; */
    color: #bf0000 !important;
    padding: 1px 5px;
    border-radius: 5px;
    font-size: 12px;
    float: right;

}

.premiumicon {
    background: #d7552e !important;
}

.to-win:before {
    display: inherit;
    background-image: url("./images/downloadarrow.svg");
}

.to-win:before,
.to-lose:before {
    content: "";
    width: 2.66667vw;
    height: 2.66667vw;
    background-repeat: no-repeat;
    background-size: contain;
    margin: -0.53333vw 1.06667vw 0 0;
    vertical-align: middle;
}

.limit_info-popup {
    display: none;
    position: absolute;
    top: 0;
    right: 1.86667vw;
    background-color: #fff;
    border-radius: 1.06667vw;
    align-items: flex-start;
    /* z-index: 9; */
}

.limit_info-popup dd {
    line-height: 3.73333vw;
    padding-bottom: 1.33333vw;
    color: #1e1e1e;
}

.limit_info-popup .close {
    width: 6.66667vw;
    height: 6.66667vw;
    padding: 12px 0px 0px 7px;
}

.limit_info-popup .close {
    line-height: 8vw;
}

.close {
    justify-content: center;
    align-items: center;
    font-size: 0;
    text-indent: -99999px;
}

.close:before {
    content: "";
    width: 2.4vw;
    height: 2.4vw;
    background: url("./images/close.svg");
    background-size: contain;
    display: inherit;
}

#minMaxInfo {
    width: 75px;
    padding: 0;
    margin: 0px;
}


.bet-slip {
    height: 500px
}

.bg_odd {
    background: #7cc4f7;
    /* border: 1px solid
    rgba(224, 224, 224, 1); */
}

.bg_even {
    background: #fca4b7;
    /* border:1px solid
    rgba(224, 224, 224, 1); */
}

.fancy-bet .bets-selections dt .btn-book {
    position: absolute;
    font-weight: normal;
    left: 77px;
    margin-top: -41px;
    padding: 0px;
    font-size: 13px;
}

@media (max-width: 600px) and (min-width: 200px) {
    .fancy-bet .bets-selections dt .btn-book {
        position: absolute;
        font-weight: 400;
        left: 77px;
        margin-top: -14px;
        padding: 0;
        font-size: 6px;
        height: 68%;
        width: 2%;
    }


    .bets-bookmaker .bets-selections-head ul,
    .bets-selections dt ul,
    .fancy-bet .bets-selections-head ul {
        font-size: 3vw
    }

    .bets-selections-head dt {
        font-size: 3vw;
    }


    .bets-selections>dd {
        width: 15vw;
        margin-left: 0;
    }

    .bets-selections-head dd {
        font-size: 3vw;
    }

    .backprice-match,
    .layprice-match {
        font-size: 2.1vw !important;
    }



    .bets-selections a span {
        /* overflow: hidden; */
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .bets-selections span,
    .bets-selections-head span,
    .trade span,
    .trade-2 span {
        font-weight: normal;
        font-size: 1.6vw;
    }

    .bets-selections-head dd {
        width: 14.66667vw !important;

    }


    element.style {}

    .sectionbookmaker #back_1 a div,
    .sectionbookmaker #lay_1 a div {
        padding: 1%;
        border: 1 px solid #fff;
        padding-top: 7 px;
        padding-bottom: 7 px;
        padding-left: 0;
        width: 90%;
        margin: auto;
        border-radius: 4 px;
    }

    * {
        margin: 0;
        padding: 0;
        font-family: Helvetica, Tahoma, sans-serif;
    }

    user agent stylesheet div {
        display: block;
    }

    .bets-selections a,
    .trade p,
    .trade-2 p {
        font-size: 2.2vw;
    }
}

.expand .table-commission {
    width: 75%;
    margin-left: 23%;
    border-right: 1px solid #7e97a7;
}

.table-commission .table_head th {
    background-color: #E4E4E4;
}